import { StyleSheet, WidgetTypes } from '.';

export enum HEIGHT_UNITS {
  PX = 'px',
  VH = 'vh',
}
export interface Column {
  widgets: WidgetTypes[];
}

export type SectionBackground = {
  id: string;
  name: string;
  extension?: string;
  imageUrl?: string;
  detailUrl?: string;
};
export interface Section {
  id: string;
  appearance: {
    width: number;
    height: number;
    contentAlignment: ContentAlignment;
    columnSpacing: number;
    contentSpacing: number;
    paddingTop: number;
    paddingBottom: number;
    paddingLeft: number;
    paddingRight: number;
    style: string;
  };
  creationTime?: string;
  lastModifiedTime?: string;
  columns: Column[];
  sectionLayout: SectionLayout;
  backgroundColorId: string;
  asset: SectionBackground;

  style: StyleSheet;
}

export enum SectionLayout {
  SINGLE = 'SINGLE',
  DOUBLE_50_50 = 'DOUBLE_50_50',
  DOUBLE_66_33 = 'DOUBLE_66_33',
  DOUBLE_33_66 = 'DOUBLE_33_66',
  TRIPLE_33_33_33 = 'TRIPLE_33_33_33',
}

export interface ToSectionPayload {
  sectionPosition: number;
}

export interface NewSectionPayload {
  sectionId: string;
  sectionPosition: number;
  widgetMutations: WidgetMutationsPayload[];
  textBlockMutations: TextBlockMutationPayload[];
}

export interface WidgetMutationsPayload {
  from: string;
  to: string;
}

export interface TextBlockMutationPayload {
  from: { key: string };
  to: { key: string };
}

export enum ContentAlignment {
  TOP_LEFT = 'TOP_LEFT',
  TOP = 'TOP',
  TOP_RIGHT = 'TOP_RIGHT',
  LEFT = 'LEFT',
  CENTER = 'CENTER',
  RIGHT = 'RIGHT',
  BOTTOM_LEFT = 'BOTTOM_LEFT',
  BOTTOM = 'BOTTOM',
  BOTTOM_RIGHT = 'BOTTOM_RIGHT',
}
