import { ThemeProvider } from 'styled-components';
import { DesignSystemProvider } from '@bynder/design-system';
import ThemeFontsProvider from './ThemeFontsProvider';
import { useGetTheme } from '../queries/themesets';

interface Props {
  children: React.ReactElement;
}

const ThemeManager = (props: Props) => {
  const { data: theme } = useGetTheme();

  return (
    <ThemeProvider theme={theme}>
      <DesignSystemProvider>
        <>
          {props.children}
          <ThemeFontsProvider />
        </>
      </DesignSystemProvider>
    </ThemeProvider>
  );
};

export default ThemeManager;
