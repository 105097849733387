import { create } from 'zustand';
import { createSelectors } from './helpers';
import { GuideOverviewLayout } from '../constants/guides';
import { FetchError } from '../types';

interface AppStore {
  overviewLayout: GuideOverviewLayout;
  hasAbilities: boolean;
  fetchError: FetchError;
  timeoutError: boolean;
  isOffline: boolean;
  changeOverviewLayout: (newView: GuideOverviewLayout) => void;
  setHasAbilities: () => void;
  setFetchError: (error: FetchError) => void;
  setTimeoutError: (isError: boolean) => void;
  setisOffline: (isOffline: boolean) => void;
}

export const useAppStoreBase = create<AppStore>(set => ({
  hasAbilities: false,
  fetchError: null,
  timeoutError: false,
  isOffline: false,
  overviewLayout: GuideOverviewLayout.GRID,
  setFetchError: (error: FetchError) => set({ fetchError: error }),
  setTimeoutError: (isError: boolean) => set({ timeoutError: isError }),
  setisOffline: (isOffline: boolean) => set({ isOffline: isOffline }),
  setHasAbilities: () => set({ hasAbilities: true }),
  changeOverviewLayout: (newView: GuideOverviewLayout) =>
    set({ overviewLayout: newView }),
}));

export const useAppStore = createSelectors(useAppStoreBase);
