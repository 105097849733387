import { create } from 'zustand';
import {
  getOrderByFromLocalStorage,
  getOrderTypeFromLocalStorage,
} from '../helpers/browser';
import { LOCAL_STORAGE_KEYS } from '../constants/guides';
import { OrderBy, OrderType } from '../types';
import { createSelectors } from './helpers';

export enum OverviewSections {
  ALL = 'All',
  GUIDES = 'Guides',
  GROUPS = 'Groups',
  GROUP_DETAILS_OVERVIEW = 'Group Details Overview',
  HOMEPAGES = 'Homepages',
  THEMESETS = 'Themesets',
  SEARCH = 'Search',
  CUSTOM_LINKS = 'Custom Links',
}

interface OverviewStore {
  orderBy: OrderBy;
  setOrderBy: (newOrderBy: OrderBy) => void;
  orderType: OrderType;
  setOrderType: (newOrderType: OrderType) => void;
  activeSection: OverviewSections;
  setActiveSection: (section: OverviewSections) => void;
  activeGroupId: string;
  setActiveGroupId: (activeGroupId: string) => void;
}

const useOverviewStoreBase = create<OverviewStore>(set => ({
  activeGroupId: null,
  setActiveGroupId: activeGroupId => set(() => ({ activeGroupId })),
  orderBy: getOrderByFromLocalStorage(),
  setOrderBy: (newOrderBy: OrderBy) =>
    set(() => {
      localStorage.setItem(LOCAL_STORAGE_KEYS.OVERVIEW_ORDER_BY, newOrderBy);

      return {
        orderBy: newOrderBy,
      };
    }),
  orderType: getOrderTypeFromLocalStorage(),
  setOrderType: (newOrderType: OrderType) =>
    set(() => {
      localStorage.setItem(
        LOCAL_STORAGE_KEYS.OVERVIEW_ORDER_TYPE,
        newOrderType,
      );

      return {
        orderType: newOrderType,
      };
    }),
  activeSection: OverviewSections.GUIDES,
  setActiveSection: (section: OverviewSections) =>
    set(() => ({ activeSection: section })),
}));

export const useOverviewStore = createSelectors(useOverviewStoreBase);
