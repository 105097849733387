import { GUIDE_VIEW_MODE } from '../guides';
import { createBrowserHistory } from 'history';
import { QueryClient } from '@tanstack/react-query';

export const isDevelopment = import.meta.env.MODE === 'development';

export const history = createBrowserHistory({ basename: '/guidelines' });

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: { refetchOnWindowFocus: false, staleTime: Infinity },
  },
});

export const CONFIG = {
  API_WRITE_ENDPOINT_URL: import.meta.env.VITE_WRITE_API,
  API_READ_ENDPOINT_URL: import.meta.env.VITE_READ_API,
  API_SEARCH_ENDPOINT_URL: import.meta.env.VITE_SEARCH_API,
  API_READ_PUBLIC_ENDPOINT_URL: import.meta.env.VITE_READ_API_PUBLIC,
  SOCKET_SERVER_URL: import.meta.env.VITE_WS_SERVER,
  ROOT_ELEMENT_ID: 'root',
  ACCOUNT_BASE_ROUTE: isDevelopment
    ? 'http://127.0.0.1:5173'
    : window.location.origin,
  GRAPHQL_BASE_ROUTE: import.meta.env.VITE_GRAPHQL_API,
  CONTENT_CUSTOMIZATION_API: import.meta.env.VITE_CONTENT_CUSTOMIZATION_API,
  PERMISSIONS_SERVICE_API: import.meta.env.VITE_PERMISSIONS_SERVICE_API,
};

export const isDeimosStage = [
  'www.bynder-stage.com',
  'deimos-e2e-enterprise.bynder-stage.com',
  'deimos-e2e-advanced.bynder-stage.com',
  'deimos-e2e-basic.bynder-stage.com',
].includes(window.location.host);

export const isExperimental =
  [
    'deimos-e2e-experimental.bynder-stage.com',
    'experimental-global.bynder.com',
    'experimental-eu.bynder.com',
    'experimental-us.bynder.com',
  ].includes(window.location.host) || isDevelopment;

export const API_ROUTES = {
  GROUPS_READ: `${CONFIG.API_READ_ENDPOINT_URL}/groups`,
  GROUP_BY_ID_READ: (groupId: string) =>
    `${CONFIG.API_READ_ENDPOINT_URL}/groups/${groupId}`,
  GUIDES_READ: (isPublic: boolean) =>
    `${
      isPublic
        ? CONFIG.API_READ_PUBLIC_ENDPOINT_URL
        : CONFIG.API_READ_ENDPOINT_URL
    }/guides`,
  GROUPS_WRITE: `${CONFIG.API_WRITE_ENDPOINT_URL}/commands`,
  GROUPS_SEARCH: `${CONFIG.API_SEARCH_ENDPOINT_URL}/groups/search/`,
  GUIDES_WRITE: `${CONFIG.API_WRITE_ENDPOINT_URL}/commands`,
  GUIDES_SEARCH: `${CONFIG.API_SEARCH_ENDPOINT_URL}/guides/search/`,
  DOCUMENTS_SEARCH: `${CONFIG.API_SEARCH_ENDPOINT_URL}/documents/search/`,
  GUIDE_BY_ID_READ: (guideId: string, viewMode: GUIDE_VIEW_MODE) => {
    switch (viewMode) {
      case GUIDE_VIEW_MODE.PUBLIC:
        return `${CONFIG.API_READ_PUBLIC_ENDPOINT_URL}/guides/${guideId}`;
      case GUIDE_VIEW_MODE.EDIT:
      case GUIDE_VIEW_MODE.PREVIEW:
        return `${CONFIG.API_READ_ENDPOINT_URL}/guides/${guideId}/edit`;
      case GUIDE_VIEW_MODE.PUBLISHED:
      default:
        return `${CONFIG.API_READ_ENDPOINT_URL}/guides/${guideId}`;
    }
  },
  GUIDE_BY_ID_READ_NEW: (guideId: string, isPublic: boolean) =>
    isPublic
      ? `${CONFIG.API_READ_PUBLIC_ENDPOINT_URL}/guides/${guideId}`
      : `${CONFIG.API_READ_ENDPOINT_URL}/guides/${guideId}`,
  GUIDE_LIMIT_BY_ID: (guideId: string) =>
    `${CONFIG.API_READ_ENDPOINT_URL}/guides/${guideId}/size/`,
  PAGE_BY_ID: (guideId: string, pageId: string, isPublic: boolean) =>
    isPublic
      ? `${CONFIG.API_READ_PUBLIC_ENDPOINT_URL}/guides/${guideId}/pages/${pageId}`
      : `${CONFIG.API_READ_ENDPOINT_URL}/guides/${guideId}/pages/${pageId}`,
  PAGE_BY_ID_PUBLIC: (guideId: string, pageId: string) =>
    `${CONFIG.API_READ_PUBLIC_ENDPOINT_URL}/guides/${guideId}/pages/${pageId}`,
  USERS_LIST: `${CONFIG.ACCOUNT_BASE_ROUTE}/v7/identity/users/list/`,
  GROUPS_LIST: `${CONFIG.ACCOUNT_BASE_ROUTE}/v7/identity/groups/list/`,
  PROFILES_LIST: `${CONFIG.ACCOUNT_BASE_ROUTE}/v7/identity/profiles/`,
  CURRENT_USER: `${CONFIG.ACCOUNT_BASE_ROUTE}/v7/identity/users/current_user/`,
  SUGGESTIONS_PYSETTINGS: `${CONFIG.ACCOUNT_BASE_ROUTE}/v7/portal/settings/3F95AF05-3052-4A52-AF6FBAF8E8714BA1/`,
  USER_BY_ID: (userId: string) =>
    `${CONFIG.ACCOUNT_BASE_ROUTE}/v7/identity/users/${userId}/`,
  GRAPHQL: `${CONFIG.GRAPHQL_BASE_ROUTE}/graphql`,
  THEMESETS_READ: `${CONFIG.API_READ_ENDPOINT_URL}/themesets`,
  ENTITY_PERMISSIONS: (entityId: string) =>
    isDevelopment
      ? `${CONFIG.PERMISSIONS_SERVICE_API}/permissions/${entityId}`
      : `${CONFIG.PERMISSIONS_SERVICE_API}/v7/guidelines-permissions/permissions/${entityId}`,
  ASSET_OPEN: (assetId: string) =>
    `${window.location.origin}/media/?mediaId=${assetId}`,
  ASSET_DOWNLOAD: () =>
    isDevelopment
      ? `${CONFIG.ACCOUNT_BASE_ROUTE}/new_download/`
      : `${window.location.origin}/new_download/`,
  CHECK_IS_ASSET_DOWNLOADABLE: () =>
    isDevelopment
      ? `${CONFIG.ACCOUNT_BASE_ROUTE}/media/checkDownloadable/`
      : `${window.location.origin}/media/checkDownloadable/`,
  COLLECTION_DETAIL: (collectionId: string) =>
    `${window.location.origin}/web/${collectionId}`,
  COLLECTION_ASSET_DETAIL: (collectionId: string, assetId: string) =>
    `${window.location.origin}/web/${collectionId}/?mediaId=${assetId}`,
  COLLECTION_FEED: (collectionId: string) =>
    `${CONFIG.ACCOUNT_BASE_ROUTE}/feeds/collections/${collectionId}`,
  TOKEN_MOCK: (
    accountId: string,
    userId: string,
    profileId: string,
    permission: string,
  ) =>
    `${CONFIG.ACCOUNT_BASE_ROUTE}/v7/idp/access-tokens/by-session/${accountId}/${userId}/${profileId}/${permission}/`,
  PORTAL_INFORMATION: '/v7/portal/portals/current_portal/',
  PORTAL_HOMEPAGE: '/v7/portal/homepage/',
  ANALYTICS: (isPublic: boolean) =>
    isPublic
      ? `${CONFIG.API_READ_PUBLIC_ENDPOINT_URL}/analytics/track`
      : `${CONFIG.API_READ_ENDPOINT_URL}/analytics/track`,
  BATCH_WRITE: `${CONFIG.API_WRITE_ENDPOINT_URL}/commands/batch`,
  CUSTOM_LINKS: '/v7/vanity-url-shortener-svc/bynderly/v1/links',
};

export const EXTERNAL_ROUTES = {
  GUIDE: (id: string) => `${window.location.origin}/guidelines/guide/${id}`,
  GUIDE_EDIT: (id: string) =>
    `${window.location.origin}/guidelines/guide/${id}/edit`,
  PAGE: (guideId: string, pageId: string) =>
    `${window.location.origin}/guidelines/guide/${guideId}/page/${pageId}`,
  PAGE_EDIT: (guideId: string, pageId: string) =>
    `${window.location.origin}/guidelines/guide/${guideId}/page/${pageId}/edit`,
  GROUP_OVERVIEW: (groupId: string) =>
    `${window.location.origin}/guidelines/groups/${groupId}/`,
  THEMESET: (themesetId: string) => `/guidelines/themesets/${themesetId}`,
  INSPIRATION_GUIDE: 'https://design.getbynder.com/guidelines/',
  INSPIRATION_LANDING_PAGE:
    'https://brand-experiences.bynder.com/guidelines/guide/4ba0d889-df3f-4620-ba3d-d531ab7b33d0/page/6c9a2077-5c4d-4091-9b7a-f4b2d9ac6b39',
  WHATS_NEW_LINK:
    'https://brand-experiences.bynder.com/guidelines/guide/084c8828-f50f-432c-8f7d-aff12bdb91cd/page/f659b44e-3085-4d99-acb2-53c4c4b20c08',
};

export const ROUTES = {
  GUIDE_OVERVIEW: '/',
  GROUPS_OVERVIEW: '/groups',
  GUIDES_OVERVIEW: '/guides',
  PAGES_OVERVIEW: '/pages',
  ALL_OVERVIEW: '/all',
  CUSTOM_LINKS: '/custom-links',
  GROUP_OVERVIEW: (groupId: string) => `/groups/${groupId}/`,
  GUIDE_EDIT: (guideId: string) => `/guide/${guideId}/edit`,
  GUIDE: (guideId: string) => `/guide/${guideId}`,
  GUIDE_PAGE_EDIT: (guideId: string, pageId: string) =>
    `/guide/${guideId}/page/${pageId}/edit`,
  GUIDE_PAGE: (guideId: string, pageId: string) =>
    `/guide/${guideId}/page/${pageId}`,
  MAJESTIC_FONT_EDITOR: '/settings/ps/fonts',
  THEMESET_OVERVIEW: '/themesets',
  THEMESET: (themesetId: string) => `/themesets/${themesetId}`,
  THEMESET_COLORS: (themesetId: string) => `/themesets/${themesetId}/colors`,
  THEMESET_FONTS: (themesetId: string) => `/themesets/${themesetId}/fonts`,
  THEMESET_GUIDE_UI: (themesetId: string) =>
    `/themesets/${themesetId}/${ROUTES.GUIDE_UI}`,
  THEMESET_GUIDE_PROPERTIES: (themesetId: string) =>
    `/themesets/${themesetId}/properties`,
  GUIDE_UI: 'guideui',
  ENTITY_PEMISSION_WITH_DEPTH: (
    entityId: string,
    depth = 1,
    isNewService = false,
  ) =>
    `${CONFIG.PERMISSIONS_SERVICE_API}/${getPermissionsEndpoint(isNewService)}/hierarchy/${entityId}?depth=${depth}`,
  SEARCH: '/search',
};

export const DATES = {
  DATE_FORMAT: 'ddd MMM D, YYYY',
  SHORT_DATE_FORMAT: 'MMMM D, YYYY',
};

export const SEARCH_DEBOUNCE_WHILE_TYPING = 500;
export const WIDGET_DEBOUNCE_WHILE_TYPING = 2000;
export const MAX_LIMIT_PERMISSION_ENTITIES = 30;

export const X_WORKSPACE_KEY = { 'X-Workspace-Key': 'brand_guidelines' };

const getPermissionsEndpoint = (isNewService = false) => {
  let endpoint: string;

  if (isDevelopment) {
    endpoint = `permissions`;
  }
  if (isNewService) {
    endpoint = `v7/guidelines-access/permissions`;
  } else {
    endpoint = `v7/guidelines-permissions/permissions`;
  }

  return endpoint;
};
