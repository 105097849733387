import React from 'react';
import { Button, ModalBase } from '@bynder/design-system';
import PrimaryButton from '../shared/PrimaryButton';

export interface WarningModalProps {
  onCancel: () => void;
  onSubmit: () => void;
  headerText: string;
  contentText: string;
  primaryActionText?: string;
  secondaryActionText?: string;
}

const WarningModal: React.FC<WarningModalProps> = ({
  onCancel,
  onSubmit,
  headerText,
  contentText,
  primaryActionText,
  secondaryActionText,
}) => {
  return (
    <ModalBase isOpen onClose={onCancel}>
      <ModalBase.Header title={headerText} />
      <ModalBase.Content>{contentText}</ModalBase.Content>
      <ModalBase.Footer
        actionPrimary={
          <PrimaryButton onClick={onSubmit}>
            {primaryActionText || 'Agree'}
          </PrimaryButton>
        }
        actionSecondary={
          <Button variant="secondary" onClick={onCancel}>
            {secondaryActionText || 'Cancel'}
          </Button>
        }
      />
    </ModalBase>
  );
};

export default WarningModal;
