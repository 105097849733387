import ReactGA from 'react-ga4';

export const GAtrackNavigation = (title?: string) => {
  // We need to check that ReactGA has an ID in order to track,
  // otherwise a lot of warnings are going to be displayed
  if (ReactGA.ga()) {
    ReactGA.send({
      hitType: 'pageView',
      page: window.location.pathname + window.location.hash,
      title,
    });
  }
};
