import { AxiosResponse } from 'axios';
import moment from 'moment';

import axios from '../axios';
import { UploadedFile } from '../../types';
import { BynderAsset } from '../../types/upload';

const in100YearsFromNow = moment().add(100, 'years');

type BrandType = {
  image: string;
  name: string;
  id: string;
  description: string;
  subBrands: BrandType[];
};

export const csrf = document.head
  .querySelector('[name="x-csrf-token"]')
  ?.getAttribute('content');

export async function saveMediaApi(
  fileId: string,
  brandId: string,
  file: UploadedFile,
): Promise<AxiosResponse> {
  const params = new URLSearchParams();
  params.append('med.fileImportId', fileId);
  params.append('med.filename', file.filename);
  params.append('med.name', file.filename);
  params.append('med.size', file.fileSize.toString());
  params.append('med.height', file.height.toString());
  params.append('med.width', file.width.toString());
  params.append('med.fileId', fileId);
  params.append('med.brandId', brandId);
  params.append('med.intent', 'create_asset');
  params.append('med.isPublic', '1');
  params.append('med.appCodes', 'BG');
  params.append(
    'med.archiveDate',
    in100YearsFromNow.format('DD/MM/YYYY hh:mm'),
  );
  params.append('med.isoarchiveDate', in100YearsFromNow.format());

  return (await axios()).post(UPLOAD_ENDPOINTS.SAVE_MEDIA, params, {
    headers: {
      'x-csrf-token': window?.Bynder?.csrf_token || csrf,
      'content-type': 'application/x-www-form-urlencoded;charset=utf-8',
    },
  });
}

export async function retrieveAssetInfoApi(
  assetId: string,
): Promise<BynderAsset> {
  return (await axios(false))
    .get(UPLOAD_ENDPOINTS.PUBLIC_RETRIEVE_ASSET(assetId))
    .then(res => res.data);
}

export async function retrieveBrandsApi(): Promise<BrandType[]> {
  return (await axios(false))
    .get(UPLOAD_ENDPOINTS.PUBLIC_RETRIEVE_BRANDS)
    .then(res => res.data);
}

const UPLOAD_ENDPOINTS = {
  PUBLIC_RETRIEVE_BRANDS: '/api/v4/brands/',
  SAVE_MEDIA: '/import/savemedia/',
  RETRIEVE_DERIVATIVE: (mediaId: string, filename: string, io: string) =>
    `/transform/${mediaId}/${filename}?io=${io}`,
  PUBLIC_RETRIEVE_ASSET: (id: string) => `/api/v4/media/${id}`,
};
