import styled from 'styled-components';
import { token } from '@bynder/design-system';

interface Props {
  value: string;
  maxValue: number;
}

const MaxCharacterCounter = (props: Props) => {
  const { value, maxValue } = props;

  return (
    <StyledMaxCharacterCounter>
      {`${value.length} / ${maxValue}`}
    </StyledMaxCharacterCounter>
  );
};

const StyledMaxCharacterCounter = styled.span`
  color: ${token.gray300};
  font-size: ${token.fontSize75};
`;

export default MaxCharacterCounter;
