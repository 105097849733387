import React from 'react';
import {
  CompactView,
  CompactViewProps,
  Login,
  Modal,
} from '@bynder/compact-view';
import { isDevelopment } from '../../constants/app';
import { CVAsset, CVFile } from '../../types';

export interface CompactViewModalProps {
  onCancel: () => void;
  onSubmit: (selectedMedia?: CVAsset[], file?: CVFile) => void;
  width?: string;
  compactViewProps?: CompactViewProps;
}

const assetFieldSelection = `
  databaseId
  name
  url
  extensions
  originalUrl
  derivatives {
    webImage
  }
  ... on Video {
    previewUrls
  }
`;

const CompactViewModal: React.FC<CompactViewModalProps> = props => {
  const compactViewProps: CompactViewProps = {
    onSuccess: props.onSubmit,
    mode: 'SingleSelectFile',
    language: 'en_US',
    ...props.compactViewProps,
  };
  const accessToken = localStorage.getItem('jwt');
  const configuredCompactView = (
    <CompactView
      {...compactViewProps}
      assetFieldSelection={assetFieldSelection}
    />
  );

  return (
    <Modal onClose={props.onCancel}>
      {isDevelopment ? (
        <Login>{configuredCompactView}</Login>
      ) : (
        <Login
          authentication={{
            getAccessToken: () => accessToken,
            hideLogout: true,
          }}
        >
          {configuredCompactView}
        </Login>
      )}
    </Modal>
  );
};

export default CompactViewModal;
