export const DEFAULT_SECTION_WIDTH = 800;
export const SECTION_WIDTH_S = 800;
export const SECTION_WIDTH_M = 1200;
export const SECTION_WIDTH_L = 2048;
export const SECTION_WIDTH_XL = 3840;

export const DEFAULT_SECTION_HEIGHT = 120;
export const SECTION_MAX_HEIGHT = 2160;
export const SECTION_MIN_HEIGHT = 10;

export const SECTION_CONTENT_SPACING_SMALL = 8;
export const SECTION_CONTENT_SPACING_MEDIUM = 16;
export const SECTION_CONTENT_SPACING_LARGE = 32;
export const DEFAULT_SECTION_CONTENT_SPACING = SECTION_CONTENT_SPACING_MEDIUM;

const SECTION_PADDING_NONE = 0;
export const SECTION_PADDING_SMALL = 8;
export const SECTION_PADDING_MEDIUM = 16;
export const SECTION_PADDING_LARGE = 64;
export const DEFAULT_SECTION_HORIZONTAL_PADDING = SECTION_PADDING_NONE;
export const DEFAULT_SECTION_VERTICAL_PADDING = SECTION_PADDING_MEDIUM;

export const SECTION_COLUMN_GAP_NONE = 0;
export const SECTION_COLUMN_GAP_SMALL = 20;
export const SECTION_COLUMN_GAP_MEDIUM = 40;
export const SECTION_COLUMN_GAP_LARGE = 80;
export const DEFAULT_SECTION_COLUMN_GAP = SECTION_COLUMN_GAP_MEDIUM;
