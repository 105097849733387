import { DefaultTheme, ThemeProvider } from 'styled-components';
import { useGetFonts } from '../queries/themesets';

interface Props {
  children: React.ReactElement;
}

const FontsManager = (props: Props) => {
  const { data: availableFonts } = useGetFonts();

  const fontsTheme = { availableFonts };
  return (
    <ThemeProvider theme={fontsTheme as DefaultTheme}>
      {props.children}
    </ThemeProvider>
  );
};

export default FontsManager;
