import Axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import { invalidateAccessToken } from '@bynder/js-access-token-lib';
import { getToken } from './jwt';

export const maxAttempts = 2;
const TIMEOUT = 30000;

export default async (hasAuthorizationToken = true): Promise<AxiosInstance> => {
  if (hasAuthorizationToken) {
    const token = await getToken();
    const axios = Axios.create({
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    axios.defaults.timeout = TIMEOUT;

    return axios;
  }

  return Axios.create();
};

export const handleUnauthorized = (
  error: AxiosError,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  func: (...args: any[]) => Promise<AxiosResponse>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ...args: any[]
) => {
  const numberOfRetries = args[args.length - 1];

  if (error.response.status === 401 && numberOfRetries > 0) {
    console.log('Unauthorized request, refreshing token.');
    invalidateAccessToken();
    const funcArgs = args.slice(0, -1);

    if (args.length === 1) {
      func(args[0] - 1);
    } else {
      func(...funcArgs, numberOfRetries - 1);
    }

    throw error;
  } else {
    return Promise.reject(error);
  }
};
