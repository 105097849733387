import React from 'react';
import { IconShield, IconUserGroup, IconUser } from '@bynder/icons';
import { Button, Dropdown, List, Thumbnail } from '@bynder/design-system';
import { Translate, useTranslate } from '@bynder/localization';
import { RoleType, IdentityType, IdentityAccess, Identity } from '../../types';
import SkeletonElement from '../shared/styled-components/SkeletonElement';
import { SkeletonCircle } from '../shared/styled-components/Skeleton';
import { useEntityPermission } from '../../queries/permissions';
import { useIdentity, useUserIdentity } from '../../queries/identity';
import { AccessModalContext } from '../../context/accessmodal';

export const AccessModalIdentityList: React.FC = () => {
  const { translate } = useTranslate();
  const { identityList, entityId, editIdentity } =
    React.useContext(AccessModalContext);

  const { data: entityPermissions } = useEntityPermission(entityId);
  const { data: owner, isLoading: isLoadingOwner } = useUserIdentity(
    entityPermissions?.documentOwnerId,
  );

  const onDeleteFromList = (id: string) => {
    const newList = identityList.filter((item: Identity) => item.id !== id);
    editIdentity(newList);
  };

  const onChangeRole = (id: string, role: RoleType) => {
    // When copying arrays of objects, we need to do a deep copy
    // otherwise we will be manipulating the original objects
    const newList = JSON.parse(JSON.stringify(identityList));
    const index = identityList.findIndex((item: Identity) => item.id === id);
    newList[index].role = role;

    editIdentity(newList);
  };

  return (
    <List>
      <List.Group title={translate('ACCESS_LIST.HEADER_TEXT')}>
        {isLoadingOwner ? (
          <SkeletonOwner />
        ) : (
          <>
            <List.Item
              subtext={owner?.email}
              thumbnail={<UserThumbnail />}
              rightElements={
                <Button variant="clean" isDisabled>
                  {translate('ACCESS_LIST.OWNER_TEXT')}
                </Button>
              }
            >
              {owner?.full_name || translate('ACCESS_LIST.AUTHOR_UNKNOWN')}
            </List.Item>
            {identityList?.map((entity: IdentityAccess) => (
              <IdentityList
                key={entity.id}
                entity={entity}
                onChangeRole={onChangeRole}
                onDeleteFromList={onDeleteFromList}
              />
            ))}
          </>
        )}
      </List.Group>
    </List>
  );
};

interface IdentityListProps {
  entity: IdentityAccess;
  onChangeRole: (id: string, role: RoleType) => void;
  onDeleteFromList: (id: string) => void;
}

const IdentityList: React.FC<IdentityListProps> = ({
  entity,
  onChangeRole,
  onDeleteFromList,
}) => {
  const { id, identity: idType } = entity;
  const { data: identity, isLoading, isError } = useIdentity(id, idType);

  const renderIdentityInfo = () => {
    if (isError) {
      return <Translate id="ACCESS_LIST.USER_UNKNOWN" />;
    }

    if (isLoading) {
      return <SkeletonOwner />;
    }

    return idType === IdentityType.USER ? identity?.full_name : identity?.name;
  };

  return (
    <List.Item
      subtext={idType === IdentityType.USER && identity?.email}
      thumbnail={
        <Thumbnail
          variant="clean"
          icon={getIdentityIcons(idType)}
          shape="circle"
          size="m"
        />
      }
      rightElements={
        <DropdownActions
          entity={entity}
          onChangeRole={onChangeRole}
          onDeleteFromList={onDeleteFromList}
        />
      }
    >
      {renderIdentityInfo()}
    </List.Item>
  );
};

const DropdownActions: React.FC<IdentityListProps> = ({
  entity,
  onChangeRole,
  onDeleteFromList,
}) => {
  const isReader = entity.role === RoleType.READER;
  const isEditor = entity.role === RoleType.EDITOR;
  const { isError } = useIdentity(entity.id, entity.identity);

  return (
    <Dropdown
      trigger={({ isOpen, ...triggerProps }) => (
        <Button
          isPressed={isOpen}
          variant="clean"
          rightIcon={<Dropdown.Arrow />}
          aria-label="Actions"
          {...triggerProps}
        >
          <Dropdown.SelectButtonText>
            {isReader && <Translate id="ACCESS_LIST_ITEM.MODAL_VIEWER" />}
            {isEditor && <Translate id="ACCESS_LIST_ITEM.MODAL_EDITOR" />}
          </Dropdown.SelectButtonText>
        </Button>
      )}
      position="bottom-right"
    >
      <Dropdown.Item
        onClick={() => onChangeRole(entity.id, RoleType.READER)}
        isChecked={isReader}
        isDisabled={isReader || isError}
      >
        <Translate id="ACCESS_LIST_ITEM.MODAL_VIEWER" />
      </Dropdown.Item>

      <Dropdown.Item
        onClick={() => onChangeRole(entity.id, RoleType.EDITOR)}
        isChecked={isEditor}
        isDisabled={isEditor || isError}
      >
        <Translate id="ACCESS_LIST_ITEM.MODAL_EDITOR" />
      </Dropdown.Item>

      <Dropdown.Divider />

      <Dropdown.Item onClick={() => onDeleteFromList(entity.id)}>
        <Translate id="ACCESS_LIST_ITEM.MODAL_REMOVE" />
      </Dropdown.Item>
    </Dropdown>
  );
};

const UserThumbnail = () => (
  <Thumbnail variant="clean" icon={<IconUser />} shape="circle" size="m" />
);

const SkeletonOwner = () => {
  const { translate } = useTranslate();

  return (
    <List.Item
      subtext={<SkeletonElement width={120} height={12} />}
      thumbnail={<SkeletonCircle radius={40} />}
      rightElements={
        <Button variant="clean" isDisabled>
          {translate('ACCESS_LIST.OWNER_TEXT')}
        </Button>
      }
      data-testid="SkeletonOwner"
    >
      <SkeletonElement width={120} height={18} />
    </List.Item>
  );
};

const getIdentityIcons = (identityType: IdentityType) => {
  switch (identityType) {
    case IdentityType.USER:
      return <IconUser />;
    case IdentityType.GROUP:
      return <IconUserGroup />;
    case IdentityType.PROFILE:
      return <IconShield />;

    default:
      return <SkeletonCircle radius={48} />;
  }
};
