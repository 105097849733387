export const CLASSNAMES = {
  SECTION: 'section',
  SECTION_OVERLAY: 'section-overlay',
  SECTION_CONTAINER: 'section-container',
  BUTTON: 'button',
};

export const CSS_PROPERTIES = {
  FONT_SIZE: 'font-size',
  BORDER_RADIUS: 'border-radius',
  BACKGROUND_COLOR: 'background-color',
  JUSTIFY_CONTENT: 'justify-content',
  OPACITY: 'opacity',
  COLOR: 'color',
  MIN_HEIGHT: 'min-height',
  BORDER_COLOR: 'border-color',
  WIDTH: 'width',
  GAP: 'gap',
};

export const CSS_METAPROPERTIES = {
  HOVER: ':hover',
};

export const CSS_CLASSES = {
  CARD: '.card',
  CARD_TITLE: '.card-title',
  CARD_DESCRIPTION: '.card-description',
  COLOR_PALETTE_NAME: '.color-name',
  COLOR_PALETTE_KEY: '.color-key',
  COLOR_PALETTE_VALUE: '.color-value',
  COLLECTION_TITLE: '.collection-title',
  COLLECTION_ASSET_COUNT: '.collection-asset-count',
  COLLECTION_ASSET: '.collection-asset',
};

export const INSPECTOR_DEBOUNCE_DELAY = 500;
