import { generateRandomTextBlockKey } from '../../helpers/utils';

export enum TemplatesEnum {
  WHITE_TEMPLATE = 'WHITE_TEMPLATE',
  HERO_TEMPLATE = 'HERO_TEMPLATE',
  GTM_TEMPLATE = 'GTM_TEMPLATE',
  PL_TEMPLATE = 'PL_TEMPLATE',
  TWO_CARD_TEMPLATE = 'TWO_CARD_TEMPLATE',
  THREE_CARD_TEMPLATE = 'THREE_CARD_TEMPLATE',
  FOUR_CARD_TEMPLATE = 'FOUR_CARD_TEMPLATE',
  FIVE_CARD_TEMPLATE = 'FIVE_CARD_TEMPLATE',
  SIX_CARD_TEMPLATE = 'SIX_CARD_TEMPLATE',
  EIGHT_CARD_TEMPLATE = 'EIGHT_CARD_TEMPLATE',
  TEN_CARD_TEMPLATE = 'TEN_CARD_TEMPLATE',
  TWO_COLUMN_CARD_NAVIGATION_TEMPLATE = 'TWO_COLUMN_CARD_NAVIGATION_TEMPLATE',
  TWO_COLUMN_HERO_TEMPLATE = 'TWO_COLUMN_HERO_TEMPLATE',
  TWO_COLUMN_RIGHT_HERO_TEMPLATE = 'TWO_COLUMN_RIGHT_HERO_TEMPLATE',
  TWO_COLUMN_LEFT_HERO_TEMPLATE = 'TWO_COLUMN_LEFT_HERO_TEMPLATE',
  THREE_COLUMN_CARD_NAVIGATION_TEMPLATE = 'THREE_COLUMN_CARD_NAVIGATION_TEMPLATE',
  TWO_CARD_LARGE_TEMPLATE = 'TWO_CARD_LARGE_TEMPLATE',
  TWO_COLUMN_NAVIGATION_TEMPLATE = 'TWO_COLUMN_NAVIGATION_TEMPLATE',
  DARK_LEFT_RIGHT_PAGE_NAVIGATION_TEMPLATE = 'DARK_LEFT_RIGHT_PAGE_NAVIGATION_TEMPLATE',
  LIGHT_LEFT_RIGHT_PAGE_NAVIGATION_TEMPLATE = 'LIGHT_LEFT_RIGHT_PAGE_NAVIGATION_TEMPLATE',
  DARK_UP_DOWN_PAGE_NAVIGATION_TEMPLATE = 'DARK_UP_DOWN_PAGE_NAVIGATION_TEMPLATE',
  LIGHT_UP_DOWN_PAGE_NAVIGATION_TEMPLATE = 'LIGHT_UP_DOWN_PAGE_NAVIGATION_TEMPLATE',
  DARK_SQUARE_PAGINATION_TEMPLATE = 'DARK_SQUARE_PAGINATION_TEMPLATE',
  LIGHT_SQUARE_PAGINATION_TEMPLATE = 'LIGHT_SQUARE_PAGINATION_TEMPLATE',
  DARK_ROUND_PAGINATION_TEMPLATE = 'DARK_ROUND_PAGINATION_TEMPLATE',
  LIGHT_ROUND_PAGINATION_TEMPLATE = 'LIGHT_ROUND_PAGINATION_TEMPLATE',
  FOOTER_TEXT_TEMPLATE = 'FOOTER_TEXT_TEMPLATE',
  FOOTER_WITH_IMAGE_TEMPLATE = 'FOOTER_WITH_IMAGE_TEMPLATE',
  TEXT_TEMPLATE = 'TEXT_TEMPLATE',
  TWO_COLUMN_FEATURED_TEMPLATE = 'TWO_COLUMN_FEATURED_TEMPLATE',
  HERO_WITH_IMAGE_TEMPLATE = 'HERO_WITH_IMAGE_TEMPLATE',
  LANDING_PAGE_TEMPLATE = 'LANDING_PAGE_TEMPLATE',
  GUIDE_PAGE_TEMPLATE = 'GUIDE_PAGE_TEMPLATE',
  HOMEPAGE_TEMPLATE = 'HOMEPAGE_TEMPLATE',
  BRAND_PORTAL_TEMPLATE = 'BRAND_PORTAL_TEMPLATE',
}

export const DEFAULT_SECTION_TITLE = `{"blocks":[{"key":"${generateRandomTextBlockKey()}","text":"Lorem ipsum dolor sit amet","type":"header-three","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{"text-align":"left"}}],"entityMap":{}}`;
export const DEFAULT_TEXT_HERO_TEMPLATE = `{"blocks":[{"key":"${generateRandomTextBlockKey()}","text":"Hero Banner Title","type":"header-three","depth":0,"inlineStyleRanges":[{"offset":0,"length":17,"style":"color-#000000"},{"offset":0,"length":17,"style":"BOLD"}],"entityRanges":[],"data":{"text-align":"center"}},{"key":"${generateRandomTextBlockKey()}","text":"Subtitle","type":"header-five","depth":0,"inlineStyleRanges":[{"offset":0,"length":20,"style":"color-#000000"}],"entityRanges":[],"data":{"text-align":"center"}}],"entityMap":{}}`;
export const DEFAULT_TEXT_TEXT_TEMPLATE = `{"blocks":[{"key":"${generateRandomTextBlockKey()}","text":"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}`;
export const TITLE_HOMEPAGE_TEMPLATE = `{"blocks":[{"key":"${generateRandomTextBlockKey()}","text":"Welcome to Your DAM Portal","type":"header-three","depth":0,"inlineStyleRanges":[{"offset":0,"length":26,"style":"BOLD"}],"entityRanges":[],"data":{"text-align":"center"}}],"entityMap":{}}`;
export const TEXT_HOMEPAGE_TEMPLATE = `{"blocks":[{"key":"${generateRandomTextBlockKey()}","text":"Your homepage is your gateway to all things your brand. With our pre-built templates and widgets, you can easily customize your homepage.","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{"text-align":"center"}},{"key":"${generateRandomTextBlockKey()}","text":"Use it to showcase your brand, organize key assets, and easily navigate to everything you need.","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{"text-align":"center"}}],"entityMap":{}}`;
export const REDIRECT_TITLE = `{"blocks":[{"key":"${generateRandomTextBlockKey()}","text":"Redirection to pre-filtered views in the asset bank","type":"header-four","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{"text-align":"center"}}],"entityMap":{}}`;
export const CARDS_INFO_TEXT = `{"blocks":[{"key":"${generateRandomTextBlockKey()}","text":"Add custom thumbnails and links to get users where they need quickly.","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{"text-align":"center"}}],"entityMap":{}}`;
export const COLLECTIONS_TITLE = `{"blocks":[{"key":"${generateRandomTextBlockKey()}","text":"Collections","type":"header-four","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{"text-align":"center"}}],"entityMap":{}}`;
export const COLLECTION_INFO_TEXT = `{"blocks":[{"key":"${generateRandomTextBlockKey()}","text":"You can embed a Collection from the DAM to publish a group of assets. If you embed the Collection, it becomes public and accessible to anyone with access.","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{"text-align":"center"}}],"entityMap":{}}`;
export const QUICK_LINKS_TITLE = `{"blocks":[{"key":"${generateRandomTextBlockKey()}","text":"Quick Links","type":"header-four","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{"text-align":"center"}}],"entityMap":{}}`;
export const EXPRESS_TITLE = `{"blocks":[{"key":"${generateRandomTextBlockKey()}","text":"Share Files Via Bynder Express","type":"header-four","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{"text-align":"left"}}],"entityMap":{}}`;
export const EXPRESS_INFO_TEXT = `{"blocks":[{"key":"${generateRandomTextBlockKey()}","text":"You can use Bynder Express instead of a third-party app (WeTransfer or Hightail) to share files (up to 40 GB) via email or link.","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"${generateRandomTextBlockKey()}","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"${generateRandomTextBlockKey()}","text":"The files you share in Bynder Express will not be uploaded or shown in the Asset Bank and will not require a login to download. The transferred files count toward your contracted DAM storage; however, once the link expires, it will no longer count toward your storage space.","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":38,"length":8,"style":"BOLD"}],"entityRanges":[],"data":{}}],"entityMap":{}}`;
export const VIDEOS_TITLE = `{"blocks":[{"key":"${generateRandomTextBlockKey()}","text":"Add videos to make your homepage more dynamic","type":"header-four","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{"text-align":"center"}}],"entityMap":{}}`;
export const VIDEOS_INFO_TEXT = `{"blocks":[{"key":"${generateRandomTextBlockKey()}","text":"Embed videos from Youtube and Vimeo or directly from your DAM.","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{"text-align":"center"}}],"entityMap":{}}`;
export const NAVIGATION_LINKS_TITLE = `{"blocks":[{"key":"${generateRandomTextBlockKey()}","text":"Navigation links","type":"header-four","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}`;
export const LINK_HOMEPAGE_TEMPLATE = `{"blocks":[{"key":"${generateRandomTextBlockKey()}","text":"Link","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[{"offset":0,"length":6,"key":0}],"data":{}}],"entityMap":{"0":{"type":"LINK","mutability":"MUTABLE","data":{"url":"http://about:blank","targetOption":"_blank"}}}}`;
export const DAM_TITLE = `{"blocks":[{"key":"${generateRandomTextBlockKey()}","text":"Empower Your Creativity with Our Digital Asset Management Solution","type":"header-three","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}`;
export const DAM_TEXT = `{"blocks":[{"key":"${generateRandomTextBlockKey()}","text":"Effortlessly Organize, Access, and Share Your Digital Assets Anytime, Anywhere","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}`;
export const ASSET_BANK_TITLE = `{"blocks":[{"key":"${generateRandomTextBlockKey()}","text":"Redirection to pre-filtered views in the asset bank","type":"header-four","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{"text-align":"center"}}],"entityMap":{}}`;
export const ASSET_BANK_TEXT = `{"blocks":[{"key":"${generateRandomTextBlockKey()}","text":"Add custom thumbnails and links to get users where they need quickly.","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{"text-align":"center"}}],"entityMap":{}}`;
export const LINKS_TITLE = `{"blocks":[{"key":"${generateRandomTextBlockKey()}","text":"Links","type":"header-four","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}`;
export const LINKS_TEXT = `{"blocks":[{"key":"${generateRandomTextBlockKey()}","text":"All the quick links for you users","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}`;
export const FOOTER_TEXT = `{"blocks":[{"key":"${generateRandomTextBlockKey()}","text":"[Name] | Privacy Policy | Terms of Service | Contact Support\\n© [Year] [Name]. All rights reserved.","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{"text-align":"center"}}],"entityMap":{}}`;
export const GTM_TITLE = `{"blocks":[{"key":"${generateRandomTextBlockKey()}","text":"Bringing Innovation to Market Faster","type":"header-three","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{"text-align":"center"}}],"entityMap":{}}`;
export const GTM_TEXT = `{"blocks":[{"key":"${generateRandomTextBlockKey()}","text":"Discover how [Your Company Name] is revolutionizing [industry/market] with our breakthrough [product/service].","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{"text-align":"center"}}],"entityMap":{}}`;
export const INDUSTRY_TITLE = `{"blocks":[{"key":"${generateRandomTextBlockKey()}","text":"Positioned to Lead in [Industry]","type":"header-four","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}`;
export const INDUSTRY_TEXT = `{"blocks":[{"key":"${generateRandomTextBlockKey()}","text":"[Your Company Name] is at the forefront of [industry], offering [unique product/service]. Our strategic approach ensures we not only meet but exceed market demands, positioning us as the go-to choice for [target audience].\\n\\nKey Points:\\nMarket Insight 1: Detail your understanding of the market.\\nMarket Insight 2: Highlight a key trend or need your product addresses.\\nMarket Insight 3: Showcase your competitive advantage.","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}`;
export const LAUNCH_TITLE = `{"blocks":[{"key":"${generateRandomTextBlockKey()}","text":"Product/Service Launch Section","type":"header-four","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{"text-align":"center"}}],"entityMap":{}}`;
export const LAUNCH_TEXT = `{"blocks":[{"key":"${generateRandomTextBlockKey()}","text":"We've developed a robust go-to-market strategy that ensures a successful launch and sustained growth. From targeted campaigns to strategic partnerships, we're poised to make a significant impact.","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{"text-align":"center"}}],"entityMap":{}}`;
export const AUDIENCE_TITLE = `{"blocks":[{"key":"${generateRandomTextBlockKey()}","text":"Designed for [Target Audience]","type":"header-four","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{"text-align":"center"}}],"entityMap":{}}`;
export const AUDIENCE_TEXT = `{"blocks":[{"key":"${generateRandomTextBlockKey()}","text":"Our product/service is tailored specifically for [target audience], addressing their unique needs and challenges.","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{"text-align":"center"}}],"entityMap":{}}`;
export const SUCCESS_TITLE = `{"blocks":[{"key":"${generateRandomTextBlockKey()}","text":"Empowering Your Success","type":"header-four","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{"text-align":"center"}}],"entityMap":{}}`;
export const SUCCESS_TEXT = `{"blocks":[{"key":"${generateRandomTextBlockKey()}","text":"We equip our partners and customers with the tools they need to succeed. From comprehensive onboarding to ongoing support, we ensure that you have everything necessary to maximize the potential of our product/service","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{"text-align":"center"}}],"entityMap":{}}`;
export const PARTNER_TITLE = `{"blocks":[{"key":"${generateRandomTextBlockKey()}","text":"Partner with Us for Market Success","type":"header-four","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{"text-align":"center"}}],"entityMap":{}}`;
export const PARTNER_TEXT = `{"blocks":[{"key":"${generateRandomTextBlockKey()}","text":"Interested in collaborating or learning more about our product? Let's connect. Reach out to us at [email/phone/address].","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{"text-align":"center"}}],"entityMap":{}}`;
export const WAIT_TITLE = `{"blocks":[{"key":"${generateRandomTextBlockKey()}","text":"The Wait is Almost Over!","type":"header-four","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}`
export const WAIT_TEXT = `{"blocks":[{"key":"${generateRandomTextBlockKey()}","text":"Mark your calendar for [Launch Date]. Don’t miss your chance to be among the first to own [Product Name] and experience [key benefit].","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}`
export const PRODUCT_TITLE = `{"blocks":[{"key":"${generateRandomTextBlockKey()}","text":"See [Product Name] in Action","type":"header-four","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{"text-align":"center"}}],"entityMap":{}}`
export const PRODUCT_TEXT = `{"blocks":[{"key":"${generateRandomTextBlockKey()}","text":"Get a sneak peek of what [Product Name] can do. Watch our demo to see how it's set to transform [industry/solution].","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{"text-align":"center"}}],"entityMap":{}}`;
export const USERS_TITLE = `{"blocks":[{"key":"${generateRandomTextBlockKey()}","text":"Here’s What Our Early Users Are Saying","type":"header-four","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{"text-align":"center"}}],"entityMap":{}}`;
export const USER_TEXT = `{"blocks":[{"key":"${generateRandomTextBlockKey()}","text":"Our beta testers and early adopters have had the chance to try [Product Name]—here's what they think","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{"text-align":"center"}}],"entityMap":{}}`;
export const QUESTIONS_TITLE = `{"blocks":[{"key":"${generateRandomTextBlockKey()}","text":"Have Questions? We're Here to Help","type":"header-four","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{"text-align":"center"}}],"entityMap":{}}`;
export const QUESTION_TEXT = `{"blocks":[{"key":"${generateRandomTextBlockKey()}","text":"Whether you’re curious about [Product Name] or need help with your order, our team is ready to assist. Reach out to us at [email/phone/address].","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{"text-align":"center"}}],"entityMap":{}}`;
export const HEADLINE_TITLE = `{"blocks":[{"key":"${generateRandomTextBlockKey()}","text":"Headline goes here.","type":"header-one","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}`;
export const SUBHEADLINE_TEXT = `{"blocks":[{"key":"${generateRandomTextBlockKey()}","text":"An optional sub-headline goes here.","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":35,"style":"BOLD"}],"entityRanges":[],"data":{}}],"entityMap":{}}`;
export const GROUP_A_TEXT = `{"blocks":[{"key":"${generateRandomTextBlockKey()}","text":"Group A","type":"header-one","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{"text-align":"center"}}],"entityMap":{}}`;
export const GROUP_B_TEXT = `{"blocks":[{"key":"${generateRandomTextBlockKey()}","text":"Group B","type":"header-one","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{"text-align":"center"}}],"entityMap":{}}`;
export const SECTION_TITLE = `{"blocks":[{"key":"${generateRandomTextBlockKey()}","text":"Section title","type":"header-three","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{"text-align":"center"}}],"entityMap":{}}`
export const WELCOME_TITLE = `{"blocks":[{"key":"${generateRandomTextBlockKey()}","text":"Welcome to the","type":"header-four","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{"text-align":"center"}}],"entityMap":{}}`;
export const BRAND_PORTAL_TITLE = `{"blocks":[{"key":"${generateRandomTextBlockKey()}","text":"BRAND PORTAL","type":"header-one","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{"text-align":"center"}}],"entityMap":{}}`;
export const QUOTE_TITLE = `{"blocks":[{"key":"${generateRandomTextBlockKey()}","text":"Section title or a transitional quote","type":"header-one","depth":0,"inlineStyleRanges":[{"offset":0,"length":37,"style":"BOLD"}],"entityRanges":[],"data":{"text-align":"center"}}],"entityMap":{}}`;
export const CONTEXT_TEXT = `{"blocks":[{"key":"${generateRandomTextBlockKey()}","text":"ADDITIONAL DETAILS OR CONTEXT","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":29,"style":"BOLD"}],"entityRanges":[],"data":{"text-align":"center"}}],"entityMap":{}}`;
export const FEATURED_TITLE = `{"blocks":[{"key":"${generateRandomTextBlockKey()}","text":"Featured news item","type":"header-three","depth":0,"inlineStyleRanges":[{"offset":0,"length":18,"style":"BOLD"}],"entityRanges":[],"data":{}}],"entityMap":{}}`;
export const COMPANY_FOOTER_TEXT = `{"blocks":[{"key":"${generateRandomTextBlockKey()}","text":"Company Footer","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}`;
export const COPYRIGHT_TEXT = `{"blocks":[{"key":"${generateRandomTextBlockKey()}","text":"© Copyright 2024・Brand","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}`

export const BUTTON_STYLE_HOMEPAGE_TEMPLATE =
  'background-color:#000000;\ncolor:#FFFFFF;\n&:hover{\nbackground-color:#000000;\ncolor:#FFFFFF;\n}\n';
export const DEFAULT_BUTTON_STYLE_HERO_TEMPLATE =
  'background-color:#000000;\ncolor:#FFFFFF;\n&:hover{\nborder-color:#000000;\nbackground-color:#FFFFFF;\ncolor:#000000;\n}\n';
export const DEFAULT_BUTTON_STYLE_HERO_SECONDARY_TEMPLATE =
  'background-color:#FFFFFF;\ncolor:#000000;\nborder-color:#000000;\n&:hover{\nborder-color:#000000;\nbackground-color:#000000;\ncolor:#FFFFFF;\n}\n';
export const SQUARE_PAGINATION_LIGHT = 'border-radius:0px;\nborder-color:#000000;\nbackground-color:#FFFFFF;\ncolor:#000000;\n&:hover{\nbackground-color:#000000;\ncolor:#FFFFFF;\n}\n';
export const SQUARE_PAGINATION_DARK = 'border-radius:0px;\nborder-color:#000000;\nbackground-color:#000000;\ncolor:#FFFFFF;\n&:hover{\nbackground-color:#FFFFFF;\ncolor:#000000;\n}\n';
export const ROUNDED_PAGINATION_LIGHT = 'border-radius:200px;\nborder-color:#000000;\nbackground-color:#FFFFFF;\ncolor:#000000;\n&:hover{\nbackground-color:#000000;\ncolor:#FFFFFF;\n}\n';
export const ROUNDED_PAGINATION_DARK = 'border-radius:200px;\nborder-color:#000000;\nbackground-color:#000000;\ncolor:#FFFFFF;\n&:hover{\nbackground-color:#FFFFFF;\ncolor:#000000;\n}\n';
export const CLEAR_BUTTON = 'background-color:#FFFFFF;\ncolor:#000000;\n&:hover{\nbackground-color:#000000;\ncolor:#FFFFFF;\n}\n';

export const BUTTON_TEXT_HOMEPAGE_TEMPLATE = "Let's get started";
export const EMBED_COLLECTION_BUTTON_TEXT =
  'Find out how to embed a collection';
export const DEFAULT_BUTTON_TEXT_HERO_TEMPLATE = 'Call to Action';
export const PAGINATION_LEFT_BUTTON = '←';
export const PAGINATION_RIGHT_BUTTON = '→';
export const PAGINATION_UP_BUTTON = '↑';
export const PAGINATION_DOWN_BUTTON = '↓';
export const OTHER_LINK_BUTTON = 'Other Link';
export const EARLY_ACCESS_BUTTON = 'Get Early Access';
export const STRATEGY_BUTTON = 'Learn About Our Strategy';
export const ROADMAP_BUTTON = 'Explore Our Roadmap';
export const SERVE_BUTTON = 'See How We Serve Your';
export const RESOURCE_BUTTON = 'Access Resource';
export const CONTACT_BUTTON = 'Contact Us';
export const PREORDER_BUTTON = 'Pre-Order Today';
export const DEMO_BUTTON = 'Watch the Full Demo';
export const REVIEWS_BUTTON = 'See More Reviews';
export const NEW_BUTTON = 'New Button';
export const ACTIVE_BUTTON = 'Active';
export const BADGE_BUTTON = 'New Badge';
export const PRIMARY_BUTTON = 'Primary Button';
export const SECONDARY_BUTTON = 'Secondary Button';