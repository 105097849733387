import moment from 'moment';
import { DATES } from '../constants/app';
import { getLocale } from './i18n';

export const getNow = () => new Date().toISOString();

const CHINESE_LOCALE = 'zh';
const CHINESE_TRADITIONAL = 'zh-cn';

export const formatTimestamp = (
  timestamp: moment.MomentInput,
  format = DATES.DATE_FORMAT,
) => {
  const locale = getLocale().split('_')[0];

  return locale === CHINESE_LOCALE
    ? moment.utc(timestamp, undefined, CHINESE_TRADITIONAL).format(format)
    : moment.utc(timestamp, undefined, locale).format(format);
};

export const fromNowTimestamp = (timestamp: moment.MomentInput) => {
  const locale = getLocale().split('_')[0];

  return locale === CHINESE_LOCALE
    ? moment.utc(timestamp, undefined, CHINESE_TRADITIONAL).fromNow()
    : moment.utc(timestamp).fromNow();
};

export const lessThanOneSecondAgo = (timestamp: moment.MomentInput) =>
  Math.abs(moment().diff(timestamp)) < 1000;
