import { create } from 'zustand';
import { ModalType } from '../types';
import { createSelectors } from './helpers';

interface GuideStore {
  isCreatingGuide: boolean;
  isCreatingGroup: boolean;
  isCreatingLandingPage: boolean;
  assetDetailOverlay: {
    isVisible: boolean;
    assetName: string | null;
    assetDetailUrl: string | null;
    assetUrl: string | null;
    showOpenAssetInAssetBank?: boolean;
  };
  modal: {
    modalType: ModalType | null;
    modalProps: object | null;
    modalOnSubmit: (...args: unknown[]) => void | null;
  };
  openModal: ({
    modalType,
    modalProps,
    modalOnSubmit,
  }: {
    modalType: ModalType;
    modalProps?: object;
    modalOnSubmit: (...args: unknown[]) => void;
  }) => void;
  toggleIsCreatingGuide: (value: boolean) => void;
  toggleIsCreatingGroup: (value: boolean) => void;
  toggleIsCreatingLandingPage: (value: boolean) => void;
  closeModal: () => void;
  showAssetDetailOverlay: (
    assetName: string,
    assetDetailUrl: string,
    assetUrl: string,
    showOpenAssetInAssetBank: boolean,
  ) => void;
  hideAssetDetailOverlay: () => void;
}

export const useModalStoreBase = create<GuideStore>(set => ({
  isCreatingGroup: false,
  isCreatingGuide: false,
  isCreatingLandingPage: false,
  assetDetailOverlay: {
    isVisible: false,
    assetName: null,
    assetDetailUrl: null,
    assetUrl: null,
  },
  modal: {
    modalType: null,
    modalProps: null,
    modalOnSubmit: null,
  },
  hideAssetDetailOverlay: () =>
    set(() => ({
      assetDetailOverlay: {
        isVisible: false,
        assetName: null,
        assetDetailUrl: null,
        assetUrl: null,
      },
    })),
  showAssetDetailOverlay: (
    assetName: string,
    assetDetailUrl: string,
    assetUrl: string,
    showOpenAssetInAssetBank: boolean,
  ) =>
    set(() => ({
      assetDetailOverlay: {
        isVisible: true,
        assetName,
        assetDetailUrl,
        assetUrl,
        showOpenAssetInAssetBank,
      },
    })),
  toggleIsCreatingLandingPage: (value: boolean) =>
    set(() => ({ isCreatingLandingPage: value })),
  toggleIsCreatingGroup: (value: boolean) =>
    set(() => ({ isCreatingGroup: value })),
  toggleIsCreatingGuide: (value: boolean) =>
    set(() => ({ isCreatingGuide: value })),
  openModal: ({ modalType, modalProps, modalOnSubmit }) =>
    set(() => ({ modal: { modalType, modalProps, modalOnSubmit } })),
  closeModal: () =>
    set(() => ({
      modal: { modalType: null, modalProps: null, modalOnSubmit: null },
    })),
}));

export const useModalStore = createSelectors(useModalStoreBase);
