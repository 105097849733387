import { ThemeProvider } from 'styled-components';
import themesetQueries from '../queries/themesets';

interface Props {
  children: React.ReactElement;
}

const TypographiesManager = (props: Props) => {
  const themesetTypographies = themesetQueries.getThemesetTypographies();
  const typographiesTheme = { themesetTypographies };

  return (
    <ThemeProvider theme={typographiesTheme}>{props.children}</ThemeProvider>
  );
};

export default TypographiesManager;
