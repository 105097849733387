import React from 'react';
import styled from 'styled-components';
import { Translate } from '@bynder/localization';
import { Button, List, ModalBase, token } from '@bynder/design-system';
import PrimaryButton from '../shared/PrimaryButton';

export interface DeleteModalProps {
  onCancel: () => void;
  onSubmit: () => void;
  headerText?: string;
  checkbox1Text?: string;
  checkbox2Text?: string;
  contentText?: string;
  buttonText?: string;
  isDanger?: boolean;
}

const DeleteModal: React.FunctionComponent<DeleteModalProps> = ({
  onCancel,
  headerText,
  contentText,
  onSubmit,
  checkbox1Text,
  checkbox2Text,
  buttonText,
}) => {
  const [isButtonDisabled, setIsButtonDisabled] = React.useState(true);
  const [isChecked, setIsChecked] = React.useState(false);
  const [isChecked2, setIsChecked2] = React.useState(false);

  const hasCheckboxes = checkbox1Text || checkbox2Text;

  React.useEffect(() => {
    if (checkbox1Text) {
      if (isChecked && isChecked2) {
        setIsButtonDisabled(false);
      } else {
        setIsButtonDisabled(true);
      }
    } else {
      setIsButtonDisabled(false);
    }
  }, [checkbox1Text, isChecked, isButtonDisabled, isChecked2]);

  return (
    <ModalBase isClosedOnOverlayClick isOpen onClose={onCancel}>
      <ModalBase.Header title={headerText} />
      <ModalBase.Content>
        <StyledContextContainer>{contentText}</StyledContextContainer>
        {checkbox1Text && (
          <List>
            <List.Item
              checkedVariant="checkbox"
              onClick={() => setIsChecked(!isChecked)}
              isChecked={isChecked}
              data-testid="checkbox1"
            >
              {checkbox1Text}
            </List.Item>
            <List.Item
              checkedVariant="checkbox"
              onClick={() => setIsChecked2(!isChecked2)}
              isChecked={isChecked2}
              data-testid="checkbox2"
            >
              {checkbox2Text}
            </List.Item>
          </List>
        )}
      </ModalBase.Content>
      <ModalBase.Footer
        actionPrimary={
          <>
            {hasCheckboxes ? (
              <Button
                isDisabled={isButtonDisabled}
                onClick={onSubmit}
                data-testid="submitButton"
                variant="danger"
              >
                {buttonText || (
                  <Translate id="DELETE_MODAL.DELETE_BUTTON_TEXT" />
                )}
              </Button>
            ) : (
              <PrimaryButton
                isDisabled={isButtonDisabled}
                onClick={onSubmit}
                data-testid="submitButton"
              >
                {buttonText || (
                  <Translate id="DELETE_MODAL.DELETE_BUTTON_TEXT" />
                )}
              </PrimaryButton>
            )}
          </>
        }
        actionSecondary={
          <Button type="button" variant="secondary" onClick={onCancel}>
            <Translate id="DELETE_MODAL.CANCEL_BUTTON_TEXT" />
          </Button>
        }
      />
    </ModalBase>
  );
};

export default DeleteModal;

const StyledContextContainer = styled.div`
  font-size: ${token.fontSize100};
`;
