import { useQuery } from '@tanstack/react-query';
import {
  defaultPySettings,
  fetchSuggestionsPySettings,
} from '../helpers/api/settingsApi';

const SETTINGS_KEYS = {
  PYSETTINGS: 'PYSETTINGS',
};

export const useShareSuggestionsPySettings = () => {
  return useQuery(
    [SETTINGS_KEYS.PYSETTINGS],
    () => fetchSuggestionsPySettings(),
    {
      select: data => (data === null ? defaultPySettings : data),
    },
  );
};
