import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslate } from '@bynder/localization';
import {
  editChapterAccessApi,
  editGroupAccessApi,
  editGuideAccessApi,
  editPageAccessApi,
} from '../helpers/api/permissionsApi';
import { generateAccessList } from '../helpers/permissions';
import { useOverviewGroupById } from '../queries/groups';
import {
  useGetPageChapterId,
  useOverviewDocumentById,
} from '../queries/guides';
import { PERMISSIONS_KEYS } from '../queries/permissions';
import {
  EntityPermission,
  EntityVisibility,
  Group,
  Guide,
  Identity,
  IdentityAccess,
  PermissionDocumentType,
} from '../types';
import { handleMutationSuccess, updateGuideVersion } from './helpers';
import { useModalStore } from '../stores/modalStore';

interface EditGuideOptions {
  entityVisibility: EntityVisibility;
  identityList: Identity[];
}

export const useEditAccess = (
  entityId: string,
  activeGuideId: string,
  entityType: PermissionDocumentType,
) => {
  const queryClient = useQueryClient();
  const { translate } = useTranslate();
  const closeModal = useModalStore.use.closeModal();
  const guide: Guide = useOverviewDocumentById(entityId);
  const activeGuide: Guide = useOverviewDocumentById(activeGuideId);
  const group: Group = useOverviewGroupById(entityId);
  const chapterId = useGetPageChapterId(activeGuide?.id, entityId);

  const queryData: EntityPermission = queryClient.getQueryData([
    PERMISSIONS_KEYS.ENTITY_PERMISSIONS,
    entityId,
  ]);

  return useMutation(
    (editGuideOptions: EditGuideOptions) => {
      const { viewAccessList, editAccessList } = generateAccessList(
        queryData.permissions,
        editGuideOptions.identityList as IdentityAccess[],
      );

      if (entityType === PermissionDocumentType.GUIDE) {
        return editGuideAccessApi(
          entityId,
          viewAccessList,
          editAccessList,
          editGuideOptions.entityVisibility,
          guide.version,
        );
      } else if (entityType === PermissionDocumentType.GROUP) {
        return editGroupAccessApi(
          entityId,
          viewAccessList,
          editAccessList,
          editGuideOptions.entityVisibility,
          group.version,
        );
      } else if (entityType === PermissionDocumentType.CHAPTER) {
        return editChapterAccessApi(
          activeGuideId,
          entityId,
          viewAccessList,
          editAccessList,
          editGuideOptions.entityVisibility,
          activeGuide.version,
        );
      } else if (entityType === PermissionDocumentType.PAGE) {
        return editPageAccessApi(
          activeGuideId,
          chapterId,
          entityId,
          viewAccessList,
          editAccessList,
          editGuideOptions.entityVisibility,
          activeGuide.version,
        );
      }
    },
    {
      onSuccess(data, variables: EditGuideOptions) {
        let trackingMessage: {
          message: string;
          type: EntityVisibility;
        } | null = null;
        switch (entityType) {
          case PermissionDocumentType.GUIDE:
            updateGuideVersion(entityId);
            trackingMessage = {
              message: 'Guide Access changed',
              type: variables.entityVisibility,
            };

            break;

          case PermissionDocumentType.GROUP:
            trackingMessage = {
              message: 'Group Access changed',
              type: variables.entityVisibility,
            };

            break;
          case PermissionDocumentType.PAGE:
            updateGuideVersion(activeGuideId);
            trackingMessage = {
              message: 'Page Access changed',
              type: variables.entityVisibility,
            };

            break;
          case PermissionDocumentType.CHAPTER:
            updateGuideVersion(activeGuideId);
            trackingMessage = {
              message: 'Chapter Access changed',
              type: variables.entityVisibility,
            };
        }

        handleMutationSuccess(trackingMessage, data, {
          entityId: activeGuideId ? activeGuideId : entityId,
          message: translate('OVERVIEW_CARD_MUTATION_MESSAGE.UPDATED_ACCESS'),
          queryPath: PERMISSIONS_KEYS.ENTITY_PERMISSIONS,
        });

        closeModal();
      },
    },
  );
};
