import React from 'react';
import { ModalBase } from '@bynder/design-system';
import { useTranslate } from '@bynder/localization';
import PrimaryButton from '../shared/PrimaryButton';

const MaxGuideSizeModal: React.FC<{
  onCancel: () => void;
}> = ({ onCancel }) => {
  const { translate } = useTranslate();

  return (
    <ModalBase isOpen onClose={onCancel}>
      <ModalBase.Header title={translate('MAX_SIZE_MODAL.HEADER_TEXT')} />
      <ModalBase.Content>
        {translate('MAX_SIZE_MODAL.CONTENT_TEXT')}
      </ModalBase.Content>
      <ModalBase.Footer
        actionPrimary={
          <PrimaryButton onClick={onCancel}>
            {translate('MAX_SIZE_MODAL.ACTION_TEXT')}
          </PrimaryButton>
        }
      />
    </ModalBase>
  );
};

export default MaxGuideSizeModal;
