import ReactDOM from 'react-dom/client';
import App from './App.tsx';
import { Analytics } from './types/index.ts';
import { isDevelopment } from './constants/app/index.ts';
import { initNotificationsDeps } from './helpers/notificationDeps.ts';
import { initSentry } from './helpers/sentry.ts';

if (isDevelopment) {
  const { worker } = await import('./mocks/browser');
  await worker.start({ onUnhandledRequest: 'bypass' });
} else {
  initNotificationsDeps();
}

initSentry();
// Declare global window variables that are used within the application.
declare global {
  interface Window {
    bynder: Analytics;
    Bynder: {
      csrf_token?: string;
      language?: string;
    };
    Appcues: {
      page: () => void;
    };
  }
  interface File {
    id: string;
  }
}

ReactDOM.createRoot(document.getElementById('root')!).render(<App />);
