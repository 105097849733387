import { CreateModalProps } from '../components/modals/CreateModal';
import { CreateGroupModalProps } from '../components/modals/CreateGroupModal';
import { DeleteModalProps } from '../components/modals/DeleteModal';
import { CompactViewModalProps } from '../components/modals/CompactViewModal';
import { TableOfContentsModalProps } from '../components/modals/TableOfContentsModal';
import { WarningModalProps } from '../components/modals/WarningModal';
import { EditThemesetDetailsModalProps } from '../components/modals/themesets/EditThemesetDetailsModal';
import { CreateThemesetModalProps } from '../components/modals/themesets/CreateThemesetModal';
import { AccessModalProps } from '../components/modals/AccessModal';
import { ImageWidgetCustomUrlModalProps } from '../components/modals/ImageWidgetCustomUrlModal';
import { MovePageModalProps } from '../components/modals/MovePageModal';

export enum ModalType {
  CREATE_MODAL = 'CREATE_MODAL',
  NEW_CREATE_MODAL = 'NEW_CREATE_MODAL',
  CREATE_GROUP_MODAL = 'CREATE_GROUP_MODAL',
  DELETE_MODAL = 'DELETE_MODAL',
  COMPACT_VIEW_MODAL = 'COMPACT_VIEW_MODAL',
  TABLE_OF_CONTENTS_MODAL = 'TABLE_OF_CONTENTS_MODAL',
  COLLECTIONS_MODAL = 'COLLECTIONS_MODAL',
  WARNING_MODAL = 'WARNING_MODAL',
  EDIT_THEMESET_DETAILS_MODAL = 'EDIT_THEMESET_DETAILS_MODAL',
  CREATE_THEMESET_MODAL = 'CREATE_THEMESET_MODAL',
  ACCESS_MODAL = 'ACCESS_MODAL',
  IMAGE_WIDGET_CUSTOM_URL = 'IMAGE_WIDGET_CUSTOM_URL',
  MOVE_PAGE_MODAL = 'MOVE_PAGE_MODAL',
  MAX_GUIDE_SIZE_MODAL = 'MAX_GUIDE_SIZE_MODAL',
  SECTION_TEMPLATE_MODAL = 'SECTION_TEMPLATE_MODAL',
  PAGE_TEMPLATE_MODAL = 'PAGE_TEMPLATE_MODAL',
  HOMEPAGE_MODAL = 'HOMEPAGE_MODAL',
}

export interface ModalsProps {
  CREATE_MODAL: CreateModalProps;
  NEW_CREATE_MODAL: CreateModalProps;
  CREATE_GROUP_MODAL: CreateGroupModalProps;
  DELETE_MODAL: DeleteModalProps;
  COMPACT_VIEW_MODAL: CompactViewModalProps;
  TABLE_OF_CONTENTS_MODAL: TableOfContentsModalProps;
  COLLECTIONS_MODAL: { collectionsApiUrl: string };
  WARNING_MODAL: WarningModalProps;
  EDIT_THEMESET_DETAILS_MODAL: EditThemesetDetailsModalProps;
  CREATE_THEMESET_MODAL: CreateThemesetModalProps;
  ACCESS_MODAL: AccessModalProps;
  IMAGE_WIDGET_CUSTOM_URL: ImageWidgetCustomUrlModalProps;
  MOVE_PAGE_MODAL: MovePageModalProps;
  MAX_GUIDE_SIZE_MODAL: 'ModalProps';
}
