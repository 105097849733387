import { Box, Button, InlineBanner, ModalBase } from '@bynder/design-system';
import PrimaryButton from '../shared/PrimaryButton';
import { useFetchGuideById } from '../../queries/guides';
import { Translate, useTranslate } from '@bynder/localization';
import { useHomepageMutations } from '../../mutations/homepage';

interface HomepageModalProps {
  onCancel: () => void;
  guideId: string;
}

const HomepageModal = ({ onCancel, guideId }: HomepageModalProps) => {
  const { translate } = useTranslate();
  const { setHomepage } = useHomepageMutations();
  const { data: guide } = useFetchGuideById(guideId);

  const handleSetHomepage = () => {
    setHomepage.mutate({ guideId, guideVersion: guide?.version });
  };

  return (
    <ModalBase isClosedOnOverlayClick isOpen onClose={onCancel}>
      <ModalBase.Header title={translate('HOMEPAGE_MODAL.HEADER_TEXT')} />
      <ModalBase.Content>
        <Translate id="HOMEPAGE_MODAL.CONTENT_TEXT" />
        <Box marginTop="5">
          <InlineBanner variant="info">
            <Translate id="HOMEPAGE_MODAL.INFO_TEXT" />
          </InlineBanner>
        </Box>
      </ModalBase.Content>
      <ModalBase.Footer
        actionPrimary={
          <PrimaryButton data-testid="submitButton" onClick={handleSetHomepage}>
            <Translate id="HOMEPAGE_MODAL.BUTTON_TEXT" />
          </PrimaryButton>
        }
        actionSecondary={
          <Button
            variant="secondary"
            onClick={onCancel}
            data-testid="cancelButton"
          >
            <Translate id="HOMEPAGE_MODAL.CANCEL_TEXT" />
          </Button>
        }
      />
    </ModalBase>
  );
};

export default HomepageModal;
