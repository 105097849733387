import { Cover, EntityVisibility, Permission, Section } from '.';
import { PageHeaderHeights } from './headerHeights';
import { TableOfContents } from './tableOfContents';

export enum PageStates {
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED',
}

export interface Page {
  id: string;
  title: string;
  creationTime?: string;
  lastModifiedTime?: string;
  permissions: Permission[];
  visibility: EntityVisibility;
  sections: Section[];
  isFresh: boolean;
  state: PageStates;
  widgetCount: number;
  canEdit: boolean;
  ownerId?: string;
  canView: boolean;
  header?: PageHeaderOptions;
  tableOfContents: TableOfContents;
}

export interface PageHeaderOptions {
  asset: Cover;
  height: PageHeaderHeights;
  backgroundColor: string;
  textColorId: string;
  isTitleVisible: boolean;
  isSubtitleVisible: boolean;
  isVisible: boolean;
}
