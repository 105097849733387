import React from 'react';
import {
  Button,
  Textarea,
  Form,
  Flex,
  Input,
  ModalBase,
} from '@bynder/design-system';
import { Translate } from '@bynder/localization';
import PrimaryButton from '../shared/PrimaryButton';
import MaxCharacterCounter from '../shared/MaxCharacterCounter';

export interface CreateModalProps {
  onCancel: () => void;
  onSubmit: (title: string) => void;
  headerText?: string;
  labelText?: string;
  maxLength?: number;
  placeholderText?: string;
  initialValue?: string;
  buttonLabel?: string;
  isTextarea?: boolean;
}

const CreateModal: React.FC<CreateModalProps> = ({
  onCancel,
  onSubmit,
  maxLength,
  headerText,
  labelText,
  placeholderText,
  buttonLabel,
  initialValue = '',
  isTextarea,
}) => {
  const [title, setTitle] = React.useState(initialValue);
  const inputRef = React.useRef<HTMLInputElement | null>(null);

  React.useEffect(() => {
    (document.activeElement as HTMLElement).blur();

    if (inputRef?.current) {
      inputRef?.current?.focus();
    }
  }, []);

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && title) {
      onSubmit(title);
    }
  };

  return (
    <ModalBase isClosedOnOverlayClick isOpen onClose={onCancel}>
      <ModalBase.Header title={headerText} />
      <ModalBase.Content>
        <Form.Group>
          {isTextarea ? (
            <>
              <Form.Label htmlFor="modalTextArea">{labelText}</Form.Label>
              <Textarea
                placeholder={placeholderText}
                onChange={value => setTitle(value)}
                value={title}
                id="modalTextArea"
                maxLength={maxLength}
                onFocus={e =>
                  e.currentTarget.setSelectionRange(
                    title?.length,
                    title?.length,
                  )
                }
              />
              <Flex justifyContent="flex-end">
                <Form.HelperText id="modalTextArea">
                  <MaxCharacterCounter value={title} maxValue={maxLength} />
                </Form.HelperText>
              </Flex>
            </>
          ) : (
            <>
              <Form.Label htmlFor="modalInput">{labelText}</Form.Label>
              <Input
                onKeyDown={handleKeyPress}
                inputRef={inputRef}
                id="modalInput"
                value={title}
                onChange={setTitle}
                data-testid="modalInput"
                placeholder={placeholderText}
                maxLength={maxLength}
                iconRight={
                  <MaxCharacterCounter value={title} maxValue={maxLength} />
                }
              />
            </>
          )}
        </Form.Group>
      </ModalBase.Content>
      <ModalBase.Footer
        actionPrimary={
          <PrimaryButton
            data-testid="submitButton"
            isDisabled={title === ''}
            onClick={() => onSubmit(title)}
          >
            {buttonLabel || (
              <Translate id="CREATE_MODAL.DEFAULT_BUTTON_LABEL" />
            )}
          </PrimaryButton>
        }
        actionSecondary={
          <Button variant="secondary" onClick={onCancel}>
            <Translate id="CREATE_MODAL.CANCEL_BUTTON_LABEL" />
          </Button>
        }
      />
    </ModalBase>
  );
};

export default CreateModal;
