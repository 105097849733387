import React, { forwardRef } from 'react';
import { ExtendedButton } from './ExtendedButton';

interface Props {
  children?: React.ReactNode;
  /** Makes button 100% width of a container */
  isFullWidth?: boolean;
  /** Enforce pressed state. E. g. in dropdowns. */
  isPressed?: boolean;
  isDisabled?: boolean;
  isSmall?: boolean;
  /** Icon that is displayed on the left, or center when the button only contains an icon. */
  icon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  /** Shows button in a loading state */
  isLoading?: boolean;
  role?: 'combobox';
}

type ButtonProps = React.ComponentPropsWithoutRef<'button'> &
  React.ComponentPropsWithoutRef<'a'> &
  Props;

const PrimaryButton = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ children, ...props }, ref) => {
    return (
      <ExtendedButton ref={ref} variant="primary" {...props}>
        {children}
      </ExtendedButton>
    );
  },
);

export default PrimaryButton;
