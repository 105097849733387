import React from 'react';
import styled from 'styled-components';
import {
  Form,
  Input,
  Thumbnail,
  token,
  Button,
  Textarea,
  Flex,
  ModalBase,
} from '@bynder/design-system';
import { Translate, useTranslate } from '@bynder/localization';
import {
  THEMESET_DESCRIPTION_MAX_LENGTH,
  THEMESET_TITLE_MAX_LENGTH,
} from '../../../constants/themesets';
import { isValidHexCode, repairHexCodeInput } from '../../../helpers/colors';
import { ThemesetDetails, Themeset } from '../../../types';
import PrimaryButton from '../../shared/PrimaryButton';
import MaxCharacterCounter from '../../shared/MaxCharacterCounter';

interface Props {
  themeset?: Themeset;
  onCancel: () => void;
  onSubmit: (themesetDetails: ThemesetDetails) => void;
  primaryButtonCopy: string;
}

const ThemesetDetailsModal: React.FC<Props> = ({
  themeset,
  onCancel,
  onSubmit,
  primaryButtonCopy,
}) => {
  const { translate } = useTranslate();
  const [title, setTitle] = React.useState(themeset?.label || '');
  const [description, setDescription] = React.useState(
    themeset?.description || '',
  );
  const [hexColor, setHexColor] = React.useState(
    themeset?.cover.backgroundColor || '',
  );
  const [isButtonDisabled, setIsButtonDisabled] = React.useState(true);

  React.useEffect(() => {
    if (
      title.length > THEMESET_TITLE_MAX_LENGTH ||
      title === '' ||
      (hexColor.length && !isValidHexCode(hexColor)) ||
      (title === themeset?.label &&
        description === themeset?.description &&
        hexColor === themeset?.cover?.backgroundColor)
    ) {
      setIsButtonDisabled(true);
    } else {
      setIsButtonDisabled(false);
    }
  }, [
    title,
    description,
    themeset?.label,
    themeset?.description,
    hexColor,
    themeset?.cover.backgroundColor,
    setIsButtonDisabled,
  ]);

  const bgColor = isValidHexCode(hexColor) ? hexColor : null;

  const handleSubmit = () => {
    onSubmit({
      label: title,
      description,
      backgroundColor: hexColor,
      backgroundImageUrl: '',
    });
  };

  return (
    <ModalBase isClosedOnOverlayClick isOpen onClose={onCancel}>
      <ModalBase.Header
        title={translate('EDIT_THEMESET_DETAILS_MODAL_CONTENT.HEADER_TEXT')}
      />
      <ModalBase.Content>
        <Form>
          <Form.Group>
            <Form.Label id="themesetTitle">
              <Translate id="EDIT_THEMESET_DETAILS_MODAL_CONTENT.TITLE_LABEL" />
            </Form.Label>
            <DSInput
              value={title}
              onChange={setTitle}
              hasClearButton
              placeholder={translate(
                'EDIT_THEMESET_DETAILS_MODAL_CONTENT.TITLE_PLACEHOLDER',
              )}
              aria-label="themeset-title"
              isInvalid={title.slice().length > THEMESET_TITLE_MAX_LENGTH}
              data-testid="themesetTitle"
            />
          </Form.Group>
          <Form.Group>
            <Form.Label
              id="themesetDescription"
              secondaryText={translate(
                'EDIT_THEMESET_DETAILS_MODAL_CONTENT.LABEL_OPTIONAL',
              )}
            >
              <Translate id="EDIT_THEMESET_DETAILS_MODAL_CONTENT.DESCRIPTION_LABEL" />
            </Form.Label>
            <Textarea
              value={description}
              onChange={e => setDescription(e)}
              id="themesetDescription"
              placeholder={translate(
                'EDIT_THEMESET_DETAILS_MODAL_CONTENT.DESCRIPTION_PLACEHOLDER',
              )}
              maxLength={THEMESET_DESCRIPTION_MAX_LENGTH}
              isInvalid={
                description.slice().length > THEMESET_DESCRIPTION_MAX_LENGTH
              }
            />
            <Flex justifyContent="flex-end">
              <Form.HelperText id="themesetDescription">
                <MaxCharacterCounter
                  value={description}
                  maxValue={THEMESET_DESCRIPTION_MAX_LENGTH}
                />
              </Form.HelperText>
            </Flex>
          </Form.Group>
          <Form.Group>
            <Form.Label
              secondaryText={translate(
                'EDIT_THEMESET_DETAILS_MODAL_CONTENT.LABEL_OPTIONAL',
              )}
            >
              <Translate id="EDIT_THEMESET_DETAILS_MODAL_CONTENT.COLOR_LABEL" />
            </Form.Label>
            <InputWithColorContainer>
              <ColorPreview>
                <Thumbnail variant="content" backgroundColor={bgColor} />
              </ColorPreview>
              <DSInput
                id="themesetHexCodeInput"
                value={hexColor}
                placeholder={translate(
                  'EDIT_THEMESET_DETAILS_MODAL_CONTENT.COLOR_PLACEHOLDER',
                )}
                onChange={color => {
                  try {
                    if (color === '' || isValidHexCode(color)) {
                      setHexColor(color);
                    } else {
                      setHexColor(repairHexCodeInput(color));
                    }
                  } catch (error) {
                    // Ignore input if hex code is too big
                  }
                }}
              />
            </InputWithColorContainer>
          </Form.Group>
        </Form>
      </ModalBase.Content>
      <ModalBase.Footer
        actionPrimary={
          <PrimaryButton
            data-testid="submitButton"
            disabled={isButtonDisabled}
            onClick={handleSubmit}
          >
            {primaryButtonCopy}
          </PrimaryButton>
        }
        actionSecondary={
          <Button type="button" variant="secondary" onClick={onCancel}>
            <Translate id="EDIT_THEMESET_DETAILS_MODAL_CONTENT.CANCEL_BUTTON" />
          </Button>
        }
      />
    </ModalBase>
  );
};

export default ThemesetDetailsModal;

const InputWithColorContainer = styled.div`
  display: flex;
  width: 200px;
  background-color: ${token.white};
  position: relative;
`;

const ColorPreview = styled.span`
  height: 40px;
  width: 40px;
  min-width: 40px;
  margin-right: ${token.spacing4};
`;

// Custom Theming overrides
const DSInput = styled(Input)`
  input {
    font-size: ${token.fontSize100} !important;
    background: transparent !important;
    border: 0 !important;
  }
`;
