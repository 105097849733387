import React from 'react';
import { isEqual } from 'lodash';
import { IconLink } from '@bynder/icons';
import { Button, Flex, Form, ModalBase } from '@bynder/design-system';
import { Translate } from '@bynder/localization';
import { AccessModalDropdown } from './AccessModalDropdown';
import { EntityVisibility, Identity } from '../../types';
import { AccessModalIdentityList } from './AccessModalIdentityList';
import PrimaryButton from '../shared/PrimaryButton';
import { AccessModalSearch } from './AccessModalSearch';
import { useModuleTier } from '../../helpers/hooks';
import { useEntityPermission } from '../../queries/permissions';
import { useEditAccess } from '../../mutations/permissions';
import { AccessModalContext } from '../../context/accessmodal';
import { useModalStore } from '../../stores/modalStore';

export interface AccessModalProps {
  onCancel: () => void;
  entityId: string;
  headerText: string;
  onCopyUrl?: () => void;
  activeGuideId?: string;
}

export const AccessModal: React.FC<AccessModalProps> = ({
  onCancel,
  entityId,
  headerText,
  onCopyUrl,
  activeGuideId,
}) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const closeModal = useModalStore.use.closeModal();
  const value = React.useMemo(
    () => ({
      ...state,
      entityId,
      changeVisibility: (newEntityVisibility: EntityVisibility) => {
        dispatch({
          type: AccessModalReducerActions.CHANGE_ENTITY_VISIBILITY,
          payload: newEntityVisibility,
        });
      },
      editIdentity: (identity: Identity[]) => {
        dispatch({
          type: AccessModalReducerActions.EDIT_IDENTITY_LIST,
          payload: identity,
        });
      },
    }),
    [state],
  );

  const { isBasic } = useModuleTier();
  const {
    data: entityPermissions,
    isLoading,
    isError,
  } = useEntityPermission(entityId);

  const { documentVisibility, permissions, documentType } =
    entityPermissions || {};
  const [hasChanges, setHasChanges] = React.useState(false);

  React.useEffect(() => {
    if (!isLoading) {
      value.changeVisibility(documentVisibility);
      value.editIdentity(permissions);
    }
  }, [documentVisibility, permissions, isLoading]);

  React.useEffect(() => {
    if (
      documentVisibility !== value.entityVisibility ||
      !isEqual(value.identityList, permissions)
    ) {
      setHasChanges(true);
    } else {
      setHasChanges(false);
    }
  }, [
    documentVisibility,
    value.entityVisibility,
    value.identityList,
    permissions,
  ]);

  const editAccess = useEditAccess(entityId, activeGuideId, documentType);

  const onHandleClick = () => {
    editAccess.mutate({
      entityVisibility: value.entityVisibility,
      identityList: value.identityList,
    });
  };

  const handleCopyLink = () => {
    onCopyUrl();
  };

  if (isError) {
    closeModal();
  }

  return (
    <AccessModalContext.Provider value={value}>
      <ModalBase isClosedOnOverlayClick isOpen onClose={onCancel}>
        <ModalBase.Header title={headerText} />
        <ModalBase.Content>
          <Flex direction="column" gap="3">
            <Form.Row>
              <AccessModalDropdown activeGuideId={activeGuideId} />
            </Form.Row>
            {!isBasic && (
              <Form.Row>
                <AccessModalSearch />
              </Form.Row>
            )}
            <Form.Row>
              <AccessModalIdentityList />
            </Form.Row>
          </Flex>
        </ModalBase.Content>
        <ModalBase.Footer
          actionPrimary={
            <PrimaryButton
              isLoading={editAccess.isLoading}
              onClick={onHandleClick}
              isDisabled={!hasChanges}
            >
              {editAccess.isLoading ? (
                <Translate id="ACCESS_MODAL.MODAL_ACTIONS_SAVING_BUTTON_TEXT" />
              ) : (
                <Translate id="ACCESS_MODAL.MODAL_ACTIONS_SAVE_BUTTON_TEXT" />
              )}
            </PrimaryButton>
          }
          actionSecondary={
            <Button variant="secondary" onClick={onCancel}>
              <Translate id="ACCESS_MODAL.MODAL_ACTIONS_CANCEL_BUTTON_TEXT" />
            </Button>
          }
          actionTertiary={
            onCopyUrl && (
              <Button
                icon={<IconLink />}
                variant="secondary"
                onClick={handleCopyLink}
              >
                <Translate id="SHARE_MODAL.BUTTON_TEXT" />
              </Button>
            )
          }
        />
      </ModalBase>
    </AccessModalContext.Provider>
  );
};

const initialState = {
  entityId: '',
  entityVisibility: null as EntityVisibility,
  identityList: [] as Identity[],
};

enum AccessModalReducerActions {
  CHANGE_ENTITY_VISIBILITY = 'CHANGE_ENTITY_VISIBILITY',
  EDIT_IDENTITY_LIST = 'EDIT_IDENTITY_LIST',
}

// An interface for our actions
interface ChangeVisibilityAction {
  type: AccessModalReducerActions.CHANGE_ENTITY_VISIBILITY;
  payload: EntityVisibility;
}
interface ChangeIdentityListAction {
  type: AccessModalReducerActions.EDIT_IDENTITY_LIST;
  payload: Identity[];
}

// Reducer to Handle Actions
const reducer = (
  state = initialState,
  action: ChangeVisibilityAction | ChangeIdentityListAction,
) => {
  switch (action.type) {
    case AccessModalReducerActions.CHANGE_ENTITY_VISIBILITY:
      return { ...state, entityVisibility: action.payload };
    case AccessModalReducerActions.EDIT_IDENTITY_LIST:
      return { ...state, identityList: action.payload };
    default:
      return state;
  }
};
