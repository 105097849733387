import React from 'react';
import {
  IconUserGroup,
  IconLock,
  IconPublic,
  IconBuilding,
} from '@bynder/icons';
import { Dropdown, List, Thumbnail } from '@bynder/design-system';
import { Translate } from '@bynder/localization';
import { EntityVisibility, PermissionDocumentType } from '../../types';
import SkeletonElement from '../shared/styled-components/SkeletonElement';
import { useEntityPermission } from '../../queries/permissions';
import { AccessModalContext } from '../../context/accessmodal';

export const AccessModalDropdown: React.FC<{ activeGuideId: string }> = ({
  activeGuideId,
}) => {
  const { entityVisibility, entityId, changeVisibility, identityList } =
    React.useContext(AccessModalContext);

  const {
    data: entityPermissions,
    isLoading,
    isError,
  } = useEntityPermission(entityId);
  const { data: activeGuidePermissions } = useEntityPermission(activeGuideId);
  const isSpecificUsers = entityVisibility === EntityVisibility.SPECIFIC;
  const isAllPortalUsers = entityVisibility === EntityVisibility.PORTAL;
  const isPublic = entityVisibility === EntityVisibility.PUBLIC;
  const isPrivate =
    identityList?.length === 0 &&
    entityVisibility === EntityVisibility.SPECIFIC;
  const [isPublicLimited, setIsPublicLimited] = React.useState(false);
  const [isPortalLimited, setIsPortalLimited] = React.useState(false);
  const { documentVisibility: activeGuideVisibility } =
    activeGuidePermissions || {};

  React.useEffect(() => {
    if (
      entityPermissions?.documentType === PermissionDocumentType.CHAPTER ||
      entityPermissions?.documentType === PermissionDocumentType.PAGE
    ) {
      if (activeGuideVisibility === EntityVisibility.SPECIFIC) {
        setIsPublicLimited(true);
        setIsPortalLimited(true);
      }

      if (activeGuideVisibility === EntityVisibility.PORTAL) {
        setIsPublicLimited(true);
      }
    }
  }, [activeGuideVisibility, entityPermissions?.documentType]);

  if (isError || isLoading) {
    return <SkeletonDropdown />;
  }

  return (
    <List>
      <Dropdown
        trigger={({ isOpen, ...triggerProps }) => (
          <List.Item
            thumbnail={
              <Thumbnail
                variant="clean"
                icon={getIcon(entityVisibility, isPrivate)}
                shape="square"
                size="m"
              />
            }
            subtext={getVisibilitySubtext(
              entityVisibility,
              entityPermissions?.documentType,
              isPrivate,
            )}
            isSelected={isOpen}
            rightElements={<Dropdown.Arrow />}
            {...triggerProps}
          >
            {getVisibilityText(entityVisibility)}
          </List.Item>
        )}
        position="bottom"
      >
        <Dropdown.ListItem
          onClick={() => changeVisibility(EntityVisibility.SPECIFIC)}
          isChecked={isSpecificUsers}
          role="menuitemradio"
          subtext={
            identityList?.length === 0 ? (
              <Translate id="ACCESS_MODAL_DROPDOWN.PRIVATE_STATE_DESCRIPTION_TEXT" />
            ) : (
              <Translate
                id="ACCESS_MODAL_DROPDOWN.SPECIFIC_STATE_DESCRIPTION_TEXT"
                document={entityPermissions?.documentType.toLowerCase()}
              />
            )
          }
          thumbnail={
            <Thumbnail
              variant="clean"
              icon={
                identityList.length === 0 ? <IconLock /> : <IconUserGroup />
              }
              shape="square"
              size="m"
            />
          }
        >
          <Translate id="ACCESS_MODAL_DROPDOWN.RESTRICTED_OPTION" />
        </Dropdown.ListItem>

        <Dropdown.ListItem
          isDisabled={isPortalLimited}
          onClick={() => changeVisibility(EntityVisibility.PORTAL)}
          isChecked={isAllPortalUsers}
          role="menuitemradio"
          subtext={
            isPortalLimited ? (
              <Translate id="ACCESS_MODAL_DROPDOWN.PORTAL_STATE_LIMITED_DESCRIPTION_TEXT" />
            ) : (
              <Translate id="ACCESS_MODAL_DROPDOWN.PORTAL_STATE_DESCRIPTION_TEXT" />
            )
          }
          thumbnail={
            <Thumbnail
              variant="clean"
              icon={<IconBuilding />}
              shape="square"
              size="m"
            />
          }
        >
          <Translate id="ACCESS_MODAL_DROPDOWN.PORTAL_USERS_OPTION" />
        </Dropdown.ListItem>

        {entityPermissions?.documentType !== PermissionDocumentType.GROUP && (
          <Dropdown.ListItem
            isDisabled={isPublicLimited}
            onClick={() => changeVisibility(EntityVisibility.PUBLIC)}
            isChecked={isPublic}
            role="menuitemradio"
            subtext={
              isPublicLimited ? (
                <Translate id="ACCESS_MODAL_DROPDOWN.PUBLIC_STATE_LIMITED_DESCRIPTION_TEXT" />
              ) : (
                <Translate id="ACCESS_MODAL_DROPDOWN.PUBLIC_STATE_DESCRIPTION_TEXT" />
              )
            }
            thumbnail={
              <Thumbnail
                variant="clean"
                icon={<IconPublic />}
                shape="square"
                size="m"
              />
            }
          >
            <Translate id="ACCESS_MODAL_DROPDOWN.PUBLIC_OPTION" />
          </Dropdown.ListItem>
        )}
      </Dropdown>
    </List>
  );
};

const getIcon = (entityVisibility: EntityVisibility, isPrivate: boolean) => {
  if (isPrivate) {
    return <IconLock />;
  }

  switch (entityVisibility) {
    case EntityVisibility.PORTAL:
      return <IconBuilding />;
    case EntityVisibility.PUBLIC:
      return <IconPublic />;
    case EntityVisibility.SPECIFIC:
    default:
      return <IconUserGroup />;
  }
};

const getVisibilityText = (entityVisibility: EntityVisibility) => {
  switch (entityVisibility) {
    case EntityVisibility.PORTAL:
      return <Translate id="ACCESS_MODAL_DROPDOWN.PORTAL_USERS_OPTION" />;
    case EntityVisibility.PUBLIC:
      return <Translate id="ACCESS_MODAL_DROPDOWN.PUBLIC_OPTION" />;
    case EntityVisibility.SPECIFIC:
    default:
      return <Translate id="ACCESS_MODAL_DROPDOWN.RESTRICTED_OPTION" />;
  }
};

const getVisibilitySubtext = (
  entityVisibility: EntityVisibility,
  documentType: PermissionDocumentType,
  isPrivate: boolean,
) => {
  if (isPrivate) {
    return (
      <Translate
        id="ACCESS_MODAL_DROPDOWN.PRIVATE_STATE_DESCRIPTION_TEXT"
        document={documentType?.toLowerCase()}
      />
    );
  }

  switch (entityVisibility) {
    case EntityVisibility.PORTAL:
      return (
        <Translate id="ACCESS_MODAL_DROPDOWN.PORTAL_STATE_DESCRIPTION_TEXT" />
      );
    case EntityVisibility.PUBLIC:
      return (
        <Translate id="ACCESS_MODAL_DROPDOWN.PUBLIC_STATE_DESCRIPTION_TEXT" />
      );
    case EntityVisibility.SPECIFIC:
    default:
      return (
        <Translate
          id="ACCESS_MODAL_DROPDOWN.SPECIFIC_STATE_DESCRIPTION_TEXT"
          document={documentType?.toLowerCase()}
        />
      );
  }
};

const SkeletonDropdown = () => (
  <List.Item
    thumbnail={<SkeletonElement width={40} height={40} />}
    subtext={<SkeletonElement width={200} height={12} />}
    isSelected={false}
    rightElements={<Dropdown.Arrow />}
    isDisabled
    data-testid="SkeletonDropdown"
  >
    <SkeletonElement width={100} />
  </List.Item>
);
