import { createGlobalStyle } from 'styled-components';
import { useGetFonts } from '../queries/themesets';
import { Font, fontFaceBuilder } from '../constants/themesets';

const GlobalFonts = createGlobalStyle<{ fonts: Font[] }>`${({ theme, fonts }) =>
  fontFaceBuilder(theme, fonts)}`;

const ThemeFontsProvider: React.FC = () => {
  const { data: availableFonts } = useGetFonts();

  return <GlobalFonts fonts={availableFonts} />;
};

export default ThemeFontsProvider;
