import Color from 'color';

const regexHex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;
const regexRGB =
  /^((0|255|25[0-4]|2[0-4]\d|1\d\d|0?\d?\d),\s*){2}(0|255|25[0-4]|2[0-4]\d|1\d\d|0?\d?\d)$/;
const regexCMYK = /^((1?\d?\d),\s*){3}(1?\d?\d)$/;

const HEX_CODE_LENGTH = 6;
export const RGB_CODE_LENGTH = 13;
export const CMYK_CODE_LENGTH = 18;

export const isValidHexCode = (hex: string) => regexHex.test(hex);

export const isValidRGBCode = (rgb: string) => regexRGB.test(rgb);

export const isValidCMYKCode = (cmyk: string) => regexCMYK.test(cmyk);

export const stringToNumberArray = (s: string, limit?: number) =>
  s.split(',', limit).map(v => parseInt(v, 10));

const joiner = ', ';

export default class ColorClass {
  static fromRGB = (rgb: string) => {
    if (isValidRGBCode(rgb)) {
      return new ColorClass(Color.rgb(stringToNumberArray(rgb, 3)));
    }
  };

  static fromCMYK = (cmyk: string) => {
    if (isValidCMYKCode(cmyk)) {
      return new ColorClass(Color.cmyk(stringToNumberArray(cmyk, 4)));
    }
  };

  private color: Color;

  constructor(color: string | Color) {
    if (typeof color === 'string') {
      if (isValidHexCode(color)) {
        this.color = new Color(color);
      } else {
        throw new Error('invalid color');
      }
    } else {
      this.color = color;
    }
  }

  toHex = () => this.color.hex();

  toRGB = () => this.color.rgb().round().array().join(joiner);

  toCMYK = () => this.color.cmyk().round().array().join(joiner);
}

export const repairHexCodeInput = (possibleHexCode: string) => {
  const hexCode = possibleHexCode.replace('#', '');

  if (hexCode.length > HEX_CODE_LENGTH) {
    throw new Error('Hex code input too long');
  }

  return `#${hexCode.toUpperCase()}`;
};
