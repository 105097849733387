import { useMutation } from '@tanstack/react-query';
import { useTranslate } from '@bynder/localization';
import {
  addSectionTemplate,
  createGuideWithTemplateApi,
} from '../helpers/api/guidelinesApi';
import { generateUUID } from '../helpers/utils';
import { OVERVIEW_KEYS } from '../queries/guides';
import { useModalStore } from '../stores/modalStore';
import { handleMutationSuccess } from './helpers';
import { GuideType } from '../types';
import { GuideTemplateBuilder } from '../constants/templates/guideTemplateBuilder';
import { TemplatesEnum } from '../constants/templates/constants';
import { useOverviewStore } from '../stores/overviewStore';

export const useLandingPageMutations = () => {
  const toggleIsCreatingLandingPage =
    useModalStore.use.toggleIsCreatingLandingPage();
  const activeGroupId = useOverviewStore.use.activeGroupId();
  const { translate } = useTranslate();
  const newGuideId = generateUUID();
  const newGuideVersion = 0;
  const newPageId = generateUUID();
  const closeModal = useModalStore.use.closeModal();

  return {
    createLandingPage: useMutation(
      (createGuideOptions: { guideTitle: string }) =>
        createGuideWithTemplateApi(
          newGuideId,
          createGuideOptions.guideTitle,
          generateUUID(),
          translate('GUIDE.UNTITLED_CHAPTER'),
          generateUUID(),
          translate('GUIDE.UNTITLED_PAGE'),
          generateUUID(),
          newGuideVersion,
          GuideType.SINGLE_PAGE,
        ),
      {
        onMutate: () => {
          toggleIsCreatingLandingPage(true);
          closeModal();
        },
        onSuccess: data => {
          handleMutationSuccess('Landing Page Created', data, {
            entityId: newGuideId,
            queryPath: OVERVIEW_KEYS.OVERVIEW_GUIDES,
          });
        },
      },
    ),
    addPageTemplate: useMutation(
      (mutationOptions: { pageTitle: string; pageTemplate: TemplatesEnum }) =>
        addSectionTemplate(
          newGuideId,
          GuideTemplateBuilder(
            newGuideId,
            generateUUID(),
            newPageId,
            generateUUID(),
            newGuideVersion,
            mutationOptions.pageTemplate,
            mutationOptions.pageTitle,
            activeGroupId,
          ),
          newGuideVersion,
        ),
      {
        onMutate: () => {
          toggleIsCreatingLandingPage(true);
          closeModal();
        },
        onSuccess: data => {
          handleMutationSuccess('Landing Page Created', data, {
            entityId: newGuideId,
            queryPath: OVERVIEW_KEYS.OVERVIEW_GUIDES,
            payload: {
              isCreatingLandingPage: true,
              newPageId,
            },
          });
        },
      },
    ),
  };
};
