import { Button } from '@bynder/design-system';
import { darken, tint } from 'polished';
import styled from 'styled-components';

export const ExtendedButton = styled(Button)`
  background: ${props => props.theme.primaryColor};

  &:hover {
    background: ${props => darken(0.1, props.theme.primaryColor)};
  }

  &:active,
  &:focus {
    background: ${props => darken(0.2, props.theme.primaryColor)};
  }

  &:disabled {
    background: ${props => tint(0.5, props.theme.primaryColor)};
  }
`;
