import styled, { createGlobalStyle } from 'styled-components';
import { token } from '@bynder/design-system';

export const NoScrollBody = createGlobalStyle`
  body {
    overflow: hidden;
  }
`;

export const StyledModalDescription = styled.span`
  font-size: ${token.fontSize75};
  line-height: 20px;
  color: ${token.gray500a};
`;

export const StyledGuideStateInfoLabel = styled.p`
  font-size: ${token.fontSize100};
`;

export const StyledGuideStateInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
