export const initNotificationsDeps = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { require: pyRequire } = global as any;

  pyRequire?.config({
    baseUrl: document.querySelector('#root-script').getAttribute('data-url'),
    paths: {
      requireSettings: 'scripts/modules/base/requireSettings',
    },
  });

  pyRequire(['requireSettings'], () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    pyRequire(['Bynder'], (Bynder: any) => {
      Bynder.init();
    });
  });
};
