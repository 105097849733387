import { AxiosResponse } from 'axios';
import axios, { handleUnauthorized } from '../axios';
import { API_ROUTES } from '../../constants/app';

export async function setHomepagePortalApi(
  guideId: string,
): Promise<AxiosResponse> {
  return (await axios())
    .put(API_ROUTES.PORTAL_HOMEPAGE, {
      homepage: `/guidelines/guide/${guideId}`,
    })
    .catch(error => handleUnauthorized(error, setHomepagePortalApi));
}

export async function unsetHomepagePortalApi(): Promise<AxiosResponse> {
  return (await axios())
    .delete(API_ROUTES.PORTAL_HOMEPAGE)
    .catch(error => handleUnauthorized(error, unsetHomepagePortalApi));
}

export async function getCurrentHomepageApi(): Promise<AxiosResponse> {
  return (await axios())
    .get(API_ROUTES.PORTAL_HOMEPAGE)
    .catch(error => handleUnauthorized(error, getCurrentHomepageApi));
}
