export const generateGraphQLCollectionId = (id: string) =>
  btoa(`(Collection_id ${id})`);

export const cfuuidToUuid = (cfuuid: string) => {
  const isValidCFUuid = (uuid: string) =>
    uuid.match(
      '^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{16}$',
    );

  if (isValidCFUuid(cfuuid)) {
    return `${cfuuid.substring(0, 23)}-${cfuuid.substr(23)}`;
  }

  throw new Error('The input has the wrong format');
};

export const isValidUUID = (uuid: string): boolean => {
  const uuidRegex =
    /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  return uuidRegex.test(uuid);
};
