import { ThemesetCategory, Themeset, BackButtonBehavior } from '../../types';
import { TypographyHeading } from '../../types/typography';
import { getFontsFromTheme } from '../../queries/themesets';
import defaultFonts from '../../themes/defaultFonts.json';
import { DefaultTheme } from 'styled-components';

export const THEMESET_DESCRIPTION_MAX_LENGTH = 2000;
export const THEMESET_TITLE_MAX_LENGTH = 255;

export const isThemesetEditable = (category: ThemesetCategory) =>
  category !== ThemesetCategory.BYNDER_THEME_SET;

export const themeSetHeadings = [
  {
    heading: TypographyHeading.H1,
    isExpanded: false,
    isEditing: false,
  },
  {
    heading: TypographyHeading.H2,
    isExpanded: false,
    isEditing: false,
  },
  {
    heading: TypographyHeading.H3,
    isExpanded: false,
    isEditing: false,
  },
  {
    heading: TypographyHeading.H4,
    isExpanded: false,
    isEditing: false,
  },
  {
    heading: TypographyHeading.H5,
    isExpanded: false,
    isEditing: false,
  },
  {
    heading: TypographyHeading.NORMAL,
    isExpanded: false,
    isEditing: false,
  },
  {
    heading: TypographyHeading.H6,
    isExpanded: false,
    isEditing: false,
  },
];

export interface Font {
  id: string;
  label: string;
  baseUrl: string;
  creationTime?: string;
  lastModifiedTime?: string;
  styles: FontStyle[];
}

export interface FontStyle {
  label: string;
  weight: string;
  files: FontFiles;
}

interface FontFiles {
  eot: string;
  svg: string;
  ttf: string;
  woff: string;
  woff2: string;
}

const fontURL = (baseUrl: string, path: string) => {
  return baseUrl[baseUrl.length - 1] === '/'
    ? `${baseUrl}${path}`
    : `${baseUrl}/${path}`;
};

const generateFontFaces = (
  fontFamily: string,
  fontStyles: FontStyle[],
  baseUrl: string,
): string => {
  return ''.concat(
    ...fontStyles.map(
      style => `@font-face {
        font-family: "${fontFamily}";
        font-weight: ${style.weight};
        ${
          style.files
            ? `
            src: url(${fontURL(baseUrl, style.files.eot)});
            src:
              url(${fontURL(baseUrl, style.files.woff2)}) format('woff2'),
              url(${fontURL(baseUrl, style.files.woff)}) format('woff'),
              url(${fontURL(baseUrl, style.files.ttf)}) format('truetype'),
              url(${fontURL(baseUrl, style.files.svg)}) format('svg');
            `
            : ''
        }
      }\n`,
    ),
  );
};

export const fontFaceBuilder = (
  theme: DefaultTheme,
  availableFonts: Font[] = defaultFonts as Font[],
): string => {
  let fontFaces = '';

  const uniqueFonts = getFontsFromTheme(theme);

  uniqueFonts.forEach(fontFamily => {
    const font: Font = availableFonts.find(
      availableFont => availableFont.label === fontFamily,
    );
    let fontFace = '';

    // Custom fonts don't get loaded immediately
    if (font && font.styles) {
      fontFace = generateFontFaces(font.label, font.styles, font.baseUrl);
    }

    fontFaces = fontFaces.concat(fontFace);
  });

  return fontFaces;
};

export const defaultBynderThemeset: Themeset = {
  id: 'local-bynder-themeset',
  label: 'Bynder theme set',
  description: 'Color, fonts & typography',
  cover: {
    backgroundColor: '#00AAFF',
    backgroundImageUrl: null,
  },
  colorSets: [
    {
      id: 'd314cf05-32b7-4aa8-86f2-995e67c73017',
      label: 'Colors',
      lastModifiedTime: '2021-08-23T09:44:28.552Z',
      creationTime: '2021-08-23T09:44:28.552Z',
      colors: [
        {
          id: '53890a9f-9f04-4d9a-b18c-ce9c06a83581',
          label: 'Bynder white',
          lastModifiedTime: '2021-08-23T09:44:28.552Z',
          hexCode: '#FFFFFF',
          creationTime: '2021-08-23T09:44:28.552Z',
        },
        {
          id: '6dd5f81d-b409-499b-b0be-e735a839cdc2',
          label: 'Bynder gray',
          lastModifiedTime: '2021-08-23T09:44:28.552Z',
          hexCode: '#002233',
          creationTime: '2021-08-23T09:44:28.552Z',
        },
        {
          id: '06b950f0-8cbc-4c20-a08b-dfb7c7f52375',
          label: 'Bynder blue',
          lastModifiedTime: '2021-08-23T09:44:28.552Z',
          hexCode: '#00AAFF',
          creationTime: '2021-08-23T09:44:28.552Z',
        },
        {
          id: '1',
          hexCode: '#FFFFFF',
          label: 'Gray 10',
        },
        {
          id: '2',
          hexCode: '#F7F8F9',
          label: 'Gray 25',
        },
        {
          id: '3',
          hexCode: '#F2F4F5',
          label: 'Gray 50',
        },
        {
          id: '4',
          hexCode: '#EBEDEF',
          label: 'Gray 75',
        },
        {
          id: '5',
          hexCode: '#E6E9EB',
          label: 'Gray 100',
        },
        {
          id: '6',
          hexCode: '#CCD3D6',
          label: 'Gray 200',
        },

        {
          id: '7',
          hexCode: '#667A85',
          label: 'Gray 600',
        },
        {
          id: '8',
          hexCode: '#334E5C',
          label: 'Gray 800',
        },
      ],
    },
  ],
  typographies: [
    {
      fontFamily: 'Source Sans Pro',
      lastModifiedTime: '2021-08-23T09:44:28.552Z',
      creationTime: '2021-08-23T09:44:28.552Z',
      heading: TypographyHeading.H1,
      fontSize: 45,
      id: 'fcd7f485-4730-40ea-9b2c-a50a6d196c6a',
      label: 'Heading XXL',
      fontStyle: 'SourceSansPro-bold',
      fontColor: {
        id: '8',
        hexCode: '#334E5C',
        label: 'Gray 800',
      },
    },
    {
      fontFamily: 'Source Sans Pro',
      lastModifiedTime: '2021-08-23T09:44:28.552Z',
      creationTime: '2021-08-23T09:44:28.552Z',
      heading: TypographyHeading.H2,
      fontSize: 36,
      id: 'b7d98299-c89e-4aa6-ad90-2f08dbcb8f39',
      label: 'Heading XL',
      fontStyle: 'SourceSansPro-bold',
      fontColor: {
        id: '8',
        hexCode: '#334E5C',
        label: 'Gray 800',
      },
    },
    {
      fontFamily: 'Source Sans Pro',
      lastModifiedTime: '2021-08-23T09:44:28.552Z',
      creationTime: '2021-08-23T09:44:28.552Z',
      heading: TypographyHeading.H3,
      fontSize: 28,
      id: 'b7d98299-c89e-4aa6-ad90-2f08dbcb8f39',
      label: 'Heading L',
      fontStyle: 'SourceSansPro-semibold',
      fontColor: {
        id: '8',
        hexCode: '#334E5C',
        label: 'Gray 800',
      },
    },
    {
      fontFamily: 'Source Sans Pro',
      lastModifiedTime: '2021-08-23T09:44:28.552Z',
      creationTime: '2021-08-23T09:44:28.552Z',
      heading: TypographyHeading.H4,
      fontSize: 22,
      id: '0bcf539d-ded0-4317-ad65-3ab096c13f18',
      label: 'Heading M',
      fontStyle: 'SourceSansPro-semibold',
      fontColor: {
        id: '8',
        hexCode: '#334E5C',
        label: 'Gray 800',
      },
    },
    {
      fontFamily: 'Source Sans Pro',
      lastModifiedTime: '2021-08-23T09:44:28.552Z',
      creationTime: '2021-08-23T09:44:28.552Z',
      heading: TypographyHeading.H5,
      fontSize: 18,
      id: 'f6ae8d12-2b95-4487-a557-24b5e8b601b5',
      label: 'Paragraph L',
      fontStyle: 'SourceSansPro-regular',
      fontColor: {
        id: '8',
        hexCode: '#334E5C',
        label: 'Gray 800',
      },
    },
    {
      fontFamily: 'Source Sans Pro',
      lastModifiedTime: '2021-08-23T09:44:28.552Z',
      creationTime: '2021-08-23T09:44:28.552Z',
      heading: TypographyHeading.H6,
      fontSize: 14,
      id: '9dc8fb2a-e64e-4158-b360-ee2f92573d28',
      label: 'Paragraph S',
      fontStyle: 'SourceSansPro-regular',
      fontColor: {
        id: '8',
        hexCode: '#334E5C',
        label: 'Gray 800',
      },
    },
    {
      fontFamily: 'Source Sans Pro',
      lastModifiedTime: '2021-08-23T09:44:28.552Z',
      creationTime: '2021-08-23T09:44:28.552Z',
      heading: TypographyHeading.NORMAL,
      fontSize: 16,
      id: '11c79261-3db5-47e6-8966-0cdd6cdbf967',
      label: 'Paragraph M',
      fontStyle: 'SourceSansPro-regular',
      fontColor: {
        id: '8',
        hexCode: '#334E5C',
        label: 'Gray 800',
      },
    },
  ],
  navigationBar: {
    borderColor: {
      id: '0d332404-2b72-4258-881a-5a94756a9aab',
      lastModifiedTime: '2021-08-23T09:44:28.552Z',
      hexCode: '#E6E9EB',
      label: 'Gray 100',
      creationTime: '2021-08-23T09:44:28.552Z',
    },
    backgroundColor: {
      id: '53890a9f-9f04-4d9a-b18c-ce9c06a83581',
      label: 'Bynder white',
      lastModifiedTime: '2021-08-23T09:44:28.552Z',
      hexCode: '#FFFFFF',
      creationTime: '2021-08-23T09:44:28.552Z',
    },
    fontFamily: 'Source Sans Pro',
    fontSize: 18,
    lastModifiedTime: '2021-08-23T09:44:28.552Z',
    fontStyle: 'SourceSansPro-regular',
    creationTime: '2021-08-23T09:44:28.552Z',
    textColor: {
      id: '6dd5f81d-b409-499b-b0be-e735a839cdc2',
      label: 'Bynder gray',
      lastModifiedTime: '2021-08-23T09:44:28.552Z',
      hexCode: '#002233',
      creationTime: '2021-08-23T09:44:28.552Z',
    },
  },
  sideBar: {
    lastModifiedTime: '2021-08-23T09:44:28.552Z',
    backgroundColor: {
      id: '1',
      hexCode: '#FFFFFF',
      label: 'Gray 10',
    },
    borderColor: {
      id: '4',
      hexCode: '#EBEDEF',
      label: 'Gray 75',
    },
    chapter: {
      fontFamily: 'Source Sans Pro',
      fontStyle: 'SourceSansPro-bold',
      fontSize: 14,
      colors: {
        text: {
          normal: {
            id: '7',
            hexCode: '#667A85',
            label: 'Gray 600',
          },

          hover: {
            id: '8',
            hexCode: '#334E5C',
            label: 'Gray 800',
          },
        },
        background: {
          hover: {
            id: '2',
            hexCode: '#F7F8F9',
            label: 'Gray 25',
          },
        },
      },
    },

    page: {
      fontFamily: 'Source Sans Pro',
      fontStyle: 'SourceSansPro-regular',
      fontSize: 16,
      colors: {
        text: {
          normal: {
            id: '7',
            hexCode: '#667A85',
            label: 'Gray 600',
          },

          hover: {
            id: '8',
            hexCode: '#334E5C',
            label: 'Gray 800',
          },
          active: {
            id: '8',
            hexCode: '#334E5C',
            label: 'Gray 800',
          },
        },
        background: {
          active: {
            id: '2',
            hexCode: '#F7F8F9',
            label: 'Gray 25',
          },
          hover: {
            id: '2',
            hexCode: '#F7F8F9',
            label: 'Gray 25',
          },
        },
      },
    },
    tableOfContents: {
      fontFamily: 'Source Sans Pro',
      fontStyle: 'SourceSansPro-regular',
      fontSize: 14,
      colors: {
        text: {
          normal: {
            id: '7',
            hexCode: '#667A85',
            label: 'Gray 600',
          },
          hover: {
            id: '8',
            hexCode: '#334E5C',
            label: 'Gray 800',
          },
          active: {
            id: '8',
            hexCode: '#334E5C',
            label: 'Gray 800',
          },
        },
        background: {
          hover: {
            id: '3',
            hexCode: '#F2F4F5',
            label: 'Gray 50',
          },
          active: {
            id: '3',
            hexCode: '#F2F4F5',
            label: 'Gray 50',
          },
        },
        accent: {
          normal: {
            id: '3',
            hexCode: '#F2F4F5',
            label: 'Gray 50',
          },
          hover: {
            id: '5',
            hexCode: '#E6E9EB',
            label: 'Gray 100',
          },
          active: {
            id: '6',
            hexCode: '#CCD3D6',
            label: 'Gray 200',
          },
        },
      },
    },
  },
  properties: {
    landingPageHeaderEnabled: true,
    pageHeaderEnabled: true,
    customLogoEnabled: false,
    logoId: null,
    logoUrl: null,
    backButton: true,
    backButtonBehavior: {
      behavior: BackButtonBehavior.OVERVIEW,
      link: null,
    },
  },
  category: ThemesetCategory.BYNDER_THEME_SET,
  isDefault: true,
  accountId: 'b05f9df8-cd3b-4b30-b57b-5b45ffaac62f',
  ownerId: 'ab851f80-916b-4e12-a49c-b70e6ab1f817',
  creationTime: '2021-08-23T09:44:28.552Z',
  lastModifiedTime: '2021-08-23T09:44:28.552Z',
};
