import { useQueries, useQuery } from '@tanstack/react-query';
import { jwtDecode } from 'jwt-decode';
import {
  fetchCurrentUserIdentityApi,
  fetchGroupIdentitiesApi,
  fetchProfileIdentitiesApi,
  fetchUserIdentitiesApi,
  fetchUserIdentityByIdApi,
} from '../helpers/api/identityApi';
import { BynderJWT, fetchToken } from '../helpers/jwt';
import { CurrentUserIdentity, IdentityType } from '../types';

const KEYS = {
  CURRENT_USER: 'CURRENT_USER',
  JWT_TOKEN: 'JWT_TOKEN',
  USER_IDENTITY: 'USER_IDENTITY',
  PROFILE_IDENTITY: 'PROFILE_IDENTITY',
  GROUP_IDENTITY: 'GROUP_IDENTITY',
  IP: 'IP',
};

export const useSearchIdentities = (
  searchTerm: string,
  shareSuggestionsSettings,
) => {
  const isSearchTermEmail = isEmail(searchTerm);
  return useQueries({
    queries: [
      {
        queryKey: ['SEARCH_USERS', searchTerm],
        queryFn: () => fetchUserIdentitiesApi(searchTerm, [], 1, 1000),
        staleTime: Infinity,
        enabled: shareSuggestionsSettings?.userSuggestions
          ? !!searchTerm
          : isSearchTermEmail,
        retryOnMount: false,
        placeholderData: [],
      },

      {
        queryKey: ['SEARCH_GROUPS', searchTerm],
        queryFn: () => fetchGroupIdentitiesApi(searchTerm, [], 1, 1000),
        staleTime: Infinity,
        enabled:
          !!searchTerm && shareSuggestionsSettings?.groupSuggestions === true,
        retryOnMount: false,
        placeholderData: [],
      },

      {
        queryKey: ['SEARCH_PROFILES', searchTerm],
        queryFn: () => fetchProfileIdentitiesApi(searchTerm, [], 1, 1000),
        staleTime: Infinity,
        enabled:
          !!searchTerm && shareSuggestionsSettings?.profileSuggestions === true,
        retryOnMount: false,
        placeholderData: [],
      },
    ],
  });
};

export const useUserIdentity = (userId: string) => {
  return useQuery(
    [KEYS.USER_IDENTITY, userId],
    () => fetchUserIdentityByIdApi(userId),
    {
      enabled: !!userId,
      retry: false,
      retryOnMount: false,
      staleTime: Infinity,
    },
  );
};

export const useIdentity = (id: string, identity: IdentityType) => {
  return useQuery(
    [KEYS.USER_IDENTITY, identity, id],
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (): any => {
      if (identity === IdentityType.USER) return fetchUserIdentityByIdApi(id);
      else if (identity === IdentityType.GROUP)
        return fetchGroupIdentitiesApi('', [id], 1, 1);
      else if (identity === IdentityType.PROFILE)
        return fetchProfileIdentitiesApi('', [id], 1, 1);
    },
    {
      enabled: !!id,
      retry: false,
      retryOnMount: false,
      staleTime: Infinity,
      select: data => (identity !== IdentityType.USER ? data[0] : data),
    },
  );
};

export const useCurrentUserIdentity = () => {
  return useQuery<CurrentUserIdentity>(
    [KEYS.CURRENT_USER],
    () => fetchCurrentUserIdentityApi(),
    {
      staleTime: Infinity,
      retry: false,
      retryOnMount: false,
    },
  );
};

export const useToken = () => {
  return useQuery<string>([KEYS.JWT_TOKEN], () => fetchToken(), {
    staleTime: Infinity,
    retry: false,
  });
};

export const useDecodedToken = () => {
  const { data: token, isLoading } = useToken();

  if (!isLoading && token) {
    return jwtDecode<BynderJWT>(token);
  }

  return null;
};

const isEmail = (email: string) => {
  const re = /\S+@\S+\.\S+/;
  return re.test(email);
};
