import { Typography } from './typography';
import { Color } from './color';
import { Font } from '../constants/themesets';

export interface ColorSet {
  id: string;
  label: string;
  lastModifiedTime: string;
  creationTime: string;
  colors: Color[];
}
export interface NavigationBar {
  backgroundColor: Color;
  borderColor: Color;
  textColor: Color;
  fontFamily: string;
  fontStyle: string;
  fontSize: number;
  creationTime?: string;
  lastModifiedTime?: string;
}

export enum BackButtonBehavior {
  BROWSER = 'BROWSER',
  CUSTOM = 'CUSTOM',
  OVERVIEW = 'OVERVIEW',
}

export interface GuideProperties {
  landingPageHeaderEnabled: boolean;
  pageHeaderEnabled: boolean;
  customLogoEnabled: boolean;
  logoId: string;
  logoUrl: string;
  backButton: boolean;
  backButtonBehavior: {
    behavior: BackButtonBehavior;
    link: string;
  };
}

export interface Sidebar {
  backgroundColor: Color;
  borderColor: Color;
  lastModifiedTime?: string;
  chapter: {
    fontFamily: string;
    fontStyle: string;
    fontSize: number;
    colors: {
      text: { normal: Color; hover: Color };
      background: { hover: Color };
    };
  };
  page: {
    fontFamily: string;
    fontStyle: string;
    fontSize: number;
    colors: {
      text: { normal: Color; hover: Color; active: Color };
      background: { hover: Color; active: Color };
    };
  };
  tableOfContents: {
    fontFamily: string;
    fontStyle: string;
    fontSize: number;
    colors: {
      text: { normal: Color; hover: Color; active: Color };
      background: { hover: Color; active: Color };
      accent: { normal: Color; hover: Color; active: Color };
    };
  };
}

export enum ThemesetCategory {
  BYNDER_THEME_SET = 'BYNDER_THEME_SET',
  PORTAL_THEME_SET = 'PORTAL_THEME_SET',
  CUSTOM_THEME_SET = 'CUSTOM_THEME_SET',
}

export interface Themeset {
  id: string;
  label: string;
  description: string;
  cover: ThemesetCover;
  accountId: string;
  ownerId: string;
  creationTime: string;
  lastModifiedTime: string;
  navigationBar?: NavigationBar;
  sideBar?: Sidebar;
  colorSets?: ColorSet[];
  fonts?: Font[];
  category: ThemesetCategory;
  typographies?: Typography[];
  default?: {
    textColorId: string;
    backGroundColorId: string;
  };
  isDefault: boolean;
  properties: GuideProperties;
}

export interface ThemesetDetails {
  label: string;
  description: string;
  backgroundColor: string;
  backgroundImageUrl: string;
}

interface ThemesetCover {
  backgroundColor: string;
  backgroundImageUrl: string;
}

export interface PortalInformation {
  domain: string;
  logo: string;
  logo_link: string;
  name: string;
  portal_id: string;
}
