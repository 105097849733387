import { API_ROUTES } from '../../constants/app';
import axios from '../axios';

interface PySettings {
  userSuggestions: boolean;
  groupSuggestions: boolean;
  profileSuggestions: boolean;
}

export const defaultPySettings: PySettings = {
  userSuggestions: true,
  groupSuggestions: true,
  profileSuggestions: true,
};

export const fetchSuggestionsPySettings = async (): Promise<PySettings> => {
  return (await axios())
    .get(API_ROUTES.SUGGESTIONS_PYSETTINGS)
    .then(res => res.data);
};
