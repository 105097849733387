import { StyleSheet } from '..';
import type { BaseWidget } from '../widgets';

export enum CollectionLayoutOptions {
  GRID = 'GRID',
  MASONRY = 'MASONRY',
}

export type CollectionFeedResponse = {
  id: string;
  name: string;
  account: string;
  description: string;
  downloadableMedia: number;
  filename: string;
  readonly: number;
  recipientShareId: string;
  media: AssetInFeedCollection[];
};

type AssetInFeedCollection = {
  isLimited: boolean;
  isPrivate: boolean;
  isArchived: boolean;
  brand: string;
  description: string;
  filename: string;
  id: string;
  image: string;
  name: string;
  readonly: boolean;
  thumb: string;
  type: string;
  mediaItems: MediaItemInAssetFeedCollection[];
};

type MediaItemInAssetFeedCollection = {
  height: number;
  size: string;
  width: number;
  id: string;
  extension: string;
  type: 'original' | 'web';
};

interface ContentCollectionWidget {
  collectionId: string;
  collectionSettings?: EditCollectionPropsType;
  style?: StyleSheet;
}

export interface EditCollectionPropsType {
  layout: CollectionLayoutOptions;
  collectionId?: string;
  assetsShown: number;
  showAssetTitle: boolean;
  showAssetType: boolean;
  showAssetDownload: boolean;
  showCollectionDownload: boolean;
  allowAssetRedirection: boolean;
  allowCollectionRedirection: boolean;
  showOpenAssetInAssetBank: boolean;
}

export interface NormalizedFeedCollectionResponse {
  collectionId: string;
  collectionName: string;
  collectionUrl: string;
  collectionAssetCount: number;
  collectionPreviews: CollectionMediaItem[];
}

export interface CollectionMediaItem {
  isLimited: boolean;
  isPrivate: boolean;
  isArchived: boolean;
  assetId: string;
  assetName: string;
  assetImageUrl: string;
  assetDetailUrl: string;
  assetExtension: string;
}

export interface CollectionWidget extends BaseWidget, ContentCollectionWidget {}
