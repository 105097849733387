import {
  CSS_CLASSES,
  CSS_PROPERTIES,
} from '../../components/guides/Inspector/constants';
import {
  DEFAULT_CARD_WIDGET_CARD_SIZE,
  getDefaultSpacerHeight,
} from '../../components/widgets/helpers';
import { generateUUID } from '../../helpers/utils';
import { getNewCard } from '../../mutations/widgets/card';
import {
  GuideType,
  WidgetAlignment,
  SectionLayout,
  ContentAlignment,
  WIDGET_TYPE,
} from '../../types';
import { Command } from '../../types/commands';
import { emptyCSSObject, SpacingWidgetOptions } from '../widgets';
import {
  REDIRECT_TITLE,
  CARDS_INFO_TEXT,
  COLLECTIONS_TITLE,
  COLLECTION_INFO_TEXT,
  EMBED_COLLECTION_BUTTON_TEXT,
  BUTTON_STYLE_HOMEPAGE_TEMPLATE,
  QUICK_LINKS_TITLE,
  EXPRESS_TITLE,
  EXPRESS_INFO_TEXT,
  VIDEOS_TITLE,
  VIDEOS_INFO_TEXT,
  NAVIGATION_LINKS_TITLE,
  LINK_HOMEPAGE_TEMPLATE,
} from './constants';

export function getSharedTemplate(
  guideId: string,
  chapterId: string,
  pageId: string,
  sectionId: string,
  guideVersion: number,
) {
  const {
    addNewSectionToPage,
    changeSectionLayout,
    editSectionWidth,
    editSectionHeight,
    editSectionContentAlignment,
    editSectionHorizontalPadding,
    addNewTextWidgetToSection,
    addNewButtonWidgetToSection,
    addNewCardWidgetToSection,
    editSectionVerticalPadding,
    addNewYoutubeWidgetToSection,
  } = BatchCommands(guideId, chapterId, pageId, sectionId, guideVersion);

  const secondSectionId = generateUUID();
  const thirdSectionId = generateUUID();
  const fourthSectionId = generateUUID();
  const fifthSectionId = generateUUID();
  const sixthSectionId = generateUUID();
  const seventhSectionId = generateUUID();

  return [
    addNewSectionToPage(1, secondSectionId),
    editSectionWidth(1200, secondSectionId),
    editSectionHeight(120, secondSectionId),
    editSectionVerticalPadding(48, secondSectionId),
    addNewTextWidgetToSection(REDIRECT_TITLE, 0, secondSectionId),
    addNewTextWidgetToSection(CARDS_INFO_TEXT, 0, secondSectionId),
    addNewCardWidgetToSection(
      [
        getNewCard(
          generateUUID(),
          'Asset Category A',
          '',
          'https://community.bynder.com/',
        ),
        getNewCard(
          generateUUID(),
          'Asset Category B',
          '',
          'https://support.bynder.com/hc/en-us',
        ),
        getNewCard(
          generateUUID(),
          'Asset Category C',
          '',
          'https://www.bynder.com/en/',
        ),
        getNewCard(generateUUID(), 'Asset Category D'),
      ],
      0,
      WidgetAlignment.CENTER,
      secondSectionId,
    ),

    // third section
    addNewSectionToPage(2, thirdSectionId),
    editSectionWidth(1200, thirdSectionId),
    editSectionHeight(120, thirdSectionId),
    editSectionVerticalPadding(48, thirdSectionId),
    addNewTextWidgetToSection(COLLECTIONS_TITLE, 0, thirdSectionId),
    addNewTextWidgetToSection(COLLECTION_INFO_TEXT, 0, thirdSectionId),
    addNewButtonWidgetToSection(
      EMBED_COLLECTION_BUTTON_TEXT,
      BUTTON_STYLE_HOMEPAGE_TEMPLATE,
      generateUUID(),
      '',
      thirdSectionId,
    ),

    // fourth section
    addNewSectionToPage(3, fourthSectionId),
    editSectionWidth(1200, fourthSectionId),
    editSectionVerticalPadding(48, fourthSectionId),
    editSectionContentAlignment(ContentAlignment.CENTER, fourthSectionId),
    addNewTextWidgetToSection(QUICK_LINKS_TITLE, 0, fourthSectionId),
    addNewCardWidgetToSection(
      [
        getNewCard(
          generateUUID(),
          'Community',
          '',
          'https://community.bynder.com/',
        ),
        getNewCard(
          generateUUID(),
          'Knowledge base',
          '',
          'https://support.bynder.com/hc/en-us',
        ),
        getNewCard(
          generateUUID(),
          'Bynder website',
          '',
          'https://www.bynder.com/en/',
        ),
      ],
      0,
      WidgetAlignment.CENTER,
      fourthSectionId,
    ),

    // fifth section
    addNewSectionToPage(4, fifthSectionId),
    editSectionWidth(1200, fifthSectionId),
    editSectionVerticalPadding(48, fifthSectionId),
    editSectionContentAlignment(ContentAlignment.CENTER, fifthSectionId),
    changeSectionLayout(SectionLayout.DOUBLE_50_50, fifthSectionId),
    addNewTextWidgetToSection(EXPRESS_TITLE, 0, fifthSectionId),
    addNewTextWidgetToSection(EXPRESS_INFO_TEXT, 0, fifthSectionId),
    addNewCardWidgetToSection(
      [getNewCard(generateUUID(), 'Share files')],
      1,
      WidgetAlignment.CENTER,
      fifthSectionId,
    ),

    // sixth section
    addNewSectionToPage(5, sixthSectionId),
    editSectionWidth(1200, sixthSectionId),
    editSectionHeight(120, sixthSectionId),
    editSectionVerticalPadding(48, sixthSectionId),
    addNewTextWidgetToSection(VIDEOS_TITLE, 0, sixthSectionId),
    addNewTextWidgetToSection(VIDEOS_INFO_TEXT, 0, sixthSectionId),
    addNewYoutubeWidgetToSection(0, sixthSectionId),

    // seventh section
    addNewSectionToPage(6, seventhSectionId),
    editSectionWidth(1200, seventhSectionId),
    editSectionHeight(120, seventhSectionId),
    editSectionHorizontalPadding(16, seventhSectionId),
    editSectionVerticalPadding(48, seventhSectionId),
    changeSectionLayout(SectionLayout.TRIPLE_33_33_33, seventhSectionId),
    addNewTextWidgetToSection(NAVIGATION_LINKS_TITLE, 0, seventhSectionId),
    addNewTextWidgetToSection(LINK_HOMEPAGE_TEMPLATE, 0, seventhSectionId),
    addNewTextWidgetToSection(LINK_HOMEPAGE_TEMPLATE, 0, seventhSectionId),
    addNewTextWidgetToSection(LINK_HOMEPAGE_TEMPLATE, 0, seventhSectionId),
    addNewTextWidgetToSection(NAVIGATION_LINKS_TITLE, 1, seventhSectionId),
    addNewTextWidgetToSection(LINK_HOMEPAGE_TEMPLATE, 1, seventhSectionId),
    addNewTextWidgetToSection(LINK_HOMEPAGE_TEMPLATE, 1, seventhSectionId),
    addNewTextWidgetToSection(LINK_HOMEPAGE_TEMPLATE, 1, seventhSectionId),
    addNewTextWidgetToSection(NAVIGATION_LINKS_TITLE, 2, seventhSectionId),
    addNewTextWidgetToSection(LINK_HOMEPAGE_TEMPLATE, 2, seventhSectionId),
    addNewTextWidgetToSection(LINK_HOMEPAGE_TEMPLATE, 2, seventhSectionId),
    addNewTextWidgetToSection(LINK_HOMEPAGE_TEMPLATE, 2, seventhSectionId),
  ];
}

export const BatchCommands = (
  guideId: string,
  chapterId: string,
  pageId: string,
  sectionId: string,
  guideVersion: number,
  groupId?: string,
) => {
  return {
    createLandingPage: (guideTitle: string) =>
      groupId
        ? {
            command: Command.CREATE_GUIDE_WITH_TEMPLATE_INSIDE_GROUP,
            aggregateId: guideId,
            aggregateVersion: guideVersion,
            payload: {
              guideTitle,
              chapterId,
              chapterTitle: 'Chapter',
              pageId,
              pageTitle: 'Page',
              sectionId,
              guideType: GuideType.SINGLE_PAGE,
              groupId,
              groupVersion: 0,
            },
          }
        : {
            command: Command.CREATE_GUIDE_WITH_TEMPLATE,
            aggregateId: guideId,
            aggregateVersion: guideVersion,
            payload: {
              guideTitle,
              chapterId,
              chapterTitle: 'Chapter',
              pageId,
              pageTitle: 'Page',
              sectionId,
              guideType: GuideType.SINGLE_PAGE,
            },
          },

    addPageToChapter: (pageTitle: string) => ({
      command: Command.ADD_NEW_PAGE_TO_CHAPTER,
      aggregateId: guideId,
      aggregateVersion: guideVersion,
      payload: {
        chapterId,
        pageId,
        pageTitle,
      },
    }),

    addNewCardWidgetToSection: (
      cardPayloads: object[],
      columnIndex: number = 0,
      widgetAlignment: WidgetAlignment = WidgetAlignment.CENTER,
      newSectionId: string = sectionId,
      cardSize: number = DEFAULT_CARD_WIDGET_CARD_SIZE,
    ) => ({
      command: Command.ADD_NEW_CARD_WIDGET_TO_SECTION,
      aggregateId: guideId,
      aggregateVersion: guideVersion,
      payload: {
        chapterId,
        pageId,
        sectionId: newSectionId,
        columnIndex,
        widgetId: generateUUID(),
        cardWidgetPayload: {
          cardPayloads,
          widgetStyle: {
            ...emptyCSSObject,
            stylesheet: `${CSS_CLASSES.CARD}{\n${CSS_PROPERTIES.WIDTH}:${cardSize};\n}\n${CSS_PROPERTIES.JUSTIFY_CONTENT}:${widgetAlignment};\n`,
          },
        },
      },
    }),
    changeSectionLayout: (
      sectionLayout: SectionLayout,
      newSectionId: string = sectionId,
    ) => ({
      command: Command.CHANGE_SECTION_LAYOUT,
      aggregateId: guideId,
      aggregateVersion: 0,
      payload: {
        chapterId,
        pageId,
        sectionId: newSectionId,
        sectionLayout,
      },
    }),
    editSectionHorizontalPadding: (
      sectionHorizontalPadding: number,
      newSectionId: string = sectionId,
    ) => ({
      command: Command.EDIT_SECTION_HORIZONTAL_PADDING,
      aggregateId: guideId,
      aggregateVersion: guideVersion,
      payload: {
        chapterId,
        pageId,
        sectionId: newSectionId,
        sectionHorizontalPadding,
      },
    }),
    editSectionVerticalPadding: (
      sectionVerticalPadding: number,
      newSectionId: string = sectionId,
    ) => ({
      command: Command.EDIT_SECTION_VERTICAL_PADDING,
      aggregateId: guideId,
      aggregateVersion: guideVersion,
      payload: {
        chapterId,
        pageId,
        sectionId: newSectionId,
        sectionVerticalPadding,
      },
    }),
    editSectionContentSpacing: (
      sectionContentSpacing: number,
      newSectionId: string = sectionId,
    ) => ({
      command: Command.EDIT_SECTION_CONTENT_SPACING,
      aggregateId: guideId,
      aggregateVersion: guideVersion,
      payload: {
        chapterId,
        pageId,
        sectionId: newSectionId,
        sectionContentSpacing,
      },
    }),
    editSectionColumnSpacing: (
      sectionColumnSpacing: number,
      newSectionId: string = sectionId,
    ) => ({
      command: Command.EDIT_SECTION_COLUMN_SPACING,
      aggregateId: guideId,
      aggregateVersion: guideVersion,
      payload: {
        chapterId,
        pageId,
        sectionId: newSectionId,
        sectionColumnSpacing,
      },
    }),
    addNewSectionToPage: (
      sectionPosition: number,
      newSectionId: string = sectionId,
    ) => ({
      command: Command.ADD_NEW_SECTION_TO_PAGE,
      aggregateId: guideId,
      aggregateVersion: guideVersion,
      payload: {
        chapterId,
        pageId,
        sectionId: newSectionId,
        sectionPosition,
      },
    }),
    editSectionWidth: (
      sectionWidth: number,
      newSectionId: string = sectionId,
    ) => ({
      command: Command.EDIT_SECTION_WIDTH,
      aggregateId: guideId,
      aggregateVersion: guideVersion,
      payload: {
        chapterId,
        pageId,
        sectionId: newSectionId,
        sectionWidth,
      },
    }),
    editSectionHeight: (
      sectionHeight: number,
      newSectionId: string = sectionId,
    ) => ({
      command: Command.EDIT_SECTION_HEIGHT,
      aggregateId: guideId,
      aggregateVersion: guideVersion,
      payload: {
        chapterId,
        pageId,
        sectionId: newSectionId,
        sectionHeight,
      },
    }),
    addSpacingWidgetToSection: (
      height: number = getDefaultSpacerHeight(),
      divider: SpacingWidgetOptions = SpacingWidgetOptions.None,
      newSectionId: string = sectionId,
      columnIndex: number = 0,
    ) => ({
      command: Command.ADD_NEW_SPACING_WIDGET_TO_SECTION,
      aggregateId: guideId,
      aggregateVersion: guideVersion,
      payload: {
        chapterId,
        pageId,
        sectionId: newSectionId,
        widgetId: generateUUID(),
        columnIndex,
        widgetSpacingPayload: {
          height,
          divider,
          style: {
            stylesheet: '',
            language: 'CSS',
            version: 3,
          },
        },
      },
    }),
    editStyleInSection: (style: string, newSectionId: string = sectionId) => ({
      command: Command.EDIT_STYLE_IN_SECTION,
      aggregateId: guideId,
      aggregateVersion: guideVersion,
      payload: {
        chapterId,
        pageId,
        sectionId: newSectionId,
        sectionPayload: {
          style: {
            ...emptyCSSObject,
            stylesheet: style,
          },
        },
      },
    }),

    editSectionContentAlignment: (
      sectionContentAlignment: ContentAlignment,
      newSectionId: string = sectionId,
    ) => ({
      command: Command.EDIT_SECTION_CONTENT_ALIGNMENT,
      aggregateId: guideId,
      aggregateVersion: guideVersion,
      payload: {
        chapterId,
        pageId,
        sectionId: newSectionId,
        sectionContentAlignment,
      },
    }),
    addNewTextWidgetToSection: (
      widgetText: string,
      columnIndex: number = 0,
      newSectionId: string = sectionId,
    ) => ({
      command: Command.ADD_NEW_TEXT_WIDGET_TO_SECTION,
      aggregateId: guideId,
      aggregateVersion: guideVersion,
      payload: {
        chapterId,
        pageId,
        sectionId: newSectionId,
        widgetId: generateUUID(),
        widgetType: WIDGET_TYPE.TEXT,
        columnIndex,
        widgetText,
      },
    }),
    addNewYoutubeWidgetToSection: (
      columnIndex: number = 0,
      newSectionId: string = sectionId,
    ) => ({
      command: Command.ADD_NEW_YOUTUBE_WIDGET_TO_SECTION,
      aggregateId: guideId,
      aggregateVersion: guideVersion,
      payload: {
        chapterId,
        pageId,
        sectionId: newSectionId,
        widgetId: generateUUID(),
        columnIndex,
        youtubeWidgetPayload: {
          sourceId: 'BSprLGppjLk',
          settings: {
            loop: false,
            autoplay: false,
            controls: true,
          },
        },
      },
    }),
    addNewButtonsToButtonWidget: (
      buttonText: string,
      buttonStylesheet: string,
      widgetId: string = generateUUID(),
      columnIndex: number = 0,
      newSectionId: string = sectionId,
    ) => ({
      command: Command.ADD_NEW_BUTTONS_TO_BUTTON_WIDGET,
      aggregateId: guideId,
      aggregateVersion: guideVersion,
      payload: {
        chapterId,
        pageId,
        sectionId: newSectionId,
        widgetId,
        columnIndex,
        buttonWidgetPayload: {
          buttonPayloads: [
            {
              id: generateUUID(),
              name: {
                text: buttonText,
              },
              link: {
                url: '',
                openNewTab: false,
              },
              buttonStyle: {
                stylesheet: buttonStylesheet,
                language: 'css',
                version: 3,
              },
            },
          ],
        },
      },
    }),
    addNewButtonWidgetToSection: (
      buttonText: string,
      buttonStylesheet: string,
      widgetId: string = generateUUID(),
      widgetStylesheet: string = '',
      newSectionId: string = sectionId,
      columnIndex: number = 0,
    ) => ({
      command: Command.ADD_NEW_BUTTON_WIDGET_TO_SECTION,
      aggregateId: guideId,
      aggregateVersion: guideVersion,
      payload: {
        chapterId,
        pageId,
        sectionId: newSectionId,
        columnIndex,
        widgetId,
        buttonWidgetPayload: {
          buttonPayloads: [
            {
              id: generateUUID(),
              name: {
                text: buttonText,
              },
              link: {
                url: '',
                openNewTab: false,
              },
              buttonStyle: {
                stylesheet: buttonStylesheet,
                language: 'css',
                version: 3,
              },
            },
          ],
          widgetStyle: {
            stylesheet: widgetStylesheet,
            language: 'CSS',
            version: '3',
          },
        },
      },
    }),
  };
};
