import styled from 'styled-components';
import { token } from '@bynder/design-system';

interface RectangleProps {
  width: number;
}
interface CircleProps {
  radius: number;
}

interface GradientProps {
  $index: number;
  $direction: 'right' | 'bottom';
}

export const DISPLAY_ELEMENTS = 4;

export const SkeletonRectangle = styled.div<RectangleProps>`
  height: 8px;
  width: ${({ width }) => `${width}px`};
  border-radius: ${token.radiusMedium};
  background-color: ${token.gray100a};
`;

export const SkeletonCircle = styled.div<CircleProps>`
  width: ${({ radius }) => `${radius}px`};
  height: ${({ radius }) => `${radius}px`};
  background-color: ${token.gray100a};
  border-radius: ${token.radiusCircle};
`;

export const SkeletonGradient = styled.div<GradientProps>`
  opacity: 0;
  position: absolute;
  height: 102%;
  width: 102%;
  z-index: 45;
  background: linear-gradient(
    to ${({ $direction }) => $direction},
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.35) 24%,
    rgba(255, 255, 255, 0.36) 25%,
    rgba(255, 255, 255, 0.84) 57%,
    rgba(255, 255, 255, 1) 68%,
    rgba(255, 255, 255, 1) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  
  ${({ $index }) => $index === DISPLAY_ELEMENTS - 1 && `opacity: 1;`}
  ${({ $index }) => $index === DISPLAY_ELEMENTS - 2 && `opacity: 0.5;`}
  ${({ $index }) => $index === DISPLAY_ELEMENTS - 3 && `opacity: 0.25;`}
  }
`;
