import { useQuery } from '@tanstack/react-query';
import { fetchGroupByIdApi, fetchGroups } from '../helpers/api/guidelinesApi';
import { Group } from '../types';
import { OVERVIEW_KEYS } from './guides';
import { useOverviewStore } from '../stores/overviewStore';

export const useGetOverviewGroups = () => {
  const orderType = useOverviewStore.use.orderType();
  const orderBy = useOverviewStore.use.orderBy();

  return useQuery(
    [OVERVIEW_KEYS.OVERVIEW_GROUPS, orderBy, orderType],
    () => fetchGroups({ orderBy, orderType }),
    {
      enabled: !!orderBy && !!orderType,
    },
  );
};

export const useOverviewGroupById = (groupId: string): Group => {
  const { data: groups } = useGetOverviewGroups();

  return groups?.find(group => group.id === groupId);
};

export const useGroupById = (groupId: string) => {
  const orderType = useOverviewStore.use.orderType();
  const orderBy = useOverviewStore.use.orderBy();

  return useQuery(
    [OVERVIEW_KEYS.OVERVIEW_GROUPS, orderBy, orderType, groupId],
    () => fetchGroupByIdApi(groupId, { orderBy, orderType }),
  );
};
