import {
  Ability,
  AbilityBuilder,
  createAliasResolver,
  defineAbility,
} from '@casl/ability';

import type { BynderJWT } from './jwt';
import {
  CONTENT_CUSTOMIZATION_PERMISSIONS,
  GUIDELINES_PERMISSIONS,
  JWT_PERMISSIONS,
  PERMISSION_ENTITIES, PORTAL_SETTINGS_PERMISSIONS,
} from './permissions';
import { useAppStoreBase } from '../stores/appStore';
import { jwtDecode } from 'jwt-decode';

type GuidelinesAbilities = Ability<
  [
    GUIDELINES_PERMISSIONS | CONTENT_CUSTOMIZATION_PERMISSIONS | 'edit',
    PERMISSION_ENTITIES | GuideAbility,
  ]
>;

const editAction = createAliasResolver({
  edit: [GUIDELINES_PERMISSIONS.CREATE],
});

export class GuideAbility {
  public author: string;

  constructor(author: string) {
    this.author = author?.toLowerCase();
  }

  static get modelName(): PERMISSION_ENTITIES {
    return PERMISSION_ENTITIES.GUIDE;
  }
}

export class Group {
  public author: string;

  constructor(author: string) {
    this.author = author.toLowerCase();
  }

  static get modelName(): PERMISSION_ENTITIES {
    return PERMISSION_ENTITIES.GROUP;
  }
}

const hasContentManagePermission = (
  permission: JWT_PERMISSIONS[keyof JWT_PERMISSIONS],
) => permission === JWT_PERMISSIONS.CONTENT_CUSTOMIZATION_MANAGE;

const hasManagePortalSettingsPermission = (
  permission: JWT_PERMISSIONS[keyof JWT_PERMISSIONS],
) => permission === JWT_PERMISSIONS.MANAGE_PORTAL_SETTINGS;

const hasManagePermission = (
  permission: JWT_PERMISSIONS[keyof JWT_PERMISSIONS],
) =>
  permission === JWT_PERMISSIONS.GUIDELINES_MANAGE ||
  permission === JWT_PERMISSIONS.GUIDELINES_ADVANCED_MANAGE ||
  permission === JWT_PERMISSIONS.GUIDELINES_ENTERPRISE_MANAGE;

const hasCreatePermission = (
  permission: JWT_PERMISSIONS[keyof JWT_PERMISSIONS],
) =>
  permission === JWT_PERMISSIONS.GUIDELINES_CREATE ||
  permission === JWT_PERMISSIONS.GUIDELINES_ADVANCED_CREATE ||
  permission === JWT_PERMISSIONS.GUIDELINES_ENTERPRISE_CREATE;

const hasViewPermission = (
  permission: JWT_PERMISSIONS[keyof JWT_PERMISSIONS],
) =>
  permission === JWT_PERMISSIONS.GUIDELINES_VIEW ||
  permission === JWT_PERMISSIONS.GUIDELINES_ADVANCED_VIEW ||
  permission === JWT_PERMISSIONS.GUIDELINES_ENTERPRISE_VIEW;

const userCanManageGuides = (can: AbilityBuilder<GuidelinesAbilities>['can']) =>
  can(
    [
      GUIDELINES_PERMISSIONS.MANAGE,
      GUIDELINES_PERMISSIONS.CREATE,
      GUIDELINES_PERMISSIONS.VIEW,
    ],
    PERMISSION_ENTITIES.GUIDE,
  );

const userCanCreateGuides = (
  can: AbilityBuilder<GuidelinesAbilities>['can'],
  userId: string,
) => {
  can(GUIDELINES_PERMISSIONS.VIEW, PERMISSION_ENTITIES.GUIDE);
  can([GUIDELINES_PERMISSIONS.CREATE, 'edit'], PERMISSION_ENTITIES.GUIDE, {
    author: userId,
  });
};

const userCanViewGuides = (can: AbilityBuilder<GuidelinesAbilities>['can']) =>
  can(GUIDELINES_PERMISSIONS.VIEW, PERMISSION_ENTITIES.GUIDE);

const userCannotOperateGuides = (
  cannot: AbilityBuilder<GuidelinesAbilities>['cannot'],
) =>
  cannot(
    [
      GUIDELINES_PERMISSIONS.MANAGE,
      GUIDELINES_PERMISSIONS.CREATE,
      GUIDELINES_PERMISSIONS.VIEW,
    ],
    PERMISSION_ENTITIES.GUIDE,
  );

const userCanManageContentCustomization = (
  can: AbilityBuilder<GuidelinesAbilities>['can'],
) =>
  can(
    CONTENT_CUSTOMIZATION_PERMISSIONS.MANAGE,
    PERMISSION_ENTITIES.CONTENT_CUSTOMIZATION_SETTINGS,
  );

const userCannotManageContentCustomization = (
  cannot: AbilityBuilder<GuidelinesAbilities>['cannot'],
) =>
  cannot(
    CONTENT_CUSTOMIZATION_PERMISSIONS.MANAGE,
    PERMISSION_ENTITIES.CONTENT_CUSTOMIZATION_SETTINGS,
  );

const userCanManagePortalSettings = (
  can: AbilityBuilder<GuidelinesAbilities>['can'],
) =>
  can(
    PORTAL_SETTINGS_PERMISSIONS.MANAGE,
    PERMISSION_ENTITIES.PORTAL_SETTINGS,
  );

const userCannotManagePortalSettings = (
  cannot: AbilityBuilder<GuidelinesAbilities>['cannot'],
) =>
  cannot(
    PORTAL_SETTINGS_PERMISSIONS.MANAGE,
    PERMISSION_ENTITIES.PORTAL_SETTINGS,
  );

const userCannotAdvancedOperateGuides = (
  cannot: AbilityBuilder<GuidelinesAbilities>['cannot'],
) => {
  cannot(
    [
      GUIDELINES_PERMISSIONS.ADVANCED_MANAGE,
      GUIDELINES_PERMISSIONS.ADVANCED_CREATE,
      GUIDELINES_PERMISSIONS.ADVANCED_VIEW,
    ],
    PERMISSION_ENTITIES.GUIDE,
  );
};

const userCanViewGroups = (can: AbilityBuilder<GuidelinesAbilities>['can']) =>
  can(GUIDELINES_PERMISSIONS.VIEW, PERMISSION_ENTITIES.GROUP);

const userCanCreateGroups = (
  can: AbilityBuilder<GuidelinesAbilities>['can'],
  userId: string,
) => {
  can(
    [GUIDELINES_PERMISSIONS.CREATE, GUIDELINES_PERMISSIONS.VIEW],
    PERMISSION_ENTITIES.GROUP,
    {
      author: userId,
    },
  );
};

const userCanManageGroups = (can: AbilityBuilder<GuidelinesAbilities>['can']) =>
  can(GUIDELINES_PERMISSIONS.MANAGE, PERMISSION_ENTITIES.GROUP);

const userCanManageThemesets = (
  can: AbilityBuilder<GuidelinesAbilities>['can'],
) => {
  can(GUIDELINES_PERMISSIONS.MANAGE, PERMISSION_ENTITIES.THEMESETS);
};

const userCanEditThemesets = (
  can: AbilityBuilder<GuidelinesAbilities>['can'],
) =>
  can([GUIDELINES_PERMISSIONS.CREATE, 'edit'], PERMISSION_ENTITIES.THEMESETS);

const userCannotOperateThemesets = (
  cannot: AbilityBuilder<GuidelinesAbilities>['cannot'],
) => {
  cannot(
    [
      GUIDELINES_PERMISSIONS.MANAGE,
      GUIDELINES_PERMISSIONS.CREATE,
      GUIDELINES_PERMISSIONS.VIEW,
    ],
    PERMISSION_ENTITIES.THEMESETS,
  );
};

const userCannotOperateGroups = (
  cannot: AbilityBuilder<GuidelinesAbilities>['cannot'],
) => {
  cannot(
    [
      GUIDELINES_PERMISSIONS.MANAGE,
      GUIDELINES_PERMISSIONS.CREATE,
      GUIDELINES_PERMISSIONS.VIEW,
    ],
    PERMISSION_ENTITIES.GROUP,
  );
};

const userCanAdvanceManageGuides = (
  can: AbilityBuilder<GuidelinesAbilities>['can'],
) => {
  can(GUIDELINES_PERMISSIONS.ADVANCED_MANAGE, PERMISSION_ENTITIES.GUIDE);
  can(GUIDELINES_PERMISSIONS.ADVANCED_CREATE, PERMISSION_ENTITIES.GUIDE);
};

const userCanAdvanceCreateGuides = (
  can: AbilityBuilder<GuidelinesAbilities>['can'],
  userId: string,
) => {
  can(GUIDELINES_PERMISSIONS.ADVANCED_CREATE, PERMISSION_ENTITIES.GUIDE, {
    author: userId,
  });
};

const getAbility = (
  permissions: string[],
  userId: string,
): GuidelinesAbilities =>
  defineAbility<GuidelinesAbilities>(
    (can, cannot) => {
      if (userId === '') {
        userCannotOperateGuides(cannot);
        userCannotAdvancedOperateGuides(cannot);
        userCannotManageContentCustomization(cannot);

        return;
      }

      // Guide creation checks
      if (permissions.some(hasManagePermission)) {
        userCanManageGuides(can);
      } else if (permissions.some(hasCreatePermission)) {
        userCanCreateGuides(can, userId);
      } else if (permissions.some(hasViewPermission)) {
        userCanViewGuides(can);
      } else {
        userCannotOperateGuides(cannot);
      }

      // Modal Access checks
      if (
        permissions.includes(JWT_PERMISSIONS.GUIDELINES_ADVANCED_MANAGE) ||
        permissions.includes(JWT_PERMISSIONS.GUIDELINES_ENTERPRISE_MANAGE)
      ) {
        userCanAdvanceManageGuides(can);
      } else if (
        permissions.includes(JWT_PERMISSIONS.GUIDELINES_ADVANCED_CREATE) ||
        permissions.includes(JWT_PERMISSIONS.GUIDELINES_ENTERPRISE_CREATE)
      ) {
        userCanAdvanceCreateGuides(can, userId);
      } else {
        userCannotAdvancedOperateGuides(cannot);
      }

      // Group checks
      if (permissions.includes(JWT_PERMISSIONS.GUIDELINES_ENTERPRISE_MANAGE)) {
        userCanManageGroups(can);
      } else if (
        permissions.includes(JWT_PERMISSIONS.GUIDELINES_ENTERPRISE_CREATE)
      ) {
        userCanCreateGroups(can, userId);
      } else if (
        permissions.includes(JWT_PERMISSIONS.GUIDELINES_ENTERPRISE_VIEW)
      ) {
        userCanViewGroups(can);
      } else {
        userCannotOperateGroups(cannot);
      }

      // Themeset checks
      if (permissions.includes(JWT_PERMISSIONS.GUIDELINES_ENTERPRISE_MANAGE)) {
        userCanManageThemesets(can);
      } else if (
        permissions.includes(JWT_PERMISSIONS.GUIDELINES_ADVANCED_MANAGE) ||
        permissions.includes(JWT_PERMISSIONS.GUIDELINES_MANAGE)
      ) {
        userCanEditThemesets(can);
      } else {
        userCannotOperateThemesets(cannot);
      }

      if (permissions.some(hasContentManagePermission)) {
        userCanManageContentCustomization(can);
      } else {
        userCannotManageContentCustomization(cannot);
      }

      if (permissions.some(hasManagePortalSettingsPermission)) {
        userCanManagePortalSettings(can);
      } else {
        userCannotManagePortalSettings(cannot);
      }
    },
    { resolveAction: editAction },
  );

export const abilitiesRules: Ability = new Ability();

export const updateAbilities = (token: string): void => {
  const setHasAbilities = useAppStoreBase.getState().setHasAbilities;
  try {
    const payload = jwtDecode<BynderJWT>(token);
    const { module_permissions, user_id } = payload;

    abilitiesRules.update(
      getAbility(module_permissions, user_id.toLowerCase()).rules,
    );
    setHasAbilities();
  } catch (e) {
    console.warn(`Could not update abilities`);
  }
};
