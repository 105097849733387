import type { Translations } from '@bynder/localization';
import moment from 'moment';
import 'moment/dist/locale/de';
import 'moment/dist/locale/es';
import 'moment/dist/locale/fr';
import 'moment/dist/locale/it';
import 'moment/dist/locale/ja';
import 'moment/dist/locale/ko';
import 'moment/dist/locale/nl';
import 'moment/dist/locale/pl';
import 'moment/dist/locale/pt';
import 'moment/dist/locale/ru';
import 'moment/dist/locale/tr';
import 'moment/dist/locale/zh-cn';
import 'moment/dist/locale/zh-tw';

export const getLocale = () => {
  const availableLocales = [
    'de_DE',
    'en_US',
    'es_ES',
    'fr_FR',
    'it_IT',
    'ja_JP',
    'ko_KR',
    'nl_NL',
    'pl_PL',
    'pt_BR',
    'ru_RU',
    'tr_TR',
    'zh_CN',
    'zh_TW',
  ];

  const bynderLanguage = (
    document.querySelector('html') as HTMLElement
  ).getAttribute('lang');

  if (!bynderLanguage) return 'en_US';

  const locale = availableLocales.includes(bynderLanguage)
    ? bynderLanguage
    : 'en_US';
  moment.locale(locale);

  return locale;
};

export const fetchFallback = () => {
  const locales: Record<
    string,
    () => Promise<Record<string, string> | Translations>
  > = {
    en_US: () => import('../i18n/en_US').then(({ en_US }) => en_US),
    de_DE: () =>
      import('../i18n/de_DE.json').then(response => response.default),
    es_ES: () =>
      import('../i18n/es_ES.json').then(response => response.default),
    fr_FR: () =>
      import('../i18n/fr_FR.json').then(response => response.default),
    it_IT: () =>
      import('../i18n/it_IT.json').then(response => response.default),
    ja_JP: () =>
      import('../i18n/ja_JP.json').then(response => response.default),
    ko_KR: () =>
      import('../i18n/ko_KR.json').then(response => response.default),
    nl_NL: () =>
      import('../i18n/nl_NL.json').then(response => response.default),
    pl_PL: () =>
      import('../i18n/pl_PL.json').then(response => response.default),
    pt_BR: () =>
      import('../i18n/pt_BR.json').then(response => response.default),
    ru_RU: () =>
      import('../i18n/ru_RU.json').then(response => response.default),
    tr_TR: () =>
      import('../i18n/tr_TR.json').then(response => response.default),
    zh_CN: () =>
      import('../i18n/zh_CN.json').then(response => response.default),
    zh_TW: () =>
      import('../i18n/zh_TW.json').then(response => response.default),
  };
  const locale = getLocale();

  return locales[locale]() as Promise<Translations>;
};
