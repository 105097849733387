import React from 'react';
import styled from 'styled-components';
import { Switch, Button, ModalBase, Divider } from '@bynder/design-system';
import { Translate, useTranslate } from '@bynder/localization';
import {
  StyledGuideStateInfoContainer,
  StyledGuideStateInfoLabel,
  StyledModalDescription,
} from '../shared/styled-components/Modal';

import PrimaryButton from '../shared/PrimaryButton';

export type TableOfContentsModalProps = {
  onCancel: () => void;
  onSubmit: (visibleInGuide: boolean, visibleInPage: boolean) => void;
  visibleInGuide: boolean;
  visibleInPage: boolean;
};

const TableOfContentsModal: React.FC<TableOfContentsModalProps> = ({
  onCancel,
  onSubmit,
  visibleInGuide,
  visibleInPage,
}) => {
  const { translate } = useTranslate();

  const [isVisibleInPage, setIsVisibleInPage] = React.useState(visibleInPage);
  const [isVisibleInGuide, setIsVisibleInGuide] =
    React.useState(visibleInGuide);

  const [isButtonDisabled, setIsButtonDisabled] = React.useState(true);

  React.useEffect(() => {
    setIsButtonDisabled(
      isVisibleInPage === visibleInPage && isVisibleInGuide === visibleInGuide,
    );
  }, [visibleInPage, visibleInGuide, isVisibleInPage, isVisibleInGuide]);

  const handleConfirm = () => {
    onSubmit(isVisibleInGuide, isVisibleInPage);
    onCancel();
  };

  return (
    <ModalBase isOpen onClose={onCancel}>
      <ModalBase.Header
        title={translate('TABLE_OF_CONTENTS_MODAL.MODAL_TITLE')}
      />

      <ModalBase.Content>
        <StyledOptionContainer>
          <StyledGuideStateInfoContainer>
            <StyledGuideStateInfoLabel>
              <Translate id="TABLE_OF_CONTENTS_MODAL.SIDEBAR_TEXT" />
            </StyledGuideStateInfoLabel>
            <p>
              <StyledModalDescription>
                <Translate id="TABLE_OF_CONTENTS_MODAL.SIDEBAR_DESCRIPTION" />
              </StyledModalDescription>
            </p>
          </StyledGuideStateInfoContainer>
          <Switch
            isChecked={isVisibleInGuide}
            onChange={() => setIsVisibleInGuide(!isVisibleInGuide)}
          />
        </StyledOptionContainer>
        <Divider />
        <StyledOptionContainer>
          <StyledGuideStateInfoContainer>
            <StyledGuideStateInfoLabel>
              <Translate id="TABLE_OF_CONTENTS_MODAL.PAGE_TEXT" />
            </StyledGuideStateInfoLabel>
            <p>
              <StyledModalDescription>
                <Translate id="TABLE_OF_CONTENTS_MODAL.PAGE_DESCRIPTION" />
              </StyledModalDescription>
            </p>
          </StyledGuideStateInfoContainer>
          <Switch
            isChecked={isVisibleInPage}
            onChange={() => setIsVisibleInPage(!isVisibleInPage)}
          />
        </StyledOptionContainer>
      </ModalBase.Content>

      <ModalBase.Footer
        actionPrimary={
          <PrimaryButton isDisabled={isButtonDisabled} onClick={handleConfirm}>
            <Translate id="TABLE_OF_CONTENTS_MODAL.SAVE_BUTTON_TEXT" />
          </PrimaryButton>
        }
        actionSecondary={
          <Button variant="secondary" onClick={onCancel}>
            <Translate id="TABLE_OF_CONTENTS_MODAL.CANCEL_BUTTON_TEXT" />
          </Button>
        }
      />
    </ModalBase>
  );
};

export default TableOfContentsModal;

const StyledOptionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
