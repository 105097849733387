import { generateUUID } from '../../helpers/utils';
import { getNewCard } from '../../mutations/widgets/card';
import { WidgetAlignment, ContentAlignment, SectionLayout } from '../../types';
import { BatchCommands } from './batchCommands';
import {
  TemplatesEnum,
  BUTTON_STYLE_HOMEPAGE_TEMPLATE,
  DEFAULT_TEXT_HERO_TEMPLATE,
  DEFAULT_BUTTON_TEXT_HERO_TEMPLATE,
  DEFAULT_BUTTON_STYLE_HERO_TEMPLATE,
  DEFAULT_TEXT_TEXT_TEMPLATE,
  DEFAULT_SECTION_TITLE,
  DEFAULT_BUTTON_STYLE_HERO_SECONDARY_TEMPLATE,
  PAGINATION_LEFT_BUTTON,
  PAGINATION_RIGHT_BUTTON,
  PAGINATION_UP_BUTTON,
  PAGINATION_DOWN_BUTTON,
  HEADLINE_TITLE,
  SUBHEADLINE_TEXT,
  NEW_BUTTON,
  GROUP_A_TEXT,
  GROUP_B_TEXT,
  SQUARE_PAGINATION_LIGHT,
  SQUARE_PAGINATION_DARK,
  ROUNDED_PAGINATION_LIGHT,
  ROUNDED_PAGINATION_DARK,
  SECTION_TITLE,
  CLEAR_BUTTON,
} from './constants';

export const SectionTemplateBuilder = (
  guideId: string,
  chapterId: string,
  pageId: string,
  sectionId: string,
  guideVersion: number,
  sectionTemplate: TemplatesEnum,
  sectionPosition: number,
) => {
  const {
    addNewSectionToPage,
    changeSectionLayout,
    editSectionWidth,
    editSectionHeight,
    editSectionContentAlignment,
    editSectionHorizontalPadding,
    addNewTextWidgetToSection,
    editSectionContentSpacing,
    editSectionColumnSpacing,
    addNewButtonWidgetToSection,
    addNewCardWidgetToSection,
    addNewButtonsToButtonWidget,
    editSectionVerticalPadding,
  } = BatchCommands(guideId, chapterId, pageId, sectionId, guideVersion);

  switch (sectionTemplate) {
    case TemplatesEnum.HERO_TEMPLATE:
      return [
        addNewSectionToPage(sectionPosition),
        editSectionWidth(2048),
        editSectionHeight(600),
        editSectionContentAlignment(ContentAlignment.CENTER),
        addNewTextWidgetToSection(DEFAULT_TEXT_HERO_TEMPLATE),
        addNewButtonWidgetToSection(
          DEFAULT_BUTTON_TEXT_HERO_TEMPLATE,
          DEFAULT_BUTTON_STYLE_HERO_TEMPLATE,
        ),
      ];
    case TemplatesEnum.TWO_CARD_TEMPLATE:
      return [
        addNewSectionToPage(sectionPosition),
        editSectionWidth(2048),
        addNewCardWidgetToSection([
          getNewCard(generateUUID(), 'Title', 'Description'),
          getNewCard(generateUUID(), 'Title', 'Description'),
        ]),
      ];
    case TemplatesEnum.FOUR_CARD_TEMPLATE:
      return [
        addNewSectionToPage(sectionPosition),
        editSectionWidth(2048),
        addNewCardWidgetToSection([
          getNewCard(generateUUID(), 'Title', 'Description'),
          getNewCard(generateUUID(), 'Title', 'Description'),
          getNewCard(generateUUID(), 'Title', 'Description'),
          getNewCard(generateUUID(), 'Title', 'Description'),
        ]),
      ];
    case TemplatesEnum.SIX_CARD_TEMPLATE:
      return [
        addNewSectionToPage(sectionPosition),
        editSectionWidth(1200),
        addNewCardWidgetToSection(
          [
            getNewCard(generateUUID(), 'Title', 'Description'),
            getNewCard(generateUUID(), 'Title', 'Description'),
            getNewCard(generateUUID(), 'Title', 'Description'),
          ],
          0,
        ),
        addNewCardWidgetToSection(
          [
            getNewCard(generateUUID(), 'Title', 'Description'),
            getNewCard(generateUUID(), 'Title', 'Description'),
            getNewCard(generateUUID(), 'Title', 'Description'),
          ],
          0,
        ),
      ];
    case TemplatesEnum.FIVE_CARD_TEMPLATE:
      return [
        addNewSectionToPage(sectionPosition),
        editSectionWidth(1200),
        editSectionContentAlignment(ContentAlignment.CENTER),
        editSectionVerticalPadding(64),
        editSectionHorizontalPadding(64),
        editSectionContentSpacing(16),
        addNewCardWidgetToSection(
          [
            getNewCard(generateUUID(), '01'),
            getNewCard(generateUUID(), '02'),
            getNewCard(generateUUID(), '03'),
            getNewCard(generateUUID(), '04'),
            getNewCard(generateUUID(), '05'),
          ],
          0,
          WidgetAlignment.CENTER,
          sectionId,
          191,
        ),
      ];

    case TemplatesEnum.EIGHT_CARD_TEMPLATE:
      return [
        addNewSectionToPage(sectionPosition),
        editSectionWidth(800),
        editSectionContentAlignment(ContentAlignment.CENTER),
        editSectionVerticalPadding(64),
        editSectionHorizontalPadding(64),
        editSectionContentSpacing(16),
        addNewCardWidgetToSection(
          [
            getNewCard(generateUUID(), '01'),
            getNewCard(generateUUID(), '02'),
            getNewCard(generateUUID(), '03'),
            getNewCard(generateUUID(), '04'),
          ],
          0,
          WidgetAlignment.CENTER,
          sectionId,
          128,
        ),
        addNewCardWidgetToSection(
          [
            getNewCard(generateUUID(), '05'),
            getNewCard(generateUUID(), '06'),
            getNewCard(generateUUID(), '07'),
            getNewCard(generateUUID(), '08'),
          ],
          0,
          WidgetAlignment.CENTER,
          sectionId,
          128,
        ),
      ];

    case TemplatesEnum.TEN_CARD_TEMPLATE:
      return [
        addNewSectionToPage(sectionPosition),
        editSectionWidth(2048),
        editSectionVerticalPadding(64),
        editSectionHorizontalPadding(64),
        editSectionContentSpacing(16),
        addNewCardWidgetToSection(
          [
            getNewCard(),
            getNewCard(),
            getNewCard(),
            getNewCard(),
            getNewCard(),
            getNewCard(),
            getNewCard(),
            getNewCard(),
            getNewCard(),
            getNewCard(),
          ],
          0,
          WidgetAlignment.SPACE_AROUND,
          sectionId,
          128,
        ),
      ];

    case TemplatesEnum.TWO_COLUMN_HERO_TEMPLATE: {
      const widgetId = generateUUID();
      const secondWidgetId = generateUUID();

      return [
        addNewSectionToPage(sectionPosition),
        editSectionWidth(1200),
        editSectionContentAlignment(ContentAlignment.CENTER),
        changeSectionLayout(SectionLayout.DOUBLE_50_50),
        addNewTextWidgetToSection(HEADLINE_TITLE, 0),
        addNewTextWidgetToSection(SUBHEADLINE_TEXT, 0),
        addNewTextWidgetToSection(DEFAULT_TEXT_TEXT_TEMPLATE, 0),
        editSectionColumnSpacing(40),
        editSectionVerticalPadding(64),
        editSectionHorizontalPadding(64),
        editSectionContentSpacing(16),

        addNewTextWidgetToSection(HEADLINE_TITLE, 1),
        addNewTextWidgetToSection(SUBHEADLINE_TEXT, 1),
        addNewTextWidgetToSection(DEFAULT_TEXT_TEXT_TEMPLATE, 1),
        addNewButtonWidgetToSection(
          NEW_BUTTON,
          DEFAULT_BUTTON_STYLE_HERO_TEMPLATE,
          widgetId,
          'justify-content:left;\n',
        ),
        addNewButtonsToButtonWidget(
          NEW_BUTTON,
          DEFAULT_BUTTON_STYLE_HERO_SECONDARY_TEMPLATE,
          widgetId,
        ),

        addNewButtonWidgetToSection(
          NEW_BUTTON,
          DEFAULT_BUTTON_STYLE_HERO_TEMPLATE,
          secondWidgetId,
          'justify-content:left;\n',
          sectionId,
          1,
        ),
        addNewButtonsToButtonWidget(
          NEW_BUTTON,
          DEFAULT_BUTTON_STYLE_HERO_SECONDARY_TEMPLATE,
          secondWidgetId,
          1,
        ),
      ];
    }

    case TemplatesEnum.TWO_COLUMN_RIGHT_HERO_TEMPLATE: {
      const widgetId = generateUUID();

      return [
        addNewSectionToPage(sectionPosition),
        editSectionWidth(1200),
        editSectionContentAlignment(ContentAlignment.CENTER),
        changeSectionLayout(SectionLayout.DOUBLE_50_50),
        addNewTextWidgetToSection(HEADLINE_TITLE, 1),
        addNewTextWidgetToSection(SUBHEADLINE_TEXT, 1),
        addNewTextWidgetToSection(DEFAULT_TEXT_TEXT_TEMPLATE, 1),
        editSectionColumnSpacing(40),
        editSectionVerticalPadding(64),
        editSectionHorizontalPadding(64),
        editSectionContentSpacing(16),
        addNewButtonWidgetToSection(
          NEW_BUTTON,
          DEFAULT_BUTTON_STYLE_HERO_TEMPLATE,
          widgetId,
          'justify-content:left;\n',
          sectionId,
          1,
        ),
        addNewButtonsToButtonWidget(
          NEW_BUTTON,
          DEFAULT_BUTTON_STYLE_HERO_SECONDARY_TEMPLATE,
          widgetId,
          1,
        ),
      ];
    }

    case TemplatesEnum.TWO_COLUMN_LEFT_HERO_TEMPLATE: {
      const widgetId = generateUUID();

      return [
        addNewSectionToPage(sectionPosition),
        editSectionWidth(1200),
        editSectionContentAlignment(ContentAlignment.CENTER),
        changeSectionLayout(SectionLayout.DOUBLE_50_50),
        addNewTextWidgetToSection(HEADLINE_TITLE, 0),
        addNewTextWidgetToSection(SUBHEADLINE_TEXT, 0),
        addNewTextWidgetToSection(DEFAULT_TEXT_TEXT_TEMPLATE, 0),
        editSectionColumnSpacing(40),
        editSectionVerticalPadding(64),
        editSectionHorizontalPadding(64),
        editSectionContentSpacing(16),
        addNewButtonWidgetToSection(
          NEW_BUTTON,
          DEFAULT_BUTTON_STYLE_HERO_TEMPLATE,
          widgetId,
          'justify-content:left;\n',
          sectionId,
          0,
        ),
        addNewButtonsToButtonWidget(
          NEW_BUTTON,
          DEFAULT_BUTTON_STYLE_HERO_SECONDARY_TEMPLATE,
          widgetId,
          0,
        ),
      ];
    }

    case TemplatesEnum.TWO_CARD_LARGE_TEMPLATE:
      return [
        addNewSectionToPage(sectionPosition),
        editSectionContentAlignment(ContentAlignment.CENTER),
        editSectionWidth(1200),
        editSectionVerticalPadding(64),
        editSectionHorizontalPadding(64),
        editSectionContentSpacing(16),
        addNewCardWidgetToSection(
          [
            getNewCard(
              generateUUID(),
              '',
              'An empty card widget ready to deliver unique content experiences.',
            ),
            getNewCard(
              generateUUID(),
              '',
              'An empty card widget ready to deliver unique content experiences.',
            ),
          ],
          0,
          WidgetAlignment.CENTER,
          sectionId,
          480,
        ),
      ];

    case TemplatesEnum.TWO_COLUMN_NAVIGATION_TEMPLATE:
      return [
        addNewSectionToPage(sectionPosition),
        editSectionContentAlignment(ContentAlignment.CENTER),
        editSectionWidth(2048),
        editSectionColumnSpacing(40),
        editSectionVerticalPadding(64),
        editSectionHorizontalPadding(64),
        editSectionContentSpacing(16),
        changeSectionLayout(SectionLayout.DOUBLE_50_50),

        addNewTextWidgetToSection(GROUP_A_TEXT, 0),
        addNewCardWidgetToSection(
          [
            getNewCard(),
            getNewCard(),
            getNewCard(),
            getNewCard(),
            getNewCard(),
          ],
          0,
          WidgetAlignment.CENTER,
          sectionId,
          200,
        ),
        addNewButtonWidgetToSection(
          NEW_BUTTON,
          DEFAULT_BUTTON_STYLE_HERO_TEMPLATE,
          generateUUID(),
          'justify-content:center;\n',
        ),

        addNewTextWidgetToSection(GROUP_B_TEXT, 1),
        addNewCardWidgetToSection(
          [
            getNewCard(),
            getNewCard(),
            getNewCard(),
            getNewCard(),
            getNewCard(),
          ],
          1,
          WidgetAlignment.CENTER,
          sectionId,
          200,
        ),
        addNewButtonWidgetToSection(
          NEW_BUTTON,
          DEFAULT_BUTTON_STYLE_HERO_TEMPLATE,
          generateUUID(),
          'justify-content:center;\n',
          sectionId,
          1,
        ),
      ];

    case TemplatesEnum.THREE_CARD_TEMPLATE:
      return [
        addNewSectionToPage(sectionPosition),
        editSectionContentAlignment(ContentAlignment.CENTER),
        editSectionWidth(2048),
        editSectionVerticalPadding(64),
        editSectionHorizontalPadding(64),
        editSectionContentSpacing(16),
        addNewCardWidgetToSection(
          [getNewCard(), getNewCard(), getNewCard()],
          0,
          WidgetAlignment.SPACE_AROUND,
          sectionId,
          296,
        ),
      ];

    case TemplatesEnum.TWO_COLUMN_CARD_NAVIGATION_TEMPLATE:
      return [
        addNewSectionToPage(sectionPosition),
        editSectionWidth(1200),
        changeSectionLayout(SectionLayout.DOUBLE_66_33),
        editSectionColumnSpacing(40),
        editSectionVerticalPadding(64),
        editSectionHorizontalPadding(64),
        editSectionContentSpacing(16),
        addNewCardWidgetToSection(
          [getNewCard()],
          0,
          WidgetAlignment.CENTER,
          sectionId,
          580,
        ),
        addNewCardWidgetToSection(
          [getNewCard(), getNewCard()],
          1,
          WidgetAlignment.CENTER,
          sectionId,
          256,
        ),
      ];

    case TemplatesEnum.THREE_COLUMN_CARD_NAVIGATION_TEMPLATE:
      return [
        addNewSectionToPage(sectionPosition),
        editSectionWidth(2048),
        changeSectionLayout(SectionLayout.TRIPLE_33_33_33),
        editSectionContentAlignment(ContentAlignment.CENTER),
        editSectionColumnSpacing(40),
        editSectionVerticalPadding(64),
        editSectionHorizontalPadding(64),
        editSectionContentSpacing(16),
        addNewCardWidgetToSection(
          [getNewCard(), getNewCard(), getNewCard()],
          0,
          WidgetAlignment.CENTER,
          sectionId,
          128,
        ),
        addNewCardWidgetToSection(
          [getNewCard()],
          1,
          WidgetAlignment.CENTER,
          sectionId,
          340,
        ),
        addNewCardWidgetToSection(
          [getNewCard(), getNewCard(), getNewCard()],
          2,
          WidgetAlignment.CENTER,
          sectionId,
          128,
        ),
      ];

    case TemplatesEnum.DARK_LEFT_RIGHT_PAGE_NAVIGATION_TEMPLATE: {
      const widgetId = generateUUID();

      return [
        addNewSectionToPage(sectionPosition),
        editSectionWidth(2048),
        editSectionVerticalPadding(64),
        editSectionHorizontalPadding(64),
        editSectionContentSpacing(16),
        addNewButtonWidgetToSection(
          PAGINATION_LEFT_BUTTON,
          DEFAULT_BUTTON_STYLE_HERO_TEMPLATE,
          widgetId,
          'justify-content:center;\n',
        ),
        addNewButtonsToButtonWidget(
          PAGINATION_RIGHT_BUTTON,
          DEFAULT_BUTTON_STYLE_HERO_TEMPLATE,
          widgetId,
        ),
      ];
    }

    case TemplatesEnum.LIGHT_LEFT_RIGHT_PAGE_NAVIGATION_TEMPLATE: {
      const widgetId = generateUUID();

      return [
        addNewSectionToPage(sectionPosition),
        editSectionWidth(2048),
        editSectionVerticalPadding(64),
        editSectionHorizontalPadding(64),
        editSectionContentSpacing(16),
        addNewButtonWidgetToSection(
          PAGINATION_LEFT_BUTTON,
          DEFAULT_BUTTON_STYLE_HERO_SECONDARY_TEMPLATE,
          widgetId,
          'justify-content:center;\n',
        ),
        addNewButtonsToButtonWidget(
          PAGINATION_RIGHT_BUTTON,
          DEFAULT_BUTTON_STYLE_HERO_SECONDARY_TEMPLATE,
          widgetId,
        ),
      ];
    }

    case TemplatesEnum.DARK_UP_DOWN_PAGE_NAVIGATION_TEMPLATE: {
      const widgetId = generateUUID();

      return [
        addNewSectionToPage(sectionPosition),
        editSectionWidth(2048),
        editSectionVerticalPadding(64),
        editSectionHorizontalPadding(64),
        editSectionContentSpacing(16),
        addNewButtonWidgetToSection(
          PAGINATION_UP_BUTTON,
          BUTTON_STYLE_HOMEPAGE_TEMPLATE,
          widgetId,
          'justify-content:center;\n',
        ),
        addNewButtonsToButtonWidget(
          PAGINATION_DOWN_BUTTON,
          BUTTON_STYLE_HOMEPAGE_TEMPLATE,
          widgetId,
        ),
      ];
    }

    case TemplatesEnum.LIGHT_UP_DOWN_PAGE_NAVIGATION_TEMPLATE: {
      const widgetId = generateUUID();

      return [
        addNewSectionToPage(sectionPosition),
        editSectionWidth(2048),
        editSectionVerticalPadding(64),
        editSectionHorizontalPadding(64),
        editSectionContentSpacing(16),
        addNewButtonWidgetToSection(
          PAGINATION_UP_BUTTON,
          DEFAULT_BUTTON_STYLE_HERO_SECONDARY_TEMPLATE,
          widgetId,
          'justify-content:center;\n',
        ),
        addNewButtonsToButtonWidget(
          PAGINATION_DOWN_BUTTON,
          DEFAULT_BUTTON_STYLE_HERO_SECONDARY_TEMPLATE,
          widgetId,
        ),
      ];
    }

    case TemplatesEnum.DARK_SQUARE_PAGINATION_TEMPLATE: {
      const widgetId = generateUUID();

      return [
        addNewSectionToPage(sectionPosition),
        editSectionWidth(2048),
        editSectionVerticalPadding(64),
        editSectionHorizontalPadding(64),
        editSectionContentSpacing(16),
        addNewButtonWidgetToSection(
          '1',
          SQUARE_PAGINATION_LIGHT,
          widgetId,
          'justify-content:center;\ngap:0px;\n',
        ),
        addNewButtonsToButtonWidget('2', SQUARE_PAGINATION_DARK, widgetId),
        addNewButtonsToButtonWidget('3', SQUARE_PAGINATION_DARK, widgetId),
        addNewButtonsToButtonWidget('4', SQUARE_PAGINATION_DARK, widgetId),
        addNewButtonsToButtonWidget('5', SQUARE_PAGINATION_DARK, widgetId),
        addNewButtonsToButtonWidget('6', SQUARE_PAGINATION_DARK, widgetId),
      ];
    }

    case TemplatesEnum.LIGHT_SQUARE_PAGINATION_TEMPLATE: {
      const widgetId = generateUUID();

      return [
        addNewSectionToPage(sectionPosition),
        editSectionWidth(2048),
        editSectionVerticalPadding(64),
        editSectionHorizontalPadding(64),
        editSectionContentSpacing(16),
        addNewButtonWidgetToSection(
          '1',
          SQUARE_PAGINATION_DARK,
          widgetId,
          'justify-content:center;\ngap:0px;\n',
        ),
        addNewButtonsToButtonWidget('2', SQUARE_PAGINATION_LIGHT, widgetId),
        addNewButtonsToButtonWidget('3', SQUARE_PAGINATION_LIGHT, widgetId),
        addNewButtonsToButtonWidget('4', SQUARE_PAGINATION_LIGHT, widgetId),
        addNewButtonsToButtonWidget('5', SQUARE_PAGINATION_LIGHT, widgetId),
        addNewButtonsToButtonWidget('6', SQUARE_PAGINATION_LIGHT, widgetId),
      ];
    }

    case TemplatesEnum.DARK_ROUND_PAGINATION_TEMPLATE: {
      const widgetId = generateUUID();

      return [
        addNewSectionToPage(sectionPosition),
        editSectionWidth(2048),
        editSectionVerticalPadding(64),
        editSectionHorizontalPadding(64),
        editSectionContentSpacing(16),
        addNewButtonWidgetToSection(
          '1',
          ROUNDED_PAGINATION_LIGHT,
          widgetId,
          'justify-content:center;\ngap:12px;\n',
        ),
        addNewButtonsToButtonWidget('2', ROUNDED_PAGINATION_DARK, widgetId),
        addNewButtonsToButtonWidget('3', ROUNDED_PAGINATION_DARK, widgetId),
        addNewButtonsToButtonWidget('4', ROUNDED_PAGINATION_DARK, widgetId),
        addNewButtonsToButtonWidget('5', ROUNDED_PAGINATION_DARK, widgetId),
        addNewButtonsToButtonWidget('6', ROUNDED_PAGINATION_DARK, widgetId),
      ];
    }

    case TemplatesEnum.LIGHT_ROUND_PAGINATION_TEMPLATE: {
      const widgetId = generateUUID();

      return [
        addNewSectionToPage(sectionPosition),
        editSectionWidth(2048),
        editSectionVerticalPadding(64),
        editSectionHorizontalPadding(64),
        editSectionContentSpacing(16),
        addNewButtonWidgetToSection(
          '1',
          ROUNDED_PAGINATION_DARK,
          widgetId,
          'justify-content:center;\ngap:12px;\n',
        ),
        addNewButtonsToButtonWidget('2', ROUNDED_PAGINATION_LIGHT, widgetId),
        addNewButtonsToButtonWidget('3', ROUNDED_PAGINATION_LIGHT, widgetId),
        addNewButtonsToButtonWidget('4', ROUNDED_PAGINATION_LIGHT, widgetId),
        addNewButtonsToButtonWidget('5', ROUNDED_PAGINATION_LIGHT, widgetId),
        addNewButtonsToButtonWidget('6', ROUNDED_PAGINATION_LIGHT, widgetId),
      ];
    }

    case TemplatesEnum.FOOTER_TEXT_TEMPLATE:
      return [
        addNewSectionToPage(sectionPosition),
        editSectionWidth(2048),
        editSectionColumnSpacing(20),
        editSectionVerticalPadding(64),
        editSectionHorizontalPadding(64),
        editSectionContentSpacing(16),
        changeSectionLayout(SectionLayout.TRIPLE_33_33_33),

        addNewTextWidgetToSection(SECTION_TITLE, 0),
        addNewButtonWidgetToSection(NEW_BUTTON, CLEAR_BUTTON),
        addNewButtonWidgetToSection(NEW_BUTTON, CLEAR_BUTTON),
        addNewButtonWidgetToSection(NEW_BUTTON, CLEAR_BUTTON),
        addNewButtonWidgetToSection(NEW_BUTTON, CLEAR_BUTTON),

        addNewTextWidgetToSection(SECTION_TITLE, 1),
        addNewButtonWidgetToSection(
          NEW_BUTTON,
          CLEAR_BUTTON,
          generateUUID(),
          '',
          sectionId,
          1,
        ),
        addNewButtonWidgetToSection(
          NEW_BUTTON,
          CLEAR_BUTTON,
          generateUUID(),
          '',
          sectionId,
          1,
        ),
        addNewButtonWidgetToSection(
          NEW_BUTTON,
          CLEAR_BUTTON,
          generateUUID(),
          '',
          sectionId,
          1,
        ),
        addNewButtonWidgetToSection(
          NEW_BUTTON,
          CLEAR_BUTTON,
          generateUUID(),
          '',
          sectionId,
          1,
        ),

        addNewTextWidgetToSection(SECTION_TITLE, 2),
        addNewButtonWidgetToSection(
          NEW_BUTTON,
          CLEAR_BUTTON,
          generateUUID(),
          '',
          sectionId,
          2,
        ),
        addNewButtonWidgetToSection(
          NEW_BUTTON,
          CLEAR_BUTTON,
          generateUUID(),
          '',
          sectionId,
          2,
        ),
        addNewButtonWidgetToSection(
          NEW_BUTTON,
          CLEAR_BUTTON,
          generateUUID(),
          '',
          sectionId,
          2,
        ),
        addNewButtonWidgetToSection(
          NEW_BUTTON,
          CLEAR_BUTTON,
          generateUUID(),
          '',
          sectionId,
          2,
        ),
      ];

    case TemplatesEnum.TEXT_TEMPLATE:
      return [
        addNewSectionToPage(sectionPosition),
        editSectionWidth(800),
        changeSectionLayout(SectionLayout.TRIPLE_33_33_33),
        addNewTextWidgetToSection(DEFAULT_TEXT_TEXT_TEMPLATE, 0),
        addNewTextWidgetToSection(DEFAULT_TEXT_TEXT_TEMPLATE, 1),
        addNewTextWidgetToSection(DEFAULT_TEXT_TEXT_TEMPLATE, 2),
        editSectionHorizontalPadding(16),
        editSectionContentSpacing(8),
        editSectionColumnSpacing(20),
      ];
    case TemplatesEnum.TWO_COLUMN_FEATURED_TEMPLATE:
      return [
        addNewSectionToPage(sectionPosition),
        editSectionWidth(800),
        changeSectionLayout(SectionLayout.DOUBLE_50_50),
        addNewTextWidgetToSection(DEFAULT_SECTION_TITLE, 0),
        addNewTextWidgetToSection(DEFAULT_TEXT_TEXT_TEMPLATE, 0),
        addNewCardWidgetToSection([getNewCard()], 1, WidgetAlignment.LEFT),
      ];
    case TemplatesEnum.HERO_WITH_IMAGE_TEMPLATE: {
      const widgetId = generateUUID();

      return [
        addNewSectionToPage(sectionPosition),
        editSectionWidth(800),
        changeSectionLayout(SectionLayout.DOUBLE_50_50),
        editSectionContentAlignment(ContentAlignment.CENTER),
        addNewTextWidgetToSection(DEFAULT_SECTION_TITLE, 0),
        addNewTextWidgetToSection(DEFAULT_TEXT_TEXT_TEMPLATE, 0),
        addNewButtonWidgetToSection(
          DEFAULT_BUTTON_TEXT_HERO_TEMPLATE,
          DEFAULT_BUTTON_STYLE_HERO_TEMPLATE,
          widgetId,
          'justify-content:left;\n',
        ),
        addNewButtonsToButtonWidget(
          DEFAULT_BUTTON_TEXT_HERO_TEMPLATE,
          DEFAULT_BUTTON_STYLE_HERO_SECONDARY_TEMPLATE,
          widgetId,
        ),
        addNewCardWidgetToSection([getNewCard()], 1, WidgetAlignment.LEFT),
      ];
    }
    case TemplatesEnum.FOOTER_WITH_IMAGE_TEMPLATE:
      return [
        addNewSectionToPage(sectionPosition),
        editSectionWidth(800),
        changeSectionLayout(SectionLayout.TRIPLE_33_33_33),
        addNewTextWidgetToSection(DEFAULT_TEXT_TEXT_TEMPLATE, 0),
        addNewCardWidgetToSection([getNewCard()], 1),
        addNewTextWidgetToSection(DEFAULT_TEXT_TEXT_TEMPLATE, 2),
      ];
  }
};
