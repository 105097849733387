import React from 'react';
import { useTranslate } from '@bynder/localization';

import { ThemesetDetails, Themeset } from '../../../types';
import ThemesetDetailsModal from './ThemesetDetailsModal';

export type EditThemesetDetailsModalProps = {
  themeset: Themeset;
  onCancel: () => void;
  onSubmit: (themesetDetails: ThemesetDetails) => void;
};

const EditThemesetDetailsModal: React.FC<EditThemesetDetailsModalProps> = ({
  themeset,
  onCancel,
  onSubmit,
}) => {
  const { translate } = useTranslate();

  return (
    <ThemesetDetailsModal
      themeset={themeset}
      onCancel={onCancel}
      onSubmit={onSubmit}
      primaryButtonCopy={translate(
        'EDIT_THEMESET_DETAILS_MODAL_CONTENT.SAVE_CHANGES_BUTTON',
      )}
    />
  );
};

export default EditThemesetDetailsModal;
