import styled from 'styled-components';
import { token } from '@bynder/design-system';
import { skeletonAnimation } from '../../../constants/styles';

interface Props {
  width?: number;
  height?: number;
  borderRadius?: number;
}

export default styled.span<Props>`
  display: inline-block;
  background-color: ${token.gray75a};
  border-radius: ${(props: Props) => props.borderRadius || 5}px;
  height: ${(props: Props) => props.height || 18}px;
  width: ${(props: Props) => props.width || 128}px;
  ${skeletonAnimation}
`;
