import { CONFIG } from '../../constants/app';
import axios from '../axios';

export const retrieveFeatureTreatment = async (
  splitName: string,
  isPublic: boolean,
): Promise<string> => {
  const url = `${CONFIG.ACCOUNT_BASE_ROUTE}/v7/featuretreatments/treatment/${splitName}`;
  const publicUrl = `${CONFIG.ACCOUNT_BASE_ROUTE}/v7/featuretreatments_public/treatment/${splitName}`;
  const { data } = await (
    await axios()
  ).get<{ treatment: string }>(isPublic ? publicUrl : url, {
    headers: {
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
    },
  });

  return data.treatment;
};
