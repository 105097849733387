import { useMutation } from '@tanstack/react-query';
import {
  setHomepagePortalApi,
  unsetHomepagePortalApi,
} from '../helpers/api/portalApi';
import { useModalStore } from '../stores/modalStore';
import { handleMutationSuccess } from './helpers';
import { setHomepageApi, unsetHomepageApi } from '../helpers/api/guidelinesApi';
import { useGetCurrentHomepage } from '../queries/homepage';

export const useHomepageMutations = () => {
  const closeModal = useModalStore.use.closeModal();
  const { data: currentHomepage } = useGetCurrentHomepage();

  const setHomepageCommand = useMutation(
    (mutationOptions: { guideId: string; guideVersion: number }) =>
      setHomepageApi(
        currentHomepage,
        mutationOptions.guideId,
        mutationOptions.guideVersion,
      ),
    {
      onMutate: () => {
        closeModal();
      },
      onSuccess: (data, variables) => {
        handleMutationSuccess('Landing page set as homepage', data, {
          entityId: variables.guideId,
        });
      },
    },
  );

  const setHomepage = useMutation(
    (mutationOptions: { guideId: string; guideVersion: number }) =>
      setHomepagePortalApi(mutationOptions.guideId),
    {
      onMutate: () => {
        closeModal();
      },
      onSuccess: (_, variables) => {
        setHomepageCommand.mutate({
          guideId: variables.guideId,
          guideVersion: variables.guideVersion,
        });
      },
    },
  );

  const unsetHomepage = useMutation((
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _: { guideId: string; guideVersion: number },
  ) => unsetHomepagePortalApi(), {
    onSuccess: (_, variables) => {
      unsetHomepageCommand.mutate({
        guideId: variables.guideId,
        guideVersion: variables.guideVersion,
      });
    },
  });

  const unsetHomepageCommand = useMutation(
    (mutationOptions: { guideId: string; guideVersion: number }) =>
      unsetHomepageApi(mutationOptions.guideId, mutationOptions.guideVersion),
    {
      onSuccess: (data, variables) => {
        handleMutationSuccess('Landing page unset as homepage', data, {
          entityId: variables.guideId,
        });
      },
    },
  );

  return { setHomepageCommand, setHomepage, unsetHomepage };
};
