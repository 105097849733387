import React from 'react';
import styled from 'styled-components';
import { Checkbox, Form, Input, ModalBase } from '@bynder/design-system';
import { Translate } from '@bynder/localization';
import PrimaryButton from '../shared/PrimaryButton';

export interface ImageWidgetCustomUrlModalProps {
  onCancel: () => void;
  onSubmit: (url: string, openNewTab: boolean) => void;
  headerText?: string;
  labelText?: string;
  placeholderText?: string;
  buttonLabel?: string;
  initialUrl?: string;
  initialOpenNewTab?: boolean;
}

const ImageWidgetCustomUrlModal: React.FC<ImageWidgetCustomUrlModalProps> = ({
  onCancel,
  onSubmit,
  headerText,
  labelText,
  placeholderText,
  buttonLabel,
  initialUrl = '',
  initialOpenNewTab = false,
}) => {
  const [url, setUrl] = React.useState(initialUrl);
  const [openNewTab, setOpenNewTab] = React.useState(initialOpenNewTab);
  const inputRef = React.useRef<HTMLInputElement | null>(null);

  React.useEffect(() => {
    if (inputRef?.current) {
      inputRef?.current?.focus();
    }
  }, []);

  const areOptionsSameAsInitial =
    url === initialUrl && openNewTab === initialOpenNewTab;

  return (
    <ModalBase isClosedOnOverlayClick isOpen onClose={onCancel}>
      <ModalBase.Header title={headerText} />
      <ModalBase.Content>
        <Form.Group>
          <>
            <StyledLabel>
              <Form.Label htmlFor="modalInput">{labelText}</Form.Label>
            </StyledLabel>
            <Input
              inputRef={inputRef}
              id="modalInput"
              value={url}
              onChange={setUrl}
              data-testid="modalInput"
              placeholder={placeholderText}
              hasClearButton
            />
            <StyledCheckbox>
              <Checkbox
                isChecked={openNewTab}
                onChange={() => setOpenNewTab(!openNewTab)}
              >
                <Translate id="IMAGE_WIDGET_CUSTOM_URL_MODAL.OPEN_NEW_TAB" />
              </Checkbox>
            </StyledCheckbox>
          </>
        </Form.Group>
      </ModalBase.Content>
      <ModalBase.Footer
        actionPrimary={
          <PrimaryButton
            data-testid="submitButton"
            isDisabled={url === '' || areOptionsSameAsInitial}
            onClick={() => onSubmit(url, openNewTab)}
          >
            {buttonLabel}
          </PrimaryButton>
        }
      />
    </ModalBase>
  );
};

const StyledLabel = styled.div`
  margin-bottom: 20px;
`;

const StyledCheckbox = styled.div`
  margin-top: 20px;
`;

export default ImageWidgetCustomUrlModal;
