import { useQuery } from '@tanstack/react-query';
import _, { union } from 'lodash';
import { DefaultTheme, useTheme } from 'styled-components';
import {
  fetchAssignedThemeset,
  fetchFontsApi,
  fetchJSONThemeApi,
  fetchPortalInformationApi,
  fetchThemesetAssignationApi,
  fetchThemesetsApi,
} from '../helpers/api/customizationApi';
import { ThemesetCategory, Themeset } from '../types';
import defaultTheme from '../themes/default.json';
import defaultFonts from '../themes/defaultFonts.json';
import { Font, defaultBynderThemeset } from '../constants/themesets';
import { Typography, TypographyHeading } from '../types/typography';

export const THEMESET_KEYS = {
  THEMESETS: 'THEMESETS',
  THEME: 'THEME',
  FONTS: 'FONTS',
  ASSIGNATIONS: 'ASSIGNATIONS',
  PORTAL: 'PORTAL',
};

export const useThemesetByGuideId = (guideId: string) => {
  return useQuery<Themeset>(
    [THEMESET_KEYS.THEMESETS, guideId],
    () => fetchAssignedThemeset(guideId),
    {
      enabled: !!guideId,
    },
  );
};

export const useThemesetById = (themesetId: string) => {
  return useQuery(
    [THEMESET_KEYS.THEMESETS, themesetId],
    () => fetchThemesetsApi(),
    {
      select: themesets => themesets?.find(({ id }) => id === themesetId),
    },
  );
};

const useThemesetAssignations = () => {
  return useQuery(
    [THEMESET_KEYS.THEMESETS, THEMESET_KEYS.ASSIGNATIONS],
    () => fetchThemesetAssignationApi(),
    {
      select: data => _.invertBy(data),
    },
  );
};

export const useGetThemesetColors = () => {
  const { themeset } = useTheme();

  return themeset?.colorSets[0].colors || [];
};

const useGetThemesetTypographies = () => {
  const { themeset } = useTheme();

  return themeset
    ? normalizeTypographies(themeset.typographies)
    : normalizeTypographies(defaultBynderThemeset.typographies);
};

export const useThemesets = () => {
  return useQuery<Themeset[]>(
    [THEMESET_KEYS.THEMESETS],
    () => fetchThemesetsApi(),
    {
      select: themesets => {
        const firstThemeset = themesets?.find(
          themeset => themeset.category === ThemesetCategory.BYNDER_THEME_SET,
        );

        const sortedThemesets = themesets
          ?.sort(
            (themesetA, themesetB) =>
              new Date(themesetA.creationTime).getTime() -
              new Date(themesetB.creationTime).getTime(),
          )
          .filter(
            themeset => themeset.category !== ThemesetCategory.BYNDER_THEME_SET,
          );

        return [firstThemeset, ...sortedThemesets];
      },
    },
  );
};

export const useDefaultThemeset = () => {
  const { data: themesets, isLoading } = useThemesets();

  if (!isLoading)
    return (
      themesets?.find(themeset => themeset.isDefault) || defaultBynderThemeset
    );
};

export const useGuideThemesetId = (guideId: string) => {
  const defaultThemeset = useDefaultThemeset();

  return useQuery(
    [THEMESET_KEYS.ASSIGNATIONS],
    () => fetchThemesetAssignationApi(),
    {
      enabled: !!guideId,
      select: assignations =>
        _.findKey(assignations, assignation =>
          assignation.find(id => id === guideId),
        ) || defaultThemeset?.id,
    },
  );
};

export const useGetTheme = () => {
  const DEFAULT_THEME = defaultTheme;

  return useQuery([THEMESET_KEYS.THEME], () => fetchJSONThemeApi(), {
    placeholderData: DEFAULT_THEME,
  });
};

export const useGetFonts = () => {
  const DEFAULT_FONTS = defaultFonts;
  const { data: theme } = useGetTheme();

  return useQuery([THEMESET_KEYS.FONTS], () => fetchFontsApi(), {
    placeholderData: DEFAULT_FONTS,
    select: fonts => {
      const fontsFromTheme = getFontsFromTheme(theme);
      const availableFonts: Font[] = fonts.filter((font: Font) =>
        fontsFromTheme.includes(font.label),
      );

      // Place default font in front of the rest of the fonts.
      defaultFonts.forEach((defaultFont: Font) => {
        if (
          !availableFonts.some(
            (availableFont: Font) => availableFont.label === defaultFont.label,
          )
        ) {
          availableFonts.unshift(defaultFont);
        }
      });

      return availableFonts;
    },
  });
};

export const useGetAvailableFontStyles = (fontFamily: string) => {
  const { data: availableFonts } = useGetFonts();

  return (
    availableFonts
      .find((f: Font) => f.label === fontFamily)
      ?.styles.map(style => style.label) || []
  );
};

export const useGetAvailableFontFamilies = () => {
  const { data: availableFonts } = useGetFonts();

  return availableFonts.map(font => font.label);
};

export const getFontsFromTheme = (theme: DefaultTheme) => {
  const { fontBold, fontNormal, assignedFonts = [] } = theme;

  return union([fontNormal.fontFamily, fontBold.fontFamily, ...assignedFonts]);
};

const useGetPortalInformation = () => {
  return useQuery([THEMESET_KEYS.PORTAL], () => fetchPortalInformationApi());
};

export const useThemesetProperties = () => ({
  getThemesetColorById: (colorId: string) => {
    const themesetColors = themesetQueries.getThemesetColors();

    return themesetColors?.find(c => c.id === colorId);
  },
});

const themesetQueries = {
  fetchTheme: useGetTheme,
  fetchFonts: useGetFonts,
  fetchThemesets: useThemesets,
  fetchThemesetAssignations: useThemesetAssignations,
  getThemesetColors: useGetThemesetColors,
  getThemesetTypographies: useGetThemesetTypographies,
  fetchPortalInformation: useGetPortalInformation,
};

export default themesetQueries;

const normalizeTypographies = (typoCollection: Typography[]) => {
  return typoCollection?.reduce(
    (acc, el) => {
      acc[el.heading] = el;

      return acc;
    },
    {} as { [key in TypographyHeading]: Typography },
  );
};
