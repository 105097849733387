/* eslint-disable prefer-rest-params */
import { AxiosResponse } from 'axios';
import {
  DEFAULT_CARD_WIDGET_ALIGNMENT,
  DEFAULT_CARD_WIDGET_CARD_SIZE,
  DEFAULT_CARD_WIDGET_CSS_PROPERTIES,
} from '../../../components/widgets/helpers';
import {
  CardWidgetCard,
  SectionBackground,
  WidgetAlignment,
} from '../../../types';
import axios, { handleUnauthorized } from '../../axios';
import { API_ROUTES } from '../../../constants/app';
import { Command } from '../../../types/commands';
import { getNewCard } from '../../../mutations/widgets/card';
import { emptyCSSObject } from '../../../constants/widgets';

export async function addNewCardWidgetToSectionApi({
  guideId,
  chapterId,
  pageId,
  sectionId,
  widgetId,
  columnIndex,
  guideVersion,
  cardId,
}: {
  guideId: string;
  chapterId: string;
  pageId: string;
  sectionId: string;
  widgetId: string;
  columnIndex: number;
  guideVersion: number;
  cardId: string;
}): Promise<AxiosResponse> {
  return (await axios())
    .post(API_ROUTES.GUIDES_WRITE, {
      command: Command.ADD_NEW_CARD_WIDGET_TO_SECTION,
      aggregateId: guideId,
      aggregateVersion: guideVersion,
      payload: {
        chapterId,
        pageId,
        sectionId,
        columnIndex,
        widgetId,
        cardWidgetPayload: {
          cardPayloads: [getNewCard(cardId)],
          style: {
            size: DEFAULT_CARD_WIDGET_CARD_SIZE,
            alignment: DEFAULT_CARD_WIDGET_ALIGNMENT,
          },
          widgetStyle: {
            ...emptyCSSObject,
            stylesheet: DEFAULT_CARD_WIDGET_CSS_PROPERTIES,
          },
        },
      },
    })
    .catch(error =>
      handleUnauthorized(error, addNewCardWidgetToSectionApi, ...arguments),
    );
}
export async function addCardToCardWidgetApi({
  guideId,
  chapterId,
  pageId,
  sectionId,
  widgetId,
  columnIndex,
  guideVersion,
  newCard,
}: {
  guideId: string;
  chapterId: string;
  pageId: string;
  sectionId: string;
  widgetId: string;
  columnIndex: number;
  guideVersion: number;
  newCard: CardWidgetCard;
}): Promise<AxiosResponse> {
  return (await axios())
    .post(API_ROUTES.GUIDES_WRITE, {
      command: Command.ADD_NEW_CARDS_TO_CARD_WIDGET,
      aggregateId: guideId,
      aggregateVersion: guideVersion,
      payload: {
        chapterId,
        pageId,
        sectionId,
        columnIndex,
        widgetId,
        cardWidgetPayload: { cardPayloads: [newCard] },
      },
    })
    .catch(error =>
      handleUnauthorized(error, addCardToCardWidgetApi, ...arguments),
    );
}
export async function removeCardFromCardWidgetApi({
  guideId,
  chapterId,
  pageId,
  sectionId,
  widgetId,
  columnIndex,
  guideVersion,
  cardId,
}: {
  guideId: string;
  chapterId: string;
  pageId: string;
  sectionId: string;
  widgetId: string;
  columnIndex: number;
  guideVersion: number;
  cardId: string;
}): Promise<AxiosResponse> {
  return (await axios())
    .post(API_ROUTES.GUIDES_WRITE, {
      command: Command.REMOVE_CARD_FROM_CARD_WIDGET,
      aggregateId: guideId,
      aggregateVersion: guideVersion,
      payload: {
        chapterId,
        pageId,
        sectionId,
        columnIndex,
        widgetId,
        cardWidgetPayload: { cardPayload: { id: cardId } },
      },
    })
    .catch(error =>
      handleUnauthorized(error, removeCardFromCardWidgetApi, ...arguments),
    );
}
export async function editCardHeadlineInCardWidgetApi({
  guideId,
  chapterId,
  pageId,
  sectionId,
  widgetId,
  columnIndex,
  guideVersion,
  cardId,
  headline,
}: {
  guideId: string;
  chapterId: string;
  pageId: string;
  sectionId: string;
  widgetId: string;
  columnIndex: number;
  guideVersion: number;
  cardId: string;
  headline: { title: string; description: string };
}): Promise<AxiosResponse> {
  return (await axios())
    .post(API_ROUTES.GUIDES_WRITE, {
      command: Command.EDIT_CARD_HEADLINE_IN_CARD_WIDGET,
      aggregateId: guideId,
      aggregateVersion: guideVersion,
      payload: {
        chapterId,
        pageId,
        sectionId,
        columnIndex,
        widgetId,
        cardWidgetPayload: { cardPayload: { id: cardId, headline } },
      },
    })
    .catch(error =>
      handleUnauthorized(error, editCardHeadlineInCardWidgetApi, ...arguments),
    );
}
export async function editCardLinkInCardWidgetApi({
  guideId,
  chapterId,
  pageId,
  sectionId,
  widgetId,
  columnIndex,
  guideVersion,
  cardId,
  link,
}: {
  guideId: string;
  chapterId: string;
  pageId: string;
  sectionId: string;
  widgetId: string;
  columnIndex: number;
  guideVersion: number;
  cardId: string;
  link: { url: string; openNewTab: boolean };
}): Promise<AxiosResponse> {
  return (await axios())
    .post(API_ROUTES.GUIDES_WRITE, {
      command: Command.EDIT_CARD_LINK_IN_CARD_WIDGET,
      aggregateId: guideId,
      aggregateVersion: guideVersion,
      payload: {
        chapterId,
        pageId,
        sectionId,
        columnIndex,
        widgetId,
        cardWidgetPayload: { cardPayload: { id: cardId, link } },
      },
    })
    .catch(error =>
      handleUnauthorized(error, editCardLinkInCardWidgetApi, ...arguments),
    );
}

export async function editCardAssetInCardWidgetApi({
  guideId,
  chapterId,
  pageId,
  sectionId,
  widgetId,
  columnIndex,
  guideVersion,
  cardId,
  asset,
}: {
  guideId: string;
  chapterId: string;
  pageId: string;
  sectionId: string;
  widgetId: string;
  columnIndex: number;
  guideVersion: number;
  cardId: string;
  asset: SectionBackground;
}): Promise<AxiosResponse> {
  return (await axios())
    .post(API_ROUTES.GUIDES_WRITE, {
      command: Command.EDIT_CARD_ASSET_IN_CARD_WIDGET,
      aggregateId: guideId,
      aggregateVersion: guideVersion,
      payload: {
        chapterId,
        pageId,
        sectionId,
        columnIndex,
        widgetId,
        cardWidgetPayload: { cardPayload: { id: cardId, asset } },
      },
    })
    .catch(error =>
      handleUnauthorized(error, editCardAssetInCardWidgetApi, ...arguments),
    );
}
export async function moveCardInCardWidgetApi({
  guideId,
  chapterId,
  pageId,
  sectionId,
  widgetId,
  columnIndex,
  guideVersion,
  cardId,
  from,
  to,
}: {
  guideId: string;
  chapterId: string;
  pageId: string;
  sectionId: string;
  widgetId: string;
  columnIndex: number;
  guideVersion: number;
  cardId: string;
  from: { position: number };
  to: { position: number };
}): Promise<AxiosResponse> {
  return (await axios())
    .post(API_ROUTES.GUIDES_WRITE, {
      command: Command.MOVE_CARD_IN_CARD_WIDGET,
      aggregateId: guideId,
      aggregateVersion: guideVersion,
      payload: {
        chapterId,
        pageId,
        sectionId,
        columnIndex,
        widgetId,
        cardWidgetPayload: { cardPayload: { id: cardId, from, to } },
      },
    })
    .catch(error =>
      handleUnauthorized(error, moveCardInCardWidgetApi, ...arguments),
    );
}

export async function editWidgetStyleInCardWidgetApi({
  guideId,
  chapterId,
  pageId,
  sectionId,
  widgetId,
  columnIndex,
  guideVersion,
  stylesheet,
}: {
  guideId: string;
  chapterId: string;
  pageId: string;
  sectionId: string;
  widgetId: string;
  columnIndex: number;
  guideVersion: number;
  stylesheet: string;
}) {
  return (await axios())
    .post(API_ROUTES.GUIDES_WRITE, {
      command: Command.EDIT_WIDGET_STYLE_IN_CARD_WIDGET,
      aggregateId: guideId,
      aggregateVersion: guideVersion,
      payload: {
        chapterId,
        pageId,
        sectionId,
        columnIndex,
        widgetId,
        cardWidgetPayload: {
          widgetStyle: {
            ...emptyCSSObject,
            stylesheet,
          },
        },
      },
    })
    .catch(error =>
      handleUnauthorized(error, editStyleInCardWidgetApi, ...arguments),
    );
}
export async function editStyleInCardWidgetApi({
  guideId,
  chapterId,
  pageId,
  sectionId,
  widgetId,
  columnIndex,
  guideVersion,
  style,
}: {
  guideId: string;
  chapterId: string;
  pageId: string;
  sectionId: string;
  widgetId: string;
  columnIndex: number;
  guideVersion: number;
  style: { size: number; alignment: WidgetAlignment };
}): Promise<AxiosResponse> {
  return (await axios())
    .post(API_ROUTES.GUIDES_WRITE, {
      command: Command.EDIT_STYLE_IN_CARD_WIDGET,
      aggregateId: guideId,
      aggregateVersion: guideVersion,
      payload: {
        chapterId,
        pageId,
        sectionId,
        columnIndex,
        widgetId,
        cardWidgetPayload: { style },
      },
    })
    .catch(error =>
      handleUnauthorized(error, editStyleInCardWidgetApi, ...arguments),
    );
}
