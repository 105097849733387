import { AxiosResponse } from 'axios';
import { API_ROUTES, ROUTES } from '../../constants/app';
import { EntityVisibility } from '../../types';
import { Command } from '../../types/commands';
import axios, { handleUnauthorized } from '../axios';
import { LegacyModificationGroup } from '../permissions';

export async function fetchEntityPermissionsApi(
  { entityId, depth = 1, isNewService = false },

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> {
  return (await axios())
    .get(ROUTES.ENTITY_PEMISSION_WITH_DEPTH(entityId, depth, isNewService))
    .then(res => res.data[0])
    .catch(error => handleUnauthorized(error, fetchEntityPermissionsApi));
}

export async function editGuideAccessApi(
  guideId: string,
  viewAccessChanges: LegacyModificationGroup,
  editAccessChanges: LegacyModificationGroup,
  guideVisibility: EntityVisibility,
  guideVersion: number,
): Promise<AxiosResponse> {
  return (await axios())
    .post(API_ROUTES.GUIDES_WRITE, {
      command: Command.EDIT_GUIDE_ACCESS,
      aggregateId: guideId,
      aggregateVersion: guideVersion,
      payload: {
        viewAccessChanges,
        editAccessChanges,
        guideVisibility,
      },
    })
    .catch(error => handleUnauthorized(error, editGuideAccessApi));
}

export async function editGroupAccessApi(
  groupId: string,
  viewAccessChanges: LegacyModificationGroup,
  editAccessChanges: LegacyModificationGroup,
  groupVisibility: EntityVisibility,
  groupVersion: number,
): Promise<AxiosResponse> {
  return (await axios())
    .post(API_ROUTES.GUIDES_WRITE, {
      command: Command.EDIT_GROUP_ACCESS,
      aggregateId: groupId,
      aggregateVersion: groupVersion,
      payload: {
        viewAccessChanges,
        editAccessChanges,
        groupVisibility,
      },
    })
    .catch(error => handleUnauthorized(error, editGroupAccessApi));
}

export async function editPageAccessApi(
  guideId: string,
  chapterId: string,
  pageId: string,
  viewAccessChanges: LegacyModificationGroup,
  editAccessChanges: LegacyModificationGroup,
  pageVisibility: EntityVisibility,
  guideVersion: number,
): Promise<AxiosResponse> {
  return (await axios())
    .post(API_ROUTES.GUIDES_WRITE, {
      command: Command.EDIT_PAGE_ACCESS,
      aggregateId: guideId,
      aggregateVersion: guideVersion,
      payload: {
        chapterId,
        pageId,
        viewAccessChanges,
        editAccessChanges,
        pageVisibility,
      },
    })
    .catch(error => handleUnauthorized(error, editPageAccessApi));
}
export async function editChapterAccessApi(
  guideId: string,
  chapterId: string,
  viewAccessChanges: LegacyModificationGroup,
  editAccessChanges: LegacyModificationGroup,
  chapterVisibility: EntityVisibility,
  guideVersion: number,
): Promise<AxiosResponse> {
  return (await axios())
    .post(API_ROUTES.GUIDES_WRITE, {
      command: Command.EDIT_CHAPTER_ACCESS,
      aggregateId: guideId,
      aggregateVersion: guideVersion,
      payload: {
        chapterId,
        viewAccessChanges,
        editAccessChanges,
        chapterVisibility,
      },
    })
    .catch(error => handleUnauthorized(error, editChapterAccessApi));
}
