/* eslint-disable prefer-rest-params */
import { AxiosResponse } from 'axios';
import { API_ROUTES } from '../../../constants/app';
import { Command } from '../../../types/commands';
import axios, { handleUnauthorized } from '../../axios';
import { ButtonWidgetButton, WIDGET_TYPE } from '../../../types';
import { getNewWidgetByType } from '../../../components/widgets/helpers';
import { emptyCSSObject } from '../../../constants/widgets';

export async function addNewButtonWidgetToSection({
  guideId,
  chapterId,
  pageId,
  sectionId,
  widgetId,
  columnIndex,
  guideVersion,
  buttonId,
}: {
  guideId: string;
  chapterId: string;
  pageId: string;
  sectionId: string;
  widgetId: string;
  columnIndex: number;
  guideVersion: number;
  buttonId: string;
}): Promise<AxiosResponse> {
  const emptyWidgetState = getNewWidgetByType(WIDGET_TYPE.BUTTON, {
    buttonId: buttonId,
  });

  return (await axios())
    .post(API_ROUTES.GUIDES_WRITE, {
      command: Command.ADD_NEW_BUTTON_WIDGET_TO_SECTION,
      aggregateId: guideId,
      aggregateVersion: guideVersion,
      payload: {
        chapterId,
        pageId,
        sectionId,
        columnIndex,
        widgetId,
        buttonWidgetPayload: {
          buttonPayloads: [
            {
              id: emptyWidgetState.buttons[0].id,
              name: emptyWidgetState.buttons[0].name,
              link: emptyWidgetState.buttons[0].link,
              buttonStyle: emptyWidgetState.buttons[0].style,
            },
          ],
          widgetStyle: { ...emptyWidgetState.style },
        },
      },
    })
    .catch(error =>
      handleUnauthorized(error, addNewButtonWidgetToSection, ...arguments),
    );
}

export async function addNewButtonToButonWidgetApi({
  guideId,
  guideVersion,
  chapterId,
  pageId,
  sectionId,
  columnIndex,
  widgetId,
  newButton,
}: {
  guideId: string;
  chapterId: string;
  pageId: string;
  sectionId: string;
  widgetId: string;
  columnIndex: number;
  guideVersion: number;
  newButton: ButtonWidgetButton;
}) {
  return (await axios())
    .post(API_ROUTES.GUIDES_WRITE, {
      command: Command.ADD_NEW_BUTTONS_TO_BUTTON_WIDGET,
      aggregateId: guideId,
      aggregateVersion: guideVersion,
      payload: {
        chapterId,
        pageId,
        sectionId,
        columnIndex,
        widgetId,
        buttonWidgetPayload: {
          buttonPayloads: [
            {
              id: newButton.id,
              name: newButton.name,
              link: newButton.link,
              buttonStyle: {
                stylesheet: newButton.style.stylesheet,
                language: 'css',
                version: 3,
              },
            },
          ],
        },
      },
    })
    .catch(error =>
      handleUnauthorized(error, addNewButtonToButonWidgetApi, ...arguments),
    );
}

export async function editNameInButtonApi({
  guideId,
  chapterId,
  pageId,
  sectionId,
  widgetId,
  columnIndex,
  guideVersion,
  title,
  buttonId,
}: {
  guideId: string;
  chapterId: string;
  pageId: string;
  sectionId: string;
  widgetId: string;
  columnIndex: number;
  guideVersion: number;
  title: string;
  buttonId: string;
}): Promise<AxiosResponse> {
  return (await axios())
    .post(API_ROUTES.GUIDES_WRITE, {
      command: Command.EDIT_NAME_IN_BUTTON_WIDGET,
      aggregateId: guideId,
      aggregateVersion: guideVersion,
      payload: {
        chapterId,
        pageId,
        sectionId,
        columnIndex,
        widgetId,
        buttonWidgetPayload: {
          id: buttonId,
          name: {
            text: title,
          },
        },
      },
    })
    .catch(error =>
      handleUnauthorized(error, editNameInButtonApi, ...arguments),
    );
}

export async function editLinkInButtonApi({
  guideId,
  chapterId,
  pageId,
  sectionId,
  widgetId,
  columnIndex,
  guideVersion,
  link,
  buttonId,
}: {
  guideId: string;
  chapterId: string;
  pageId: string;
  sectionId: string;
  widgetId: string;
  columnIndex: number;
  guideVersion: number;
  buttonId: string;
  link: {
    url: string;
    openNewTab: boolean;
  };
}): Promise<AxiosResponse> {
  return (await axios())
    .post(API_ROUTES.GUIDES_WRITE, {
      command: Command.EDIT_LINK_IN_BUTTON_WIDGET,
      aggregateId: guideId,
      aggregateVersion: guideVersion,
      payload: {
        chapterId,
        pageId,
        sectionId,
        columnIndex,
        widgetId,
        buttonWidgetPayload: {
          id: buttonId,
          link,
        },
      },
    })
    .catch(error =>
      handleUnauthorized(error, editLinkInButtonApi, ...arguments),
    );
}

export async function editStyleInButtonApi({
  guideId,
  chapterId,
  pageId,
  sectionId,
  widgetId,
  columnIndex,
  guideVersion,
  buttonId,
  style,
}: {
  guideId: string;
  chapterId: string;
  pageId: string;
  sectionId: string;
  widgetId: string;
  columnIndex: number;
  guideVersion: number;
  buttonId: string;
  style: string;
}): Promise<AxiosResponse> {
  return (await axios())
    .post(API_ROUTES.GUIDES_WRITE, {
      command: Command.EDIT_STYLE_IN_BUTTON_WIDGET,
      aggregateId: guideId,
      aggregateVersion: guideVersion,
      payload: {
        chapterId,
        pageId,
        sectionId,
        columnIndex,
        widgetId,
        buttonWidgetPayload: {
          id: buttonId,
          buttonStyle: {
            ...emptyCSSObject,
            stylesheet: style,
          },
        },
      },
    })
    .catch(error =>
      handleUnauthorized(error, editStyleInButtonApi, ...arguments),
    );
}

export async function removeButtonFromButtonWidgetApi({
  guideId,
  chapterId,
  pageId,
  sectionId,
  widgetId,
  columnIndex,
  guideVersion,
  buttonId,
}: {
  guideId: string;
  chapterId: string;
  pageId: string;
  sectionId: string;
  widgetId: string;
  columnIndex: number;
  guideVersion: number;
  buttonId: string;
}): Promise<AxiosResponse> {
  return (await axios())
    .post(API_ROUTES.GUIDES_WRITE, {
      command: Command.REMOVE_BUTTON_FROM_BUTTON_WIDGET,
      aggregateId: guideId,
      aggregateVersion: guideVersion,
      payload: {
        chapterId,
        pageId,
        sectionId,
        columnIndex,
        widgetId,
        buttonWidgetPayload: {
          id: buttonId,
        },
      },
    })
    .catch(error =>
      handleUnauthorized(error, removeButtonFromButtonWidgetApi, ...arguments),
    );
}

// Not yet implemented
export async function moveButtonInButtonWidgetApi({
  guideId,
  chapterId,
  pageId,
  sectionId,
  widgetId,
  columnIndex,
  guideVersion,
  buttonId,
  from,
  to,
}: {
  guideId: string;
  chapterId: string;
  pageId: string;
  sectionId: string;
  widgetId: string;
  columnIndex: number;
  guideVersion: number;
  buttonId: string;
  from: number;
  to: number;
}): Promise<AxiosResponse> {
  return (await axios())
    .post(API_ROUTES.GUIDES_WRITE, {
      command: Command.MOVE_BUTTON_IN_BUTTON_WIDGET,
      aggregateId: guideId,
      aggregateVersion: guideVersion,
      payload: {
        chapterId,
        pageId,
        sectionId,
        columnIndex,
        widgetId,
        buttonWidgetPayload: {
          id: buttonId,
          from: {
            position: from,
          },
          to: {
            position: to,
          },
        },
      },
    })
    .catch(error =>
      handleUnauthorized(error, moveButtonInButtonWidgetApi, ...arguments),
    );
}

export async function editWidgetStyleInButtonWidgetApi({
  guideId,
  chapterId,
  pageId,
  sectionId,
  widgetId,
  columnIndex,
  guideVersion,
  style,
}: {
  guideId: string;
  chapterId: string;
  pageId: string;
  sectionId: string;
  widgetId: string;
  columnIndex: number;
  guideVersion: number;
  style: string;
}): Promise<AxiosResponse> {
  return (await axios())
    .post(API_ROUTES.GUIDES_WRITE, {
      command: Command.EDIT_WIDGET_STYLE_IN_BUTTON_WIDGET,
      aggregateId: guideId,
      aggregateVersion: guideVersion,
      payload: {
        chapterId,
        pageId,
        sectionId,
        columnIndex,
        widgetId,
        buttonWidgetPayload: {
          widgetStyle: {
            ...emptyCSSObject,
            stylesheet: style,
          },
        },
      },
    })
    .catch(error =>
      handleUnauthorized(error, editWidgetStyleInButtonWidgetApi, ...arguments),
    );
}
